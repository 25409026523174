/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Stack, Tooltip, tooltipClasses, Typography } from "@mui/material";
import Button from "../../../components/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { ON_BOARDING_STEPS } from "../../../constants/default-values";
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import { useAlert } from "../../../hook/useAlert";
import { useNavigate } from "react-router-dom";
import URLS from "../../../routes/urls";
import Input from "../../../components/Input";
import { generateUniqueId, getInputId, sortArrayByKey, titleCase } from "../../../utils";
import CircularLoader from "../../../components/CircularLoader";
import { usePageTitle } from "../../../hook/useTitle";
import { styled } from "@mui/styles";
import ICONS from "../../../constants/icons";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { setUserOnBoardingStep } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px",
    borderRadius: "5px",
    background: "#3F5C76",
  },
}));

const initialUserRoles = {
  id: null,
  name: "",
  canDelete: true,
  unique_id: generateUniqueId(),
  is_deleted: false,
  external: false,
};
const UserRolesStep = forwardRef(({ setOnBoardingStep, currentUser, isEditField, setIsEditField, handleShowSaveModal }, ref) => {
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  const [userRolesData, setUserRolesData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [deletableRole, setDeletableRole] = useState({});
  const [isExternalExist, setIsExternalExist] = useState(false);

  setPageTitle("Add your User Roles");
  const findIndexWithUid = (id) =>
    userRolesData.findIndex((data) => data.unique_id === id);
  const [loading, setLoading] = useState({
    formSubmitting: false,
    pageLoading: true,
  });

  useEffect(() => {
    if (currentUser?.organization_id) fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading((prev) => ({ ...prev, pageLoading: true }));
      const [
        userRoleResponse,
        workTypesResponse,
      ] = await Promise.all([
        await axiosInstance.get(
          API.organisationUserRoles(currentUser?.organization_id)
        ),
        axiosInstance.get(API.getWorkTypesByOrganisationId(currentUser?.organization_id))
      ]);

      if (userRoleResponse?.status === 200) {
        let newResponse = userRoleResponse?.data?.data?.map((roles) => ({
          ...roles,
          is_deleted: false,
          unique_id: generateUniqueId(),
        }));
        let sortedArray = sortArrayByKey(newResponse);
        setUserRolesData([...sortedArray, initialUserRoles]);
      }
      if (workTypesResponse?.status === 200) {
        setIsExternalExist(!!workTypesResponse.data.data?.find(workTypeData => workTypeData?.external))
      }
      setLoading((prev) => ({ ...prev, pageLoading: false }));
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  }

  useImperativeHandle(ref, () => ({
    handleSubmitUserRoles: () => handleSubmitUserRoles(ON_BOARDING_STEPS.step3)
  }));

  const handlePreviousStep = () => {
    if (isEditField) {
      handleShowSaveModal();
    } else {
      navigate(`${URLS.onBoarding}/${ON_BOARDING_STEPS.step3}`, {
        replace: true,
      });
      setOnBoardingStep(ON_BOARDING_STEPS.step3);
    }
  };

  const handleSkipStep = async () => {
    try {
      const response = await axiosInstance.put(
        API.skipStepForOnBoarding(currentUser?.organization_id, "user_role_setting"));
      if (response.status === 200) {
        navigate(`${URLS.onBoarding}/${ON_BOARDING_STEPS.step5}`, {
          replace: true,
        });
        setOnBoardingStep(ON_BOARDING_STEPS.step5);
        dispatch(setUserOnBoardingStep(ON_BOARDING_STEPS.step5));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeRoles = (unique_id, value, index, is_checkbox) => {
    setIsEditField(true);
    const findIndex = findIndexWithUid(unique_id);
    const updatedUserRole = [...userRolesData];
    let name = is_checkbox ? "is_received_leave_mail" : "name";
    const updatedObject = {
      ...updatedUserRole[findIndex],
      [name]: value,
    };
    updatedUserRole[findIndex] = updatedObject;
    if (name !== "is_received_leave_mail") {
      if (value !== "" && index === updatedUserRole.length - 1) {
        initialUserRoles.unique_id = generateUniqueId();
        updatedUserRole.push(initialUserRoles);
      }
    }
    setUserRolesData(updatedUserRole);
  };

  const handleChangeExternalType = (unique_id, value, index, is_checkbox) => {
    setIsEditField(true);
    const findIndex = findIndexWithUid(unique_id);
    const updatedUserRole = [...userRolesData];
    let name = is_checkbox ? "external" : "name";
    const updatedObject = {
      ...updatedUserRole[findIndex],
      [name]: value,
    };
    updatedUserRole[findIndex] = updatedObject;
    if (name !== "external") {
      if (value !== "" && index === updatedUserRole.length - 1) {
        initialUserRoles.unique_id = generateUniqueId();
        updatedUserRole.push(initialUserRoles);
      }
    }
    setUserRolesData(updatedUserRole);
  }

  const handleRemoveRole = (userRoles) => {
    if (userRoles?.id) {
      setDeletableRole(userRoles);
      setIsOpen(true)
    } else {
      handleSubmitRemoveRole(userRoles)
    }
  };

  const handleSubmitRemoveRole = (userRoles = deletableRole) => {
    const updatedUserRole = [...userRolesData];
    if (userRoles?.id) {
      const changeDeleteStatus = updatedUserRole.map((role) => {
        return role?.unique_id === userRoles?.unique_id
          ? { ...role, is_deleted: true }
          : { ...role };
      });
      setUserRolesData(changeDeleteStatus);
      setIsOpen(false);
    } else {
      const removedObject = updatedUserRole.filter(
        (data) => data.unique_id !== userRoles?.unique_id
      );
      setUserRolesData(removedObject);
    }
  }

  const validateUniqueRoles = (userRolesData) => {
    const activeRoles = userRolesData?.filter((role) => !role?.is_deleted);
    const roleNames = activeRoles?.map((role) =>
      role.name?.toString()?.toLowerCase()
    ); // Extracting role names from the provided array
    const uniqueRoleNames = new Set(roleNames); // Using a Set to identify unique role names
    if (uniqueRoleNames.size !== roleNames.length) {
      showAlert("Role names should be unique", "error");
      return false;
    }
    return true;
  };

  const handleSubmitUserRoles = async (redirectStep) => {
    try {
      const body = userRolesData?.filter((roleData) => roleData?.name?.length);
      if (validateUniqueRoles(body)) {
        setLoading((prev) => ({ ...prev, formSubmitting: true }));
        const response = await axiosInstance.put(
          API.organisationUserRoles(currentUser?.organization_id),
          { org_user_roles: body }
        );
        if (response.status === 200) {
          setLoading((prev) => ({ ...prev, formSubmitting: false }));
          showAlert(response.data?.message);
          navigate(`${URLS.onBoarding}/${redirectStep}`, {
            replace: true,
          });
          setOnBoardingStep(redirectStep);
          dispatch(setUserOnBoardingStep(redirectStep));
          return true;
        } else {
          setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
      }
      return false;
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, formSubmitting: false }));
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" width={"100%"}>
        <Stack spacing={4} sx={{ flexBasis: "100%" }}>
          <Typography color="secondary.800" fontSize={20}>
            Create a Organisation (Step 4 of 5)
          </Typography>
          <Grid item>
            <Typography
              color="secondary"
              fontSize={{ xs: 28, lg: 32 }}
              lineHeight={{ xs: "38px", lg: "44px" }}
              mb={2}
            >
              Add your User Roles
            </Typography>
            <Typography variant="body2" color="dark.800">
              Different levels of access, permissions, and responsibilities
              assigned to different users.
            </Typography>
            <Typography variant="body2" color="dark.800">
              These roles help in managing and controlling user activities.
            </Typography>
          </Grid>
          {loading.pageLoading ? (
            <CircularLoader />
          ) : (
            <Grid sx={{ width: "700px" }}>
              <Stack spacing={4}>
                {userRolesData?.map((userRoles, index, array) =>
                  !userRoles?.is_deleted ? (
                    <Grid display="flex" key={index}>
                      <Box width="410px" maxWidth='100%' mr={4}>
                        <Input
                          id={getInputId("roleName", index)}
                          label="Name"
                          variant="standard"
                          placeholder="name"
                          type="text"
                          fullWidth
                          value={titleCase(userRoles?.name)}
                          onChange={(e) =>
                            handleChangeRoles(
                              userRoles?.unique_id,
                              e.target.value,
                              index
                            )
                          }
                          required={index !== array?.length - 1}
                          disabled={['administrator', 'hr'].includes(userRoles?.name?.toLowerCase()) && userRoles?.id && !userRoles?.canDelete}
                        />
                      </Box>
                      <Box width="200px" maxWidth='100%' mr={4} display="flex">
                        <FormControlLabel
                          id={getInputId("receiveEmails", index)}
                          name="receiveEmails"
                          value="end"
                          control={
                            <Checkbox checked={!!userRoles?.is_received_leave_mail} size="medium" />
                          }
                          disabled={['administrator'].includes(userRoles?.name?.toLowerCase()) && userRoles?.id && !userRoles?.canDelete}
                          label="Receive Emails"
                          labelPlacement="end"
                          sx={{
                            padding: "9px 0px 0px 0px",
                            margin: "0px",
                            '.MuiCheckbox-sizeMedium': {
                              minWidth: '42px'
                            }
                          }}
                          onChange={(event, checked) =>
                            handleChangeRoles(
                              userRoles?.unique_id,
                              checked,
                              index,
                              true
                            )
                          }
                        />
                        <Box sx={{
                          alignContent: 'center',
                          padding: "5px 0px 0px 0px",
                          marginLeft: '15px'
                        }}>
                          <HtmlTooltip
                            title={
                              <Typography fontSize={{ xs: 12, lg: 14 }}>Users with this role will receive email related to all Leave
                                & Availability Change request along with it's associated actions.
                              </Typography>
                            }
                          >
                            <i
                              style={{
                                width: "18px",
                                height: "18px",
                                display: "inline-block",
                                verticalAlign: "middle",
                                marginLeft: "3px",
                                color: "#3F5C76",
                              }}
                            >
                              {ICONS.Info}
                            </i>
                          </HtmlTooltip>
                        </Box>
                      </Box>
                      {isExternalExist ? <Box width="200px" maxWidth='100%' mr={4} display="flex">
                        <FormControlLabel
                          id={getInputId("externalRole", index)}
                          name="externalRole"
                          value="end"
                          control={
                            <Checkbox checked={!!userRoles?.external} size="medium" />
                          }
                          label="External Role"
                          labelPlacement="end"
                          sx={{
                            padding: "9px 0px 0px 0px",
                            margin: "0px",
                            '.MuiCheckbox-sizeMedium': {
                              minWidth: '42px'
                            }
                          }}
                          onChange={(event, checked) =>
                            handleChangeExternalType(
                              userRoles?.unique_id,
                              checked,
                              index,
                              true
                            )
                          }
                          disabled={['administrator', 'hr'].includes(userRoles?.name?.toLowerCase()) ||
                            !userRoles?.canDelete}
                        />
                      </Box> : null}
                      <Box sx={{ minWidth: "40px" }}>
                        {index !== userRolesData.length - 1 ? (
                          <IconButton
                            size="small"
                            sx={{ width: "40px", height: "40px", ml: 0, mt: 1 }}
                            onClick={() => handleRemoveRole(userRoles)}
                            disabled={!userRoles?.canDelete}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        ) : null}
                      </Box>
                    </Grid>
                  ) : null
                )}
              </Stack>
            </Grid>
          )}
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={4}
        >
          <Button
            variant="text"
            color="secondary"
            sx={{ fontSize: "15px", textTransform: "none" }}
            onClick={handlePreviousStep}
          >
            Previous
          </Button>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flexBasis="100%"
          >
            <Button
              variant="text"
              color="secondary"
              sx={{ fontSize: "15px", textTransform: "none" }}
              onClick={handleSkipStep}
            >
              Skip
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="button"
              sx={{
                boxShadow: "0",
                color: "white",
                fontSize: "15px",
                textTransform: "none",
                marginLeft: "20px",
                "&:hover": { boxShadow: "0" },
              }}
              onClick={() => handleSubmitUserRoles(ON_BOARDING_STEPS.step5)}
              isButtonLoading={loading?.formSubmitting}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
      <ConfirmationModal
        isOpen={isOpen}
        title={'Role'}
        deletableDataName={titleCase(deletableRole?.name)}
        dataContentName={'Role'}
        handleClose={() => setIsOpen(false)}
        onConfirm={() => handleSubmitRemoveRole()}
        onCancel={() => { setIsOpen(false); setDeletableRole("") }}
        isButtonLoading={false}
        subList={<span>This will remove <b>{titleCase(deletableRole?.name)}</b> from your organization's common Roles list.</span>}
      />
    </>
  );
});

export default UserRolesStep;
