import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react'
import Input from '../../../../components/Input';
import TextEditor from '../../../../components/TextEditor';
import Button from '../../../../components/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axiosInstance from '../../../../axios';
import Breadcrumb from "../../../../components/Breadcrumb";
import { useNavigate } from 'react-router-dom';
import { getInputId } from '../../../../utils';
import API from '../../../../axios/api';
import URLS from '../../../../routes/urls';
import { useAlert } from '../../../../hook/useAlert';

const supportedFormats = ['jpg', 'jpeg', 'png', 'gif'];
const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    sub_title: Yup.string().required('Required'),
    subject: Yup.string().required('Required'),
    body: Yup.string().max(1000, 'Maximum length is 1000 characters').required('Required'),
    read_more_link: Yup.string().url('Invalid URL').required('Required'),
    footer_title: Yup.string().required('Required'),
    footer_link: Yup.string().url('Invalid URL').required('Required'),
    other_blog_title: Yup.string().required('Required'),
    other_blog_intro: Yup.string().required('Required'),
    other_blog_link: Yup.string().url('Invalid URL').required('Required'),
    banner_img: Yup.mixed()
        .required('Required')
        .test('fileFormat', 'Only image files are allowed', value => {
            if (value) {
                return supportedFormats.includes(value?.name?.split('.')?.pop()?.toLowerCase());
            }
            return true;
        }),
    footer_banner: Yup.mixed()
        .required('Required')
        .test('fileFormat', 'Only image files are allowed', value => {
            if (value) {
                return supportedFormats.includes(value?.name?.split('.')?.pop()?.toLowerCase());
            }
            return true;
        }),
    other_blog_img: Yup.mixed()
        .required('Required')
        .test('fileFormat', 'Only image files are allowed', value => {
            if (value) {
                return supportedFormats.includes(value?.name?.split('.')?.pop()?.toLowerCase());
            }
            return true;
        }),
});

const initialValues = {
    banner_img: null,
    footer_banner: null,
    other_blog_img: null,
    title: '',
    sub_title: '',
    subject: '',
    body: '',
    read_more_link: '',
    footer_title: '',
    footer_link: '',
    other_blog_title: '',
    other_blog_intro: ''
};

const CreateTemplate = () => {
    const navigate = useNavigate();
    const showAlert = useAlert();
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: false,
    });
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(prev => ({ ...prev, formSubmitting: true }));
                const formData = new FormData();
                Object.keys(values).forEach((key) => {
                    formData.append(key, values[key]);
                });
                const response = await axiosInstance.post(API.createEmailTemplate, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data-1'
                    }
                });
                if (response?.status === 200) {
                    showAlert(response?.data?.message);
                    navigate(URLS.EmailTemplates);
                } else {
                    showAlert(response?.data?.message, "error");
                }
                setLoading(prev => ({ ...prev, formSubmitting: false }));
            } catch (error) {
                console.error(error);
                setLoading(prev => ({ ...prev, formSubmitting: false }));
            }
        }
    });

    return (<>
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <React.Fragment>
                <Breadcrumb
                    isBack={true}
                    onBackClick={() => navigate(-1)}
                    pageTitle={"New Template"}
                    title={"Templates"}
                />
                <Box
                    p={{ xs: 3, lg: 4, xl: 4 }}
                    py={{ xs: 3, lg: 4, xl: 4 }}
                    sx={{ background: "#ffffff" }}
                    overflow="auto"
                    flex={1}
                >
                    <form onSubmit={formik.handleSubmit} className="step-form-1" noValidate>
                        <Box
                            direction="row"
                            display="flex"
                            flexWrap="wrap"
                            width={"80%"}
                            maxWidth="100%"
                        >
                            <Box
                                sx={{
                                    display: "grid",
                                    gap: 4,
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                }}
                                mb={4}
                                width={"100%"}
                            >
                                <Grid>
                                    <Input
                                        id={getInputId("template-title")}
                                        label="Template Title"
                                        variant="standard"
                                        isCapitalizeFirstLatter={true}
                                        placeholder="Template Title"
                                        type="text"
                                        name={"title"}
                                        fullWidth
                                        error={
                                            formik.touched.title &&
                                            Boolean(formik.errors.title)
                                        }
                                        helperText={
                                            formik.touched.title && formik.errors.title
                                        }
                                        {...formik.getFieldProps("title")}
                                    />
                                </Grid>
                                <Grid>
                                    <Input
                                        id={getInputId("sub_title")}
                                        label="Sub Title"
                                        variant="standard"
                                        placeholder="Sub Title"
                                        type="text"
                                        name={"sub_title"}
                                        fullWidth
                                        error={
                                            formik.touched.sub_title &&
                                            Boolean(formik.errors.sub_title)
                                        }
                                        helperText={
                                            formik.touched.sub_title && formik.errors.sub_title
                                        }
                                        {...formik.getFieldProps("sub_title")}
                                    />
                                </Grid>
                                <Grid>
                                    <Input
                                        id={getInputId("subject")}
                                        label="Subject"
                                        variant="standard"
                                        placeholder="Subject"
                                        type="text"
                                        name={"subject"}
                                        fullWidth
                                        error={
                                            formik.touched.subject &&
                                            Boolean(formik.errors.subject)
                                        }
                                        helperText={
                                            formik.touched.subject && formik.errors.subject
                                        }
                                        {...formik.getFieldProps("subject")}
                                    />
                                </Grid>
                                <Grid>
                                    <Input
                                        id={getInputId("read_more_link")}
                                        label="Read More Link"
                                        variant="standard"
                                        placeholder="Read More Link"
                                        type="text"
                                        name={"read_more_link"}
                                        fullWidth
                                        error={
                                            formik.touched.read_more_link &&
                                            Boolean(formik.errors.read_more_link)
                                        }
                                        helperText={
                                            formik.touched.read_more_link && formik.errors.read_more_link
                                        }
                                        {...formik.getFieldProps("read_more_link")}
                                    />
                                </Grid>
                                <Grid>
                                    <Input
                                        id={getInputId("footer_title")}
                                        label="Footer Title"
                                        variant="standard"
                                        placeholder="Footer Title"
                                        type="text"
                                        name={"footer_title"}
                                        fullWidth
                                        error={
                                            formik.touched.footer_title &&
                                            Boolean(formik.errors.footer_title)
                                        }
                                        helperText={
                                            formik.touched.footer_title && formik.errors.footer_title
                                        }
                                        {...formik.getFieldProps("footer_title")}
                                    />
                                </Grid>
                                <Grid>
                                    <Input
                                        id={getInputId("footer_link")}
                                        label="Footer Link"
                                        variant="standard"
                                        placeholder="Footer Link"
                                        type="text"
                                        name={"footer_link"}
                                        fullWidth
                                        error={
                                            formik.touched.footer_link &&
                                            Boolean(formik.errors.footer_link)
                                        }
                                        helperText={
                                            formik.touched.footer_link && formik.errors.footer_link
                                        }
                                        {...formik.getFieldProps("footer_link")}
                                    />
                                </Grid>
                                <Grid>
                                    <Input
                                        id={getInputId("other_blog_title")}
                                        label="Other Blog Title"
                                        variant="standard"
                                        placeholder="Other Blog Title"
                                        type="text"
                                        name={"other_blog_title"}
                                        fullWidth
                                        error={
                                            formik.touched.other_blog_title &&
                                            Boolean(formik.errors.other_blog_title)
                                        }
                                        helperText={
                                            formik.touched.other_blog_title && formik.errors.other_blog_title
                                        }
                                        {...formik.getFieldProps("other_blog_title")}
                                    />
                                </Grid>

                                <Grid>
                                    <Input
                                        id={getInputId("other_blog_intro")}
                                        label="Other Blog Intro"
                                        variant="standard"
                                        placeholder="Other Blog Intro"
                                        type="text"
                                        name={"other_blog_intro"}
                                        fullWidth
                                        error={
                                            formik.touched.other_blog_intro &&
                                            Boolean(formik.errors.other_blog_intro)
                                        }
                                        helperText={
                                            formik.touched.other_blog_intro && formik.errors.other_blog_intro
                                        }
                                        {...formik.getFieldProps("other_blog_intro")}
                                    />
                                </Grid>
                                <Grid>
                                    <Input
                                        id={getInputId("other_blog_link")}
                                        label="Other Blog Link"
                                        variant="standard"
                                        placeholder="Other Blog Link"
                                        type="text"
                                        name={"other_blog_link"}
                                        fullWidth
                                        error={
                                            formik.touched.other_blog_link &&
                                            Boolean(formik.errors.other_blog_link)
                                        }
                                        helperText={
                                            formik.touched.other_blog_link && formik.errors.other_blog_link
                                        }
                                        {...formik.getFieldProps("other_blog_link")}
                                    />
                                </Grid>
                            </Box>
                            <Grid item width={"100%"} maxWidth={"100%"} mb={6}>
                                <Typography
                                    variant="body2"
                                    fontWeight={400}
                                    sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
                                >
                                    Body*
                                </Typography>
                                <TextEditor
                                    value={formik.values.body}
                                    onChange={(value) => formik.setFieldValue('body', value)}
                                />
                                {formik.touched.body && !!formik.errors.body?.length ?
                                    <Typography
                                        variant="body2"
                                        fontWeight={400}
                                        color={'error'}
                                        sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
                                    >
                                        {formik.errors.body}
                                    </Typography>
                                    : null}
                            </Grid>
                            <Box display={"flex"} flexDirection={"row"} gap={2}>
                                <Grid item xs={20}>
                                    <Typography>
                                        Banner Image*
                                    </Typography>
                                    <input
                                        accept="image/*"
                                        id="banner_img"
                                        type="file"
                                        variant="standard"
                                        name='banner_img'
                                        onChange={(event) => formik.setFieldValue('banner_img', event.target.files[0])}
                                    />
                                    {formik.touched.banner_img && !!formik.errors.banner_img?.length ?
                                        <Typography
                                            variant="body2"
                                            fontWeight={400}
                                            color={'error'}
                                            sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
                                        >
                                            {formik.errors.banner_img}
                                        </Typography>
                                        : null}
                                </Grid>

                                <Grid item xs={20}>
                                    <Typography>
                                        Footer Image*
                                    </Typography>
                                    <input
                                        accept="image/*"
                                        id="footer_banner"
                                        name='footer_banner'
                                        type="file"
                                        variant="standard"
                                        onChange={(event) => formik.setFieldValue('footer_banner', event.target.files[0])}
                                    />
                                    {formik.touched.footer_banner && !!formik.errors.footer_banner?.length ?
                                        <Typography
                                            variant="body2"
                                            fontWeight={400}
                                            color={'error'}
                                            sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
                                        >
                                            {formik.errors.footer_banner}
                                        </Typography>
                                        : null}
                                </Grid>

                                <Grid item xs={20}>
                                    <Typography>
                                        Other Blog Image*
                                    </Typography>
                                    <input
                                        accept="image/*"
                                        id="other_blog_img"
                                        name='other_blog_img'
                                        type="file"
                                        variant="standard"
                                        onChange={(event) => formik.setFieldValue('other_blog_img', event.target.files[0])}
                                    />
                                    {formik.touched.other_blog_img && !!formik.errors.other_blog_img?.length ?
                                        <Typography
                                            variant="body2"
                                            fontWeight={400}
                                            color={'error'}
                                            sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
                                        >
                                            {formik.errors.other_blog_img}
                                        </Typography>
                                        : null}
                                </Grid>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mt={6}
                        >
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                                flexBasis="100%"
                                gap={2}
                            >
                                <Button
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    sx={{
                                        boxShadow: "0",
                                        fontSize: "15px",
                                        textTransform: "none",
                                        color: "white",
                                        "&:hover": { boxShadow: "0" },
                                    }}
                                    disabled={loading.formSubmitting}
                                    isButtonLoading={loading.formSubmitting}
                                >
                                    Create
                                </Button>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    sx={{ textTransform: "none" }}
                                    size="medium"
                                    fontWeight="500"
                                    onClick={() => navigate(-1)}
                                    disabled={loading.pageLoading}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </React.Fragment>
        </Box>
    </>
    )
}

export default CreateTemplate;
