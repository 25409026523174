import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material';
import React from 'react'
import { titleCase } from '../../../utils';
// import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Breadcrumb from '../../../components/Breadcrumb';
import URLS from '../../../routes/urls';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../../hook/useTitle';

const Reports = () => {
    const navigate = useNavigate();
    const { setPageTitle } = usePageTitle();
    setPageTitle("Reports");

    const ReportCard = ({ text, Icon, onClick, disabled = false, width = "300px", height = "fit-content" }) => {
        return <Card sx={{
            boxShadow: 'none',
        }}>
            <CardActionArea sx={{
                minWidth: width, padding: 4, border: '1px solid #E0E0E0', textAlign: "center", p: 2,
                '&:hover': {
                    boxShadow: 'shadow'
                }
            }} onClick={onClick} disabled={disabled}>
                <Box sx={{
                    display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", gap: 1,
                }}>
                    <Icon color="secondary" sx={{ fontSize: "1.5rem", verticalAlign: "middle" }}></Icon>
                    <Box>
                        <Typography color="secondary.main" fontSize={14} fontWeight={600} lineHeight="21px" letterSpacing="0.15px" >{titleCase(text)}</Typography>
                    </Box>
                </Box>
            </CardActionArea>
        </Card>
    }

    return (
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <Breadcrumb isBack={false} pageTitle={"Reports"} isPathShow={false} />
            <Box width="100%" bgcolor="white" display="flex" flexDirection="row">
                <Box p={{ xs: 2, lg: 3, xl: 2 }} py={{ xs: 3, lg: 3, xl: 4 }} sx={{ background: '#ffffff' }} overflow="auto">
                    <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                        <ReportCard Icon={PersonIcon} text={"In-Out Working Report"} onClick={() => navigate(URLS.InOutReport)} />
                    </Stack>
                </Box>
                <Box py={{ xs: 3, lg: 3, xl: 4 }} sx={{ background: '#ffffff' }} overflow="auto">
                    <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                        <ReportCard Icon={ExitToAppIcon} text={"Leaves Report"} onClick={() => navigate(URLS.LeaveReport)} />
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default Reports;