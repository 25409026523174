import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    users: [],
    isApiFalied: false
};

const repoteeSlice = createSlice({
    name: 'isRepotee',
    initialState,
    reducers: {
        setIsRepoteeData: (state, action) => {
            state.users = action.payload;
        },
        setIsApiReponseFailed: (state, action) => {
            state.isApiFalied = action.payload;
        },
        clearIsRepoteeData: (state) => {
            state.users = [];
        }
    },
});

export const { setIsRepoteeData, setIsApiReponseFailed, clearIsRepoteeData } = repoteeSlice.actions;
export default repoteeSlice.reducer;
