/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import Button from "../../../components/Button";
import axiosInstance from "../../../axios";
import API from "../../../axios/api";
import Input from "../../../components/Input";
import { convertToBase64, generateUniqueId, getDefaultTime, getFormattedDate, getInputId, getMaxSize, isValidOpeningBalance, sortArrayByKey, titleCase } from "../../../utils";
import CircularLoader from "../../../components/CircularLoader";
import useAuthentication from "../../../hook/useAuthentication";
import { useNavigate } from "react-router-dom";
import URLS from "../../../routes/urls";
import { useAlert } from "../../../hook/useAlert";
import { handleCheckIsEmailUnique } from "../../../axios/service";
import { validateEmail } from "../../../utils/validation";
import { usePageTitle } from "../../../hook/useTitle";
import Breadcrumb from "../../../components/Breadcrumb";
import styled from "@emotion/styled";
import { PROFILE_IMAGE_SIZE, VALID_IMAGE_TYPE } from "../../../constants/default-values";
import BackgroundLetterAvatars from "../../../components/BackgroundLetterAvatars";
import moment from "moment";
import CustomTimePickerForUser from "../../../components/CustomTimePicker/customTimePickerForUser";
import SingleDatePicker from "../../../components/SingleDatePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const initialUserObjectData = {
    email: "",
    name: "",
    active: true,
    current_user_role_id: null,
    current_user_type_id: null,
    org_work_type_setting_id: null,
    is_admin: false,
    unique_id: generateUniqueId(),
    is_admin_user: false,
    leave_type_setting: [],
    is_dedicated_developer: false,
    enable_sys_generated_leave: false,
    joining_date: null,
    external: false
};

const CreateUser = () => {
    const showAlert = useAlert();
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const navigate = useNavigate();
    const { setPageTitle } = usePageTitle();
    const [previewImage, setPreviewImage] = useState(null);
    const [isChanged, setIsChanged] = useState({});
    const [userData, setUserData] = useState({ ...initialUserObjectData, in_time: getDefaultTime() });
    const [constantValues, setConstantValues] = useState({
        userRolesData: [],
        workTypeData: [],
        userTypeData: [],
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: false,
        leaveType: false,
    });
    const [externalWorkTypeIds, setExternalWorkTypeIds] = useState([]);
    const [externalRoles, setExternalRoles] = useState([]);

    setPageTitle("Add User");

    useEffect(() => {
        if (currentUser?.id) {
            getUserRolesData();
            getUserTypesData();
            getWorkTypesByOrganizationIdData();
        }
    }, []);

    useEffect(() => {
        if (constantValues?.userRolesData?.length && constantValues?.workTypeData?.length) {
        }
    }, [constantValues])

    const getUserRolesData = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.get(
                API.organisationUserRoles(currentUser?.organization_id)
            );
            if (response.status === 200) {
                let sortedArray = sortArrayByKey(response?.data?.data);
                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: sortedArray?.map(item => ({ ...item, isVisible: true, isSelected: true, })),
                }));
                setExternalRoles(sortedArray?.filter(roleData => roleData?.external)?.map((data => data?.id)))
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
        }
    };

    const getLeaveTypesByOrganizationIdData = async (...rest) => {
        let target = rest?.[0]?.target;
        try {
            setLoading((prev) => ({ ...prev, leaveType: true }));
            const response = await axiosInstance.get(API.organizationLeaveTypes(target?.value));
            if (response.status === 200) {
                let initialLeaveTypeData = response?.data?.result?.map(item => ({
                    id: item?.id || null,
                    monthly_days: item?.monthly_days || 0,
                    opening_balance: 0,
                    name: item?.LeaveType?.name || null,
                }));
                setUserData((prev) => ({ ...prev, leave_type_setting: initialLeaveTypeData }));
            } else {
                setUserData((prev) => ({ ...prev, leave_type_setting: [] }));
            }
            setLoading((prev) => ({ ...prev, leaveType: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, leaveType: false }));
        }
    };

    const getWorkTypesByOrganizationIdData = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.get(
                API.getWorkTypesByOrganisationId(currentUser?.organization_id)
            );
            if (response.status === 200) {
                setConstantValues((prev) => ({
                    ...prev,
                    workTypeData: response?.data?.data,
                }));
                setExternalWorkTypeIds(response?.data?.data?.filter(workTypeData => workTypeData?.external)?.map((data => data?.id)))
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
        }
    };

    const getUserTypesData = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.get(API.getUserTypes);
            if (response.status === 200) {
                setConstantValues((prev) => ({
                    ...prev,
                    userTypeData: response?.data?.data?.map(item => ({ ...item, isVisible: true })),
                }));
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
        }
    };

    const handleCancel = () => {
        navigate(URLS.Users)
    }

    const handleChangeProfileImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (VALID_IMAGE_TYPE.includes(file.type)) {
                if (file.size <= PROFILE_IMAGE_SIZE) {
                    const previewURL = URL.createObjectURL(file);
                    setPreviewImage(previewURL);

                    setUserData((prev) => ({
                        ...prev,
                        profile_img: file
                    }));

                    setIsChanged((prev) => ({
                        ...prev,
                        profile_img: true
                    }));
                } else {
                    showAlert(`File size exceeds ${getMaxSize(PROFILE_IMAGE_SIZE)}MB limit. Please choose a smaller file.`, "error");
                    setUserData((prev) => ({
                        ...prev,
                        profile_img: null
                    }));
                }
            } else {
                showAlert('Only JPEG and PNG file types are allowed.', "error");
                setUserData((prev) => ({
                    ...prev,
                    profile_img: null
                }));
            }
        }
    };

    const validateOpeningBalance = (event, currentIndex) => {
        const { value } = event.target;
        let updatedError = [...errors?.leave_type_setting || []]
        let error = value === "null" || value === null || value === "" || value < 0 ? "Required" : !isValidOpeningBalance(value) ? "Please enter valid opening balance" : "";
        updatedError[currentIndex] = {
            opening_balance: error,
        };
        setErrors((prev) => ({
            ...prev,
            leave_type_setting: updatedError,
        }));
    }

    const handleChangeOpeningBalance = (e, currentIndex) => {
        validateOpeningBalance(e, currentIndex);
        let updatedList = userData?.leave_type_setting?.map((value, index) => {
            if (index === currentIndex)
                return {
                    ...value,
                    opening_balance: e.target.value && e.target.value >= 0 ? parseFloat(e.target.value) : "",
                };
            else
                return value;
        });
        setUserData((prev) => ({ ...prev, leave_type_setting: updatedList }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading((prev) => ({ ...prev, formSubmitting: true }));
            let formData = new FormData();
            formData.append("email", userData?.email);
            formData.append("name", userData?.name);
            formData.append("current_user_type_id", userData?.current_user_type_id);
            formData.append("current_user_role_id", userData?.current_user_role_id);
            formData.append("org_work_type_setting_id", userData?.org_work_type_setting_id);
            formData.append("organization_id", currentUser?.organization_id);
            formData.append("is_dedicated_developer", userData?.is_dedicated_developer);
            formData.append("enable_sys_generated_leave", userData?.enable_sys_generated_leave);
            formData.append("active", true);
            formData.append("in_time", moment(userData?.in_time)?.format("HH:mm"));
            formData.append("is_admin", userData?.is_general_user ? false : (userData?.is_admin_user || userData?.is_admin));
            formData.append("external", userData?.external);
            if (userData?.joining_date) {
                formData.append("joining_date", getFormattedDate(userData?.joining_date, "YYYY-MM-DD"));
            }
            userData?.leave_type_setting?.forEach((value, index) => {
                formData.append(`leave_type_setting[${index}][id]`, value?.id);
                formData.append(`leave_type_setting[${index}][monthly_days]`, Number.parseFloat(value?.monthly_days));
                formData.append(`leave_type_setting[${index}][opening_balance]`, Number.parseFloat(value?.opening_balance));
            });
            if (isChanged?.profile_img && userData?.profile_img?.size > 0) {
                let base64 = null;
                if (userData?.profile_img) {
                    if (userData?.profile_img instanceof Blob || userData?.profile_img instanceof File) {
                        base64 = await convertToBase64(userData?.profile_img);
                    } else {
                        base64 = userData?.profile_img?.base64;
                    }
                    formData.append(`profile_img[${0}][name]`, userData?.profile_img?.name);
                    formData.append(`profile_img[${0}][size]`, userData?.profile_img?.size);
                    formData.append(`profile_img[${0}][type]`, userData?.profile_img?.type);
                    formData.append(`profile_img[${0}][base64]`, base64);
                    formData.append(`profile_img[${0}][extension]`, `.${userData?.profile_img?.name?.split('.').pop()}`);
                }
            }
            const response = await axiosInstance.post(API.addUser, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (response.status === 200) {
                showAlert(response.data?.message);
                navigate(URLS.Users, {
                    replace: true,
                });
            }
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
    };

    const handleBlur = async (e) => {
        let { name, value } = e.target;
        if (name === 'email') {
            const isEmailNotUnique = await handleCheckIsEmailUnique(value);
            const isInvalidEmail = value?.trim() && !validateEmail(value?.trim());
            let errorMessage = isInvalidEmail ? "Invalid email address" : isEmailNotUnique ? "Email is already exist" : "";
            setErrors(prev => ({
                ...prev,
                [name]: !value ? "Required" : errorMessage,
            }))
        } else {
            if (name === "current_user_role_id") {
                setErrors(prev => ({
                    ...prev,
                    [name]: !value ? prev[name]?.length > 1 ? prev[name] : "Required" : "",
                }))
            } else {
                setErrors(prev => ({
                    ...prev,
                    [name]: !value ? "Required" : "",
                }))
            }
        }
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        let updatedData = {
            ...userData,
            [name]: value,
        }
        let adminType = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "admin");
        if (name === "current_user_type_id") {
            let generalType = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "general");
            let adminRole = constantValues?.userRolesData?.find((value) => value?.name?.toLowerCase() === "administrator");
            if (adminType?.id === value) {
                updatedData['current_user_role_id'] = adminRole?.id;
                updatedData['is_admin'] = true;
                updatedData['is_admin_user'] = true;
                if (externalWorkTypeIds?.includes(updatedData?.org_work_type_setting_id)) updatedData['org_work_type_setting_id'] = null;

                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: prev?.userRolesData?.map(item => item?.id === adminRole?.id ? { ...item, isVisible: true, isSelected: true, } : { ...item, isVisible: false })
                }));
                setErrors((prev) => ({ ...prev, current_user_role_id: "" }));
                setUserData(updatedData)
            } else if (generalType?.id === value) {
                updatedData['is_admin'] = false;
                updatedData['is_admin_user'] = false;
                updatedData['is_general_user'] = true;
                updatedData['current_user_role_id'] = null;
                updatedData['current_user_role_id'] = updatedData?.current_user_role_id === adminRole?.id ? null : updatedData?.current_user_role_id;

                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: prev?.userRolesData?.map(item => item?.id !== adminRole?.id ? { ...item, isVisible: true } : { ...item, isVisible: false })
                }));
            } else {
                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: prev?.userRolesData?.map(item => item?.id !== adminRole?.id ? { ...item, isVisible: true } : { ...item, isVisible: false })
                }));
                updatedData['is_admin'] = true;
                updatedData['is_admin_user'] = false;
                updatedData['is_general_user'] = false;
                updatedData['current_user_role_id'] = null;
                updatedData['current_user_role_id'] = updatedData?.current_user_role_id === adminRole?.id ? null : updatedData?.current_user_role_id;
                setUserData(updatedData)
            }
        }

        setErrors(prev => ({
            ...prev,
            [name]: !value ? "Required" : "",
        }))

        if (name === "org_work_type_setting_id") {
            let updatedErrors = { ...errors };
            const error = { ...updatedErrors };
            if (externalWorkTypeIds?.includes(value)) {
                if (!externalRoles?.includes(updatedData?.current_user_role_id)) {
                    updatedData['current_user_role_id'] = null
                }
                if (adminType?.id === updatedData?.current_user_type_id) {
                    updatedData = {
                        ...updatedData,
                        current_user_type_id: null,
                        current_user_role_id: null,
                        is_admin_user: false,
                        is_admin: false
                    }
                }
                updatedData = {
                    ...updatedData,
                    external: true,
                }
                if (!externalRoles?.length) {
                    error['current_user_role_id'] = 'No external user role exist'
                }
            } else {
                if (error['current_user_role_id']?.includes('No external user role exist')) {
                    error['current_user_role_id'] = null
                }
                if (externalRoles?.includes(updatedData?.current_user_role_id)) {
                    updatedData['current_user_role_id'] = null
                }
                updatedData = {
                    ...updatedData,
                    external: false,
                }
            }
            setErrors(error);
        }
        setUserData(updatedData);
    }

    let isError = () => {
        function areAllValuesEmpty(obj) {
            if (typeof obj !== 'object' || obj === null) {
                return false;
            }

            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    if (Array.isArray(value)) {
                        if (!value.every(item => areAllValuesEmpty(item))) {
                            return false;
                        }
                    } else if (typeof value === 'object') {
                        if (!areAllValuesEmpty(value)) {
                            return false;
                        }
                    } else if (value !== "") {
                        return false;
                    }
                }
            }

            return true;
        }
        let isError = areAllValuesEmpty(errors);

        function validateObject(obj) {
            const errors = [];
            const requiredKeys = [
                'email',
                'name',
                'active',
                'current_user_role_id',
                'current_user_type_id',
                'org_work_type_setting_id',
                'is_admin',
                'unique_id',
                'is_admin_user',
                'leave_type_setting',
                'in_time',
                'joining_date'
            ];

            function checkString(fieldName, value, allowEmpty = false) {
                if (typeof value !== 'string' || (!allowEmpty && value.trim() === '')) {
                    errors.push(`${fieldName} must be a non-empty string.`);
                }
            }

            function checkBoolean(fieldName, value) {
                if (typeof value !== 'boolean') {
                    errors.push(`${fieldName} must be a boolean.`);
                }
            }

            function checkNullableString(fieldName, value) {
                if (value !== null && typeof value !== 'number' && typeof value !== 'string') {
                    errors.push(`${fieldName} must be null or a string.`);
                }
            }

            function checkArray(fieldName, value) {
                if (!Array.isArray(value)) {
                    errors.push(`${fieldName} must be an array.`);
                }
            }

            function checkValue(fieldName, value) {
                if (value === null || value === undefined || (typeof value === 'string' && value.trim() === '')) {
                    errors.push(`${fieldName} must have a value.`);
                }
            }

            function checkObject(fieldName, value) {
                if (value === null || value === undefined) {
                    errors.push(`${fieldName} must have a value.`);
                }
            }

            for (const key of requiredKeys) {
                if (obj.hasOwnProperty(key)) {
                    checkValue(key, obj[key]);
                } else {
                    errors.push(`${key} is missing.`);
                }
            }

            checkString('email', obj.email, true);
            checkString('name', obj.name, true);
            checkBoolean('active', obj.active);
            checkNullableString('current_user_role_id', obj.current_user_role_id);
            checkNullableString('current_user_type_id', obj.current_user_type_id);
            checkNullableString('org_work_type_setting_id', obj.org_work_type_setting_id);
            checkBoolean('is_admin', obj.is_admin);
            checkString('unique_id', obj.unique_id);
            checkObject('joining_date', obj.joining_date);
            checkBoolean('is_admin_user', obj.is_admin_user);
            checkArray('leave_type_setting', obj.leave_type_setting);

            return errors.length === 0 ? { isValid: true } : { isValid: false, errors };
        }

        let isErrorInUser = validateObject(userData);

        return !isError || !isErrorInUser?.isValid;
    }

    const isValidDateFormat = (date) => {
        return new Date(date).toString() !== "Invalid Date";
    };

    const handelTimeChange = (time) => {
        let timeValue = moment(new Date(time)).format("HH:mm:ss");
        let [HH, mm, ss] = timeValue?.split(':').map(Number);
        let newDateTimeValue = new Date();
        newDateTimeValue.setHours(HH);
        newDateTimeValue.setMinutes(mm);
        newDateTimeValue.setSeconds(ss);
        let updatedData = isValidDateFormat(time) ? newDateTimeValue : null;
        setUserData(prev => ({ ...prev, "in_time": updatedData }))

        setErrors((prev) => ({ ...prev, "in_time": isValidDateFormat(time) ? "" : 'Required' }));
    }

    const handleOpenTimePicker = () => {
        setUserData((prev) => ({ ...prev, in_time: getDefaultTime(userData?.in_time) }))
        setErrors((prev) => ({ ...prev, "in_time": "" }));
    }

    const handleChangeJoiningDate = (date) => {
        setUserData((prev) => ({
            ...prev,
            joining_date: new Date(date),
        }))
        setErrors((prev) => ({
            ...prev,
            joining_date: "",
        }))
    }

    const getFilteredWorkTypeList = () => {
        let adminTypeId = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "admin")?.id;

        if (adminTypeId === userData?.current_user_type_id) {
            return constantValues?.workTypeData?.filter((workData) => !workData?.external)
        } else {
            return constantValues?.workTypeData
        }
    }

    const getFilteredRoleList = (isExternal) => {
        return constantValues?.userRolesData?.filter((userDetails) => userDetails?.external === isExternal)
    }

    const getFilteredUserTypeList = (isExternal) => {
        if (isExternal) {
            return constantValues?.userTypeData?.filter((useData) => useData?.name?.toLowerCase() !== "admin")
        }
        return constantValues?.userTypeData;
    }

    return (
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
            <Breadcrumb isBack={true} onBackClick={() => navigate(-1)} pageTitle={"Add User"} title={"Users"} />
            <Box width="100%" sx={{ overflowY: 'auto', flex: 1 }}>
                <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }}>
                    <Stack spacing={3}>
                        <Typography color="dark.800" fontWeight={400} fontSize={20} lineHeight="30px" letterSpacing="0.15px" variant="h4">Create User</Typography>
                        <Box justifyContent="space-between" alignItems="center">
                            {loading.pageLoading ? <CircularLoader height="500px" /> :
                                <form onSubmit={handleSubmit} className="step-form-1">
                                    <Stack spacing={4} sx={{ flexBasis: "100%" }}>
                                        <Box display="flex" alignItems="center">
                                            <Box width={90} height={90} borderRadius="50%" overflow="hidden" mr={2}>
                                                {!previewImage ?
                                                    <Avatar
                                                        src="/broken-image.jpg"
                                                        sx={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: 0,
                                                            fontSize: "50px",
                                                        }}
                                                    /> :
                                                    <BackgroundLetterAvatars
                                                        user={userData}
                                                        src={previewImage}
                                                        sx={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: 0,
                                                            fontSize: "50px",
                                                        }}
                                                    />}
                                            </Box>
                                            <Button
                                                component="label"
                                                variant="text"
                                                color="primary"
                                                fontSize={13}
                                                sx={{ textTransform: "none" }}
                                            >
                                                Change Photo
                                                <VisuallyHiddenInput
                                                    name="profile_img"
                                                    type="file"
                                                    onChange={handleChangeProfileImage}
                                                    accept="image/*"
                                                />
                                            </Button>
                                        </Box>
                                        <Box width={780} maxWidth="100%">
                                            <Grid container spacing={4}>
                                                <Grid item xs={6} maxWidth={"100%"}>
                                                    <Input
                                                        id="name"
                                                        label="Name *"
                                                        variant="standard"
                                                        placeholder="Name"
                                                        type="text"
                                                        name={"name"}
                                                        fullWidth
                                                        required={false}
                                                        value={titleCase(userData?.name)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={!!errors?.name}
                                                        helperText={errors?.name}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} m={0}></Grid>
                                                <Grid item xs={6} maxWidth={"100%"}>
                                                    <Input
                                                        id="email"
                                                        label="Email Address *"
                                                        variant="standard"
                                                        placeholder="Email"
                                                        type="email"
                                                        name={"email"}
                                                        fullWidth
                                                        required={false}
                                                        value={userData?.email}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={!!errors?.email}
                                                        helperText={errors?.email}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} maxWidth={"100%"}>
                                                    <Input
                                                        id={"current_user_type_id"}
                                                        name={"current_user_type_id"}
                                                        select
                                                        type="select"
                                                        label="User Type"
                                                        defaultValue="Admin"
                                                        variant="standard"
                                                        sx={{ width: "100%" }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={userData?.current_user_type_id}
                                                        error={!!errors?.current_user_type_id}
                                                        helperText={errors?.current_user_type_id}
                                                    >
                                                        {getFilteredUserTypeList(userData?.external)?.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {titleCase(option.name)}
                                                            </MenuItem>
                                                        ))}
                                                    </Input>
                                                </Grid>
                                                <Grid item xs={6} maxWidth={"100%"}>
                                                    <Input
                                                        id={getInputId("user-role", 0)}
                                                        select
                                                        type="select"
                                                        label="User Role"
                                                        variant="standard"
                                                        name={"current_user_role_id"}
                                                        sx={{
                                                            width: "100%",
                                                            "& .MuiPopover-paper": {
                                                                boxShadow:
                                                                    "0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
                                                            },
                                                        }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={userData?.current_user_role_id}
                                                        error={!!errors?.current_user_role_id}
                                                        helperText={errors?.current_user_role_id}
                                                        disabled={userData?.is_admin_user || false}
                                                        InputLabelProps={{
                                                            shrink: !!(userData?.is_admin_user || userData?.current_user_role_id),
                                                            htmlFor: getInputId("user-role", 0),
                                                        }}
                                                    >
                                                        {getFilteredRoleList(userData?.external)?.map((option) => (
                                                            <MenuItem
                                                                selected={option?.isSelected || false}
                                                                key={option.id}
                                                                value={option.id}
                                                                sx={{
                                                                    display: !option?.isVisible ? "none" : "block",
                                                                    "&:hover": {
                                                                        background: "rgba(4, 127, 224, 0.1)",
                                                                    },
                                                                }}
                                                            >
                                                                {titleCase(option.name)}
                                                            </MenuItem>
                                                        ))}
                                                    </Input>
                                                </Grid>
                                                <Grid item xs={6} maxWidth={"100%"}>
                                                    <Input
                                                        id={"org_work_type_setting_id"}
                                                        name={"org_work_type_setting_id"}
                                                        select
                                                        type="select"
                                                        label="Work Type"
                                                        defaultValue="In-Office"
                                                        variant="standard"
                                                        sx={{ width: "100%" }}
                                                        onChange={(...rest) => {
                                                            getLeaveTypesByOrganizationIdData(...rest);
                                                            handleChange(...rest);
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={userData?.org_work_type_setting_id}
                                                        error={!!errors?.org_work_type_setting_id}
                                                        helperText={
                                                            errors?.org_work_type_setting_id
                                                        }
                                                    >
                                                        {getFilteredWorkTypeList()?.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {titleCase(option.name)}
                                                            </MenuItem>
                                                        ))}
                                                    </Input>
                                                </Grid>
                                                {loading?.leaveType ?
                                                    <React.Fragment>
                                                        <Grid item xs={6} maxWidth={"100%"} >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                sx={{ borderRadius: "5px" }}
                                                                width={"100%"}
                                                                height={50}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} maxWidth={"100%"} >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                sx={{ borderRadius: "5px" }}
                                                                width={"100%"}
                                                                height={50}
                                                            />
                                                        </Grid>
                                                    </React.Fragment>
                                                    : (userData?.leave_type_setting?.map((leaveData, mainIndex) => (
                                                        <Grid item xs={6} maxWidth={"100%"} key={mainIndex}>
                                                            <Input
                                                                type="number"
                                                                label={`Opening Balance for ${leaveData?.name} (Days)`}
                                                                variant="standard"
                                                                sx={{ width: "100%" }}
                                                                parseFunction={Number.parseFloat}
                                                                name={"opening_balance"}
                                                                id={getInputId("opening_balance", mainIndex)}
                                                                onChange={(e) => handleChangeOpeningBalance(e, mainIndex)}
                                                                onBlur={(e) => validateOpeningBalance(e, mainIndex)}
                                                                value={leaveData?.opening_balance === 0 ? "0" : leaveData?.opening_balance || ""}
                                                                error={!!errors?.leave_type_setting && !!errors?.leave_type_setting[mainIndex]?.opening_balance}
                                                                helperText={errors?.leave_type_setting && errors?.leave_type_setting[mainIndex]?.opening_balance}
                                                            />
                                                        </Grid>
                                                    )
                                                    ))}
                                                <Grid item xs={6}
                                                    maxWidth={"100%"}
                                                    flex={1}
                                                    sx={{
                                                        input: {
                                                            width: "100%",
                                                            height: 30,
                                                            border: "none !important",
                                                            boxShadow: "none !important",
                                                            borderRadius: "0px",
                                                            paddingBottom: "6px",
                                                            paddingRight: "28px",
                                                            borderBottom:
                                                                "1px solid rgba(0, 0, 0, 0.42) !important",
                                                            margin: "0",
                                                            position: "relative",
                                                            background: "transparent",
                                                            color: "dark.800",
                                                            fontSize: 16,
                                                            fontWeight: 400,
                                                            lineHeight: "24px",
                                                            letterSpacing: "0.15px",
                                                        },
                                                        "input:hover": {
                                                            borderBottomColor:
                                                                "#333333 !important",
                                                        },
                                                        "input:focus": {
                                                            borderBottomColor:
                                                                "#047FE0 !important",
                                                        },
                                                        ".rmdp-container": {
                                                            minWidth: "100px",
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        id="demo-simple-select-standard-label"
                                                        fontSize={12}
                                                        fontWeight={400}
                                                        color="dark.600"
                                                        sx={{
                                                            width: "100%",
                                                            letterSpacing: "0.15px",
                                                            marginTop: "-3px",
                                                            marginBottom: "2px",
                                                        }}
                                                    >
                                                        Joining Date *
                                                    </Typography>
                                                    <Box sx={{ position: 'relative' }}>
                                                        <CalendarTodayIcon
                                                            sx={{
                                                                fontSize: 20,
                                                                color: "rgba(0, 0, 0, 0.56)",
                                                                position: 'absolute',
                                                                right: '0px',
                                                                top: '3px'
                                                            }}
                                                        />
                                                        <SingleDatePicker
                                                            value={userData?.joining_date !== null ? new Date(userData?.joining_date) : ''}
                                                            onChange={(value) => {
                                                                handleChangeJoiningDate(value)
                                                            }}
                                                            name={`joining_date`}
                                                            placeholder='Select Date'
                                                            isMultiple={false}
                                                            isEnableAllDate={true}
                                                        />
                                                    </Box>
                                                    {!!errors?.joining_date ? (
                                                        <Typography sx={{ color: "red", fontSize: "12px" }}>{errors?.joining_date}</Typography>
                                                    ) : null}
                                                </Grid>
                                                <Grid item xs={6} maxWidth={"100%"}>
                                                    <CustomTimePickerForUser
                                                        value={userData?.in_time ? new Date(userData?.in_time) : null}
                                                        onOpen={() => handleOpenTimePicker()}
                                                        onChange={(time) => handelTimeChange(time)}
                                                        placeholder={'In Time'}
                                                        label={`In Time`}
                                                        name={'in_time'}
                                                        error={errors?.in_time}
                                                        isMaxTime={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} maxWidth={"100%"}>
                                                    <FormControlLabel
                                                        id={getInputId("is_dedicated_developer")}
                                                        name="is_dedicated_developer"
                                                        control={<Checkbox checked={userData?.is_dedicated_developer} />}
                                                        label="Is Dedicated Developer?"
                                                        onChange={(event, checked) => {
                                                            setUserData((prev) => ({ ...prev, is_dedicated_developer: checked }));
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} maxWidth={"100%"}>
                                                    <FormControlLabel
                                                        id={getInputId("is_admin")}
                                                        name="is_admin"
                                                        value="end"
                                                        control={
                                                            <Checkbox checked={userData?.is_admin} />
                                                        }
                                                        label="Access to Admin Panel"
                                                        labelPlacement="end"
                                                        sx={{
                                                            // padding: "0px 9px 9px",
                                                            '.MuiCheckbox-sizeMedium': {
                                                                minWidth: '42px'
                                                            }
                                                        }}
                                                        disabled={userData?.is_general_user || false}
                                                        onChange={(event, checked) =>
                                                            setUserData((prev) => ({
                                                                ...prev,
                                                                is_admin: checked
                                                            }))
                                                        }
                                                    />
                                                </Grid>
                                                {['administrator', 'hr']?.includes(currentUser?.user_role?.toLowerCase()) ? <Grid item xs={6} maxWidth={"100%"}>
                                                    <FormControlLabel
                                                        id={getInputId("active")}
                                                        name="active"
                                                        control={<Checkbox />}
                                                        checked={true}
                                                        label="Active"
                                                        disabled={true}
                                                    />
                                                </Grid> : null}
                                                <Grid item xs={6} maxWidth={"100%"}>
                                                    <FormControlLabel
                                                        id={getInputId("enable_sys_generated_leave")}
                                                        name="enable_sys_generated_leave"
                                                        control={<Checkbox />}
                                                        label="Enable System Generated Leaves"
                                                        onChange={(event, checked) => {
                                                            setUserData((prev) => ({ ...prev, enable_sys_generated_leave: checked }));
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Stack>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        mt={4}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            flexBasis="100%"
                                            gap={2}
                                        >
                                            <Button
                                                variant="contained"
                                                size="large"
                                                type="submit"
                                                sx={{
                                                    boxShadow: "0",
                                                    fontSize: "15px",
                                                    textTransform: "none",
                                                    color: "white",
                                                    "&:hover": { boxShadow: "0" },
                                                }}
                                                disabled={isError()}
                                                isButtonLoading={loading.formSubmitting}
                                            >
                                                Create
                                            </Button>
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                size="large"
                                                sx={{
                                                    boxShadow: "0",
                                                    fontSize: "15px",
                                                    textTransform: "none",
                                                    "&:hover": { boxShadow: "0" },
                                                }}
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            }
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default CreateUser;