const API = {
  login: "/auth/login", // POST Method
  changePassword: "/auth/change-password", // POST Method
  logout: (userId) => `/auth/logout/${userId}`, // POST Method
  forgotPassword: "/auth/forgot-password", // POST Method
  resetPassword: "/auth/reset-password", // POST Method,
  getUserDataViaToken: `/auth/user-details`, // GET Method
  updateUserProfile: (id) => `/user/update-userProfileAndName/${id}`, // POST Method
  createOrganization: "/super-admin/organization", // POST Method
  getOrganization: (id) => `/super-admin/organization/${id}`, // GET Method
  getAllOrganization: "/super-admin/organization", // GET Method
  checkOrganisationUniqueName: "/organization/unique", // POST Method
  organisationBasicInfo: (id) => `/organization/basic-info/${id}`, // GET/PUT Method
  organisationLeaveSettings: (id) => `/organization/leave-settings/${id}`, // GET/PUT Method
  organisationUsersLeaveSettings: `/organization/update-user-settings`, // GET/PUT Method
  getOrganisationWorkTypes: `/organization/work-types`, // GET Method
  getWorkTypesByOrganisationId: (id) => `/organization/work-types/${id}`, // GET Method
  getUserTypes: `organization/user-types`, // GET Method
  addUser: `/user`, // POST Method
  fetchUserDetails: (userId) => `/user/${userId}`, // GET Method
  getLeaveTypes: `/organization/leave-types`, // GET Method
  getUserLeaveDetailsById: (id, org_year_id) =>
    `/user/leave-details/${id}/${org_year_id}`, // GET Method
  updateUserLeaveDetailsById: (id, org_year_id) =>
    `/user/leave-details/${id}/${org_year_id}`, // PUT Method
  getLeaveSpecialRequests: `organization/special-requests`, // GET Method
  getUserLeaveTypeData: (id) => `/leaves/user-leave-type/${id}`, // GET Method
  getUserSpecialCredits: (id) => `/user/special-credits/${id}`, // GET Method
  organisationHoliday: (id) => `organization/holiday/${id}`, // GET/PUT Method
  organisationUserSetting: (id) => `organization/users/${id}`, // GET/PUT Method
  organisationUserWithInformationAuthority: `/user/reporting-users`, // GET Method
  organisationUserRoles: (id) => `/organization/user-roles/${id}`, // GET/PUT Method
  organizationLeaveTypes: (id) => `/organization/org-leave-details/${id}`, // GET/PUT Method
  organisationUsersListByRoles: (orgId, workTypeId) =>
    `/user/user-role/${orgId}/${workTypeId}`, // GET Method
  skipStepForOnBoarding: (orgId, status) =>
    `/organization/skip-process/${orgId}/${status}`, // PUT Method
  changeOrganisationStatus: (orgId, status) =>
    `/super-admin/organization/${orgId}/status/${status}`, // PUT Method
  getAllLeavesForUser: (id) => `/leaves/list/${id}`, // POST Method
  getOthersLeaves: (id) => `/leaves/others-leave/${id}`, // GET Method
  getOneUserLeaves: (otherUserId, userId, status) =>
    `/leaves/others-leave/${otherUserId}/user/${userId}/${status}`, // GET Method
  getUserWfhApplicationList: (id) => `/wfh/wfh-app/${id}`, // GET Method
  getOtWfhApplicationList: (id) => `/wfh/others/${id}`, // GET Method
  getOneUserWfhList: (otherUserId, userId, status) =>
    `/wfh/others/${otherUserId}/user/${userId}/${status}`, // GET Method
  applyLeaveSummary: (id) => `/leaves/${id}`, // POST method
  applyLeaveSummaryWithoutCredit: (id) => `/leaves/spcd-disable/${id}`, // POST method
  updateLeaveStatus: (userId, leaveId) =>
    `/leaves/approve/${userId}/${leaveId}`, // PUT method
  updateWfhApplicationStatus: (userId, leaveId) =>
    `/wfh/approve/${userId}/${leaveId}`, // PUT methodgetUsersLeaveList: `leaves/dashboard`, // POST method
  createLeaveApplication: (id) => `/leaves/add/${id}`, // POST Method
  createWFHApplication: (id) => `/wfh/add/${id}`, // POST Method
  getLeaveById: (userId, leaveId) => `/leaves/${userId}/${leaveId}`, // GET Method
  getWfhApplicationById: (userId, leaveId) => `/wfh/${userId}/${leaveId}`, // GET Method
  getWfhDisabledDatesById: (userId) => `/wfh/dates/${userId}`, // GET Method
  updateLeaveById: (userId, leaveId) => `/leaves/${userId}/${leaveId}`, // PUT Method
  deleteLeaveAttachment: () => `/leaves/attachment`, // PUT Method
  deleteWfhRequestAttachment: `/wfh/attachment`, // PUT Method
  updateWfhApplicationById: (userId, leaveId) => `/wfh/${userId}/${leaveId}`, // PUT Method
  deleteLeaveById: (userId, leaveId) => `/leaves/${userId}/${leaveId}`, // Delete Method
  deleteWfhApplicationById: (userId, leaveId) => `/wfh/${userId}/${leaveId}`, // Delete Method
  getLeaveBalance: (userId) => `/leaves/leave-balance/${userId}`, // GET Method
  getSpecialCredits: (userId) => `/wfh/${userId}`, // GET Method
  getHolidayYears: (orgId) => `/holiday/${orgId}`, // GET Method
  createOrgYear: (orgId) => `/holiday/year/${orgId}`, // GET Method
  getAllUsersDataByOrgId: (id) => `/user/organization/${id}`, // POST Method
  updateHolidaySetting: (org_id, year_id) => `/holiday/${org_id}/${year_id}`, // PUT Method
  getHolidayDatesBySettings: (org_id, year_id) =>
    `/holiday/${org_id}/${year_id}`, // POST Method
  getAllUserHolidays: (orgId, dateId) => `/holiday/${orgId}/${dateId}`, // GET Method
  getAllHolidaysYear: (orgId) => `/holiday/${orgId}`, // GET Method
  deleteHoliday: (holidayType, id) => `/holiday/${holidayType}/${id}`, // DELETE Method
  isEmailUnique: `/user/unique`, // POST method
  getUserTimeTracker: `/user-tracking/calculation`, // GET method
  getUserSummary: `/user-tracking/summary`, // GET method
  addOverTime: "/user-tracking/missing-time", // POST Method
  getUserActivityReport: `/user-tracking/summary-report`, // GET method
  checkOutTimeTracker: `/user-tracking/checkout`, // POST method
  getAllHolidayTitle: `/office-holiday/`, // Get Method
  getInOutReport: `/user-tracking/in-out/reports`, // POST Method
  getByIdHolidayTitle: (id) => `/office-holiday/${id}`, // Get Method
  createOfficeHolidayTitle: `/office-holiday/`, // POST Method
  updateOfficeHolidayTitle: (id) => `/office-holiday/${id}`, // PUT Method
  deleteOfficeHolidayTitle: (id) => `/office-holiday/${id}`, // DELETE Method
  readNotification: (id) => `/notifications/mark-read/${id}`, // PUT Method
  getAllNotifications: (userId, limit = 15, offSet = 0, type = "unread") =>
    `/notifications/all/${userId}/${limit}/${offSet}/${type}`, // PUT Method
  getNightMode: `/user-tracking/daily-tracker`, // GET Method
  updateNightMode: (id, value) => `/user-tracking/${id}/night-mode/${value}`, // PUT Method
  getAllActivity: (id) => `/activity/getAllActivity/${id}`, // GET method
  getAllActivityByWorkType: (workTypeId) => `/activity/work-type/${workTypeId}`, // GET method
  getActivityById: (id) => `/activity/findOneActivity/${id}`, // GET method
  deleteActivityById: (id) => `/activity/deleteActivity/${id}`, // DELETE method
  createActivity: `/activity/create-activity`, // POST method
  updateActivity: `/activity/updateActivityRecord`, // PUT method
  updateWorkTypeActivity: `/organization/activities`, // PUT method
  checkActivityUniqueName: "/activity/unique", // POST Method
  getLeaveReportList: "/leaves/leave-report", // POST method
  getRepoteesListByUserId: `/user/reporting-auth`, // GET Method
  getUserLeaveReportById: (userId, id) => `/user/leave-report/${userId}/${id}`, // GET method
  organisationProjectRoles: (id) => `/project/roles/${id}`, // GET , POST, PUT, DELETE method
  createProject: (orgId) => `/project/${orgId}`, // POST method
  updateProject: (projectId) => `/project/${projectId}`, // PUT method
  staredProject: (projectId, userId, starred = false) => `/project/star/${projectId}/${userId}/${!starred}`,
  getProjectDetailsById: (projectId) => `/project/details/${projectId}`, // GET method
  getAllUsersDataListByProjectId: (orgId, projectId) =>
    `/project/team-members/suggestions/${orgId}/${projectId}`, // GET method
  getAllProjectUsersList: (projectId) => `/project/team-members/${projectId}`, // GET method
  updateProjectMembersData: (projectId) => `/project/team-members/${projectId}`, // PUT method
  activateProjectMember: (userId) => `/project/team-members/active/${userId}`, // PUT method
  getAllProjectsList: (orgId) => `/project/${orgId}`, // GET
  getAllProjectsListWithMembers: `/project/list`, // POST
  checkProjectUniqueName: `project/unique`, // POST
  getSystemGeneratedLeaveList: "/leaves/system/user/sys-generated-leaves", // GET Method
  // updated administrator leave settings api
  getOrganisationBasicSetting: (OrgId) => `/organization/basic-setting/${OrgId}`, // GET method
  getWorkTypesByOrganisationYearId: (OrgId, YearId) => `/organization/work-type-setting/${OrgId}/${YearId}`, // GET method
  updateOrganisationBasicSetting: (OrgId) => `/organization/basic-setting/${OrgId}`, // PUT method
  getUserProjectList: (userId) => `/user/project-details/${userId}`, // GET method
  updateProjectForUser: `/user/project-details`, // PUT method
  GET_CURRENT_STATUS_REPORT: `/dashboard/users/current-status`, // POST method
  getProjectPhases: (projectId) => `/project/phase/list/${projectId}`, // GET method
  updateProjectPhase: (phaseId) => `/project/phase/${phaseId}`, // PUT method
  deleteProjectPhase: (phaseId) => `/project/phase/${phaseId}`, // DELETE method
  createProjectPhase: `/project/phase/add-project-phase`, // POST method
  addProjectPhaseHours: `project/phase-hour/add-project-phase-hour`, // POST method
  updateProjectPhaseHours: (phaseId) => `/project/phase-hour/${phaseId}`, // PUT method
  deleteProjectPhaseHours: (phaseHourId) => `/project/phase-hour/delete-hours/${phaseHourId}`, // DELETE method
  getOthersUsersLeaveList: `dashboard/leaves/others`, // POST method
  getUsersLeaveList: `dashboard/leaves/my-pending`, // get method
  createOrgWorkType: "/org-work-type/create", // Post
  updateOrgWorkType: `/org-work-type/update`, // Put
  activeInactiveOrgWorkType: (worktypeId) => `/org-work-type/active-inactive/${worktypeId}`, // Put
  importContactsData: "/campaign/recipient/import", // POST
  getAllContactsListPaginate: "/campaign/recipient/list", // POST
  getAllContactsList: "/campaign/recipient", // GET
  createContact: "/campaign/recipient", // POST
  updateContact: (recordId) => `/campaign/recipient/${recordId}`, // PUT
  deleteContact: (recordId) => `/campaign/recipient/${recordId}`, // Delete
  createEmailTemplate: "/campaign/template/add", // POST
  updateEmailTemplate: (templateId) => `campaign/template/update/${templateId}`, // PUT
  getSingleTemplateData: (templateId) => `/campaign/template/${templateId}`, // GET
  getAllTemplateList: "/campaign/template/list", // POST
  sendEmailTemplateToContacts: "campaign/recipient/send-emails", // POST
};

export default API;
