import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activityCountData: [],
    allActivityData: [],
};

const activityActionDataSlice = createSlice({
    name: 'actionData',
    initialState,
    reducers: {
        setIsActionData: (state, action) => {
            state.activityCountData = action.payload;
        },
        setAllActivityData: (state, action) => {
            state.allActivityData = action.payload;
        },
        clearActionData: (state) => {
            state.activityCountData = [];
            state.allActivityData = [];
        }
    },
});

export const { setIsActionData, setAllActivityData, clearActionData } = activityActionDataSlice.actions;
export default activityActionDataSlice.reducer;
