import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MultipleDatePicker from "../../../../../components/MultipleDatePicker";
import API from "../../../../../axios/api";
import axiosInstance from "../../../../../axios";
import { useAlert } from "../../../../../hook/useAlert";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import Button from "../../../../../components/Button";
import { convertDateFromFormat, findDeletedDataByDate, getFormattedDate, getInputId, getReportingAuthority, sortArrayByKey } from "../../../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import URLS from "../../../../../routes/urls";
import AutocompleteSelect from "../../../../../components/AutocompleteSelect";
import Breadcrumb from "../../../../../components/Breadcrumb";
import { getTime } from "../../../../../constants/default-values";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const EditLeave = ({
  setIsAppliedLeave,
  setLeaveData,
  setAutoGeneratedLeaveDays,
  leaveData,
  userLeaveTypes,
  organizationUserList,
  currentUser,
  setErrors,
  errors,
  leaveSnapshot,
  setDeletedDays,
  deletedDays,
  setHalfRequestData,
  halfRequestData,
  optionalLeaveDays
}) => {
  const classes = useStyles();
  const showAlert = useAlert();
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState({
    formSubmitting: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLeaveData(prev => {
      let _value = null;
      if (name === "informed_authorities") {
        _value = [value];
      } else {
        _value = value;
      }
      return {
        ...prev,
        [name]: _value,
        is_leave_type_changed: name === "user_leave_type_setting_id" && leaveData?.user_leave_type_setting_id !== _value ? true : false,
        leave_days: leaveData?.leave_days?.map((day) => ({
          ...day,
          is_changed: name === "user_leave_type_setting_id" && leaveData?.user_leave_type_setting_id !== _value ? true : false
        }))
      };
    });
    handleBlur(event);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    let updatedErrors = { ...errors };
    if (['leave_days'].includes(name)) {
      if (!!value?.length) {
        updatedErrors[name] = "";
      } else {
        updatedErrors[name] = "Required";
      }
    } else {
      if (!!value) {
        updatedErrors[name] = "";
      } else {
        updatedErrors[name] = "Required";
      }
    }
    setErrors(updatedErrors)
  };

  const getLeaveDates = (isGetType = false) => {
    if (isGetType) {
      return leaveData?.leave_days?.map((leaveData) => ({
        leave_date: leaveData?.leave_date,
        leave_duration: leaveData?.leave_duration || "full",
        paid: leaveData?.paid || 0,
        unpaid: leaveData?.unpaid || 0,
        id: leaveData?.id || null,
        in_time: leaveData?.in_time || null,
        out_time: leaveData?.out_time || null,
        rules: leaveData?.rules || [],
        specialRequest: leaveData?.specialRequest || [],
        is_changed: leaveData?.is_changed || false
      }))
    }
    else {
      return leaveData?.leave_days?.map((leaveData) => !!leaveData?.leave_date ? getFormattedDate(leaveData?.leave_date) : leaveData);
    }
  }

  const handleSelectMultipleDate = (selectedDates) => {
    const dates = selectedDates?.map((date) => {
      // let convertedDate = typeof date === "string" ? date : getFormattedDate(new Date(date), "DD/MM/YYYY", true);
      // let data = leaveData?.leave_days?.find(item => item?.leave_date === convertedDate);
      let convertedDate = typeof date === "string" ? date : getFormattedDate(new Date(date), 'YYYY-MM-DD');
      convertedDate = typeof convertedDate === "string" && convertedDate?.includes('/') ? convertDateFromFormat(convertedDate, "YYYY-MM-DD", "DD/MM/YYYY") : convertedDate;
      let data = leaveData?.leave_days?.find(item => item?.date === convertedDate);
      let isDateExist = halfRequestData?.find(leaveData => leaveData?.leave_date === convertedDate);
      let timeObj = {};
      if (!!isDateExist) {
        if (optionalLeaveDays?.id === isDateExist?.id) {
          if (optionalLeaveDays?.leave_duration === 'first_half') {
            timeObj['out_time'] = getTime(optionalLeaveDays?.in_time)
          } else if (optionalLeaveDays?.leave_duration === 'second_half') {
            timeObj['in_time'] = getTime(optionalLeaveDays?.out_time)
          }
        } else {
          if (isDateExist?.leave_duration === 'first_half') {
            timeObj['out_time'] = getTime(isDateExist?.in_time)
          } else if (isDateExist?.leave_duration === 'second_half') {
            timeObj['in_time'] = getTime(isDateExist?.out_time)
          }
        }
      }
      if (!!data) {
        return {
          ...data,
          leave_duration: !!isDateExist ? isDateExist?.leave_duration === 'first_half' ? 'second_half' : 'first_half' : "full",
          ...timeObj,
        };
      } else {
        return {
          ...timeObj,
          leave_date: convertedDate,
          leave_duration: !!isDateExist ? isDateExist?.leave_duration === 'first_half' ? 'second_half' : 'first_half' : "full",
          paid: 0,
          unpaid: 0,
          rules: [],
          specialRequest: [],
          is_changed: false,
        }
      }
    });

    const deletedData = findDeletedDataByDate(leaveData?.leave_days, sortArrayByKey(dates, "asc", "leave_date"), 'leave_date');
    setDeletedDays((prev) => ([...prev, ...deletedData?.filter((data) => data?.id)]))
    setLeaveData((prev) => ({ ...prev, leave_days: dates }))
    let updatedErrors = { ...errors };
    if (!!dates?.length) {
      updatedErrors['leave_days'] = "";
    } else {
      updatedErrors['leave_days'] = "Required";
    }
    setErrors(updatedErrors)
  };

  const handleSubmitLeaveSummary = async (body) => {
    try {
      setIsLoading((prev) => ({ ...prev, formSubmitting: true }));
      const response = await axiosInstance.post(
        API.applyLeaveSummary(currentUser?.id),
        body
      );
      if (response.status === 200) {
        const responseData = response?.data?.result;
        setIsAppliedLeave(true);
        setLeaveData((prev) => ({
          ...prev,
          club_leave_count: response?.club_leave_count || 0,
          leave_days: responseData?.leave_days,
          remaining_days: responseData?.remaining_days,
          remaining_credits: responseData?.remaining_credits,
          user_leave_type_setting_id: body?.user_leave_type_setting_id
        }));
        let data = responseData?.leave_days?.map(value => ({ ...value, is_changed: false }))?.filter((value) => !!value?.isAutoGenerated)
        setAutoGeneratedLeaveDays(prev => [...prev, ...data])
        setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      } else {
        showAlert(response?.response?.data?.message, "error");
        setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      showAlert(error?.response?.data?.message, "error");
    }
  };

  const handleSubmitLeaveSummaryWithoutCredit = async (body) => {
    try {
      setIsLoading((prev) => ({ ...prev, formSubmitting: true }));
      const response = await axiosInstance.post(
        API.applyLeaveSummaryWithoutCredit(currentUser?.id),
        body
      );
      if (response.status === 200) {
        const responseData = response?.data?.result;
        setIsAppliedLeave(true);
        setLeaveData((prev) => ({
          ...prev,
          club_leave_count: response?.club_leave_count || 0,
          leave_days: responseData?.leave_days,
          remaining_days: responseData?.remaining_days,
          remaining_credits: responseData?.remaining_credits,
          user_leave_type_setting_id: body?.user_leave_type_setting_id
        }));
        let data = responseData?.leave_days?.map(value => ({ ...value, is_changed: false }))?.filter((value) => !!value?.isAutoGenerated)
        setAutoGeneratedLeaveDays(prev => [...prev, ...data])
        setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      } else {
        showAlert(response?.response?.data?.message, "error");
        setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      showAlert(error?.response?.data?.message, "error");
    }
  }

  const validateData = (data) => {
    let updatedErrors = {};
    if (!data?.informed_authorities?.length) {
      updatedErrors['informed_authorities'] = "Required";
    }

    if (!data?.user_leave_type_setting_id) {
      updatedErrors['user_leave_type_setting_id'] = "Required";
    }

    if (!data?.leave_days?.length) {
      updatedErrors['leave_days'] = "Required";
    }

    setErrors((prev) => ({ ...prev, ...updatedErrors }));
    return Object.keys(updatedErrors)?.length;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      user_leave_type_setting_id: leaveData?.user_leave_type_setting_id,
      club_leave_count: leaveData?.club_leave_count || 0,
      leave_days: [...getLeaveDates(true), ...deletedDays?.map((day) => ({ ...day, leave_date: day?.leave_date, is_deleted: true }))],
      remaining_days: leaveData?.remaining_days || 0,
      is_edit: true,
      leave_application_id: parseInt(params?.appId),
      is_leave_type_changed: leaveData?.is_leave_type_changed,
    };
    if (!validateData(leaveData)) {
      let specialCredits = leaveSnapshot.find((snap) => Object.keys(snap).includes("specialCredits"));
      if (specialCredits?.allow_special_credits) {
        handleSubmitLeaveSummary(payload);
      }
      else {
        handleSubmitLeaveSummaryWithoutCredit(payload)
      }
    }
  }

  const handleBackClick = () => {
    navigate(-1);
  }

  return (
    <>
      <Grid width={'100%'}>
        <Breadcrumb isBack={true} title={"My Leaves"}
          pageTitle={'Leave Detail'}
          isPathShow={false}
          onBackClick={() => handleBackClick()} />
        <Box p={{ xs: 2, lg: 3, xl: 4 }}>
          <form onSubmit={handleSubmit}>
            <Box width={768} sx={{ maxWidth: "100%" }}>
              <Stack spacing={3} mb={4}>
                <Stack
                  spacing={4}
                  direction="row"
                  alignItems="flex-start"
                  width="100%"
                >
                  <Grid item sx={{ flex: '0 0 calc(50% - 16px)' }}>
                    <FormControl variant="standard" sx={{ m: 0, width: "100%" }}>
                      <InputLabel htmlFor={getInputId("user_leave_type_setting_id", 0)}>
                        Leave Type
                      </InputLabel>
                      <Select
                        id="user_leave_type_setting_id"
                        inputProps={{ id: getInputId("user_leave_type_setting_id", 0) }}
                        name="user_leave_type_setting_id"
                        onChange={handleChange}
                        value={leaveData?.user_leave_type_setting_id}
                        onBlur={handleBlur}
                        label="Leave Type"
                      >
                        {userLeaveTypes?.map(
                          ({ name, user_leave_type_setting_id }, index) => (
                            <MenuItem value={user_leave_type_setting_id} key={index}>
                              {name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {!!errors?.user_leave_type_setting_id ?
                        <FormHelperText sx={{ color: "red" }}>
                          {!!errors?.user_leave_type_setting_id ? errors?.user_leave_type_setting_id : ""}
                        </FormHelperText>
                        : null}
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ flex: '0 0 calc(50% - 16px)', maxWidth: 'calc(50% - 16px)' }}>
                    <FormControl
                      className={classes.margin}
                      variant="standard"
                      sx={{
                        margin: "0px !important",
                        width: "100%",
                        // "div ": { marginTop: "2px !important" },
                      }}
                    >
                      <AutocompleteSelect
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={sortArrayByKey(organizationUserList, 'asc', 'name')}
                        isOptionEqualToValue={(option, value) => getReportingAuthority(organizationUserList, leaveData?.informed_authorities?.[0]) ?? {}}
                        value={getReportingAuthority(organizationUserList, leaveData?.informed_authorities?.[0])}
                        label={"Request To"}
                        name="informed_authorities"
                        id="informed_authorities"
                        disableClearable={!getReportingAuthority(organizationUserList, leaveData?.informed_authorities?.[0])}
                      />
                      <FormHelperText>
                        Along with this user, Leave application will be sent to HR and
                        Admin users of your organisation.
                      </FormHelperText>
                      {!!errors?.informed_authorities ?
                        <FormHelperText sx={{ color: "red" }}>
                          {!!errors?.informed_authorities ? errors?.informed_authorities : ""}
                        </FormHelperText>
                        : null}
                    </FormControl>
                  </Grid>
                </Stack>
                {/* <Alert width="100%" severity="info">
                Text here for Casual Leave and it will change as per leave type.
              </Alert> */}
                <Grid item flex={1}>
                  <Typography fontWeight={400} fontSize={14} color="dark.800" sx={{ lineHeight: '21px', letterSpacing: '0.15px' }}>Leave Dates</Typography>
                  <MultipleDatePicker
                    extraDisableDates={getLeaveDates()}
                    name={"leave_days"}
                    value={getLeaveDates()}
                    onBlur={handleBlur}
                    onChange={(date) => handleSelectMultipleDate(date)}
                    plugins={[
                      <DatePanel markFocused />,
                      <Toolbar
                        position="bottom"
                        sort={["deselect", "today", "close"]}
                        names={{
                          deselect: "Deselect",
                          today: "Today",
                          close: "Select",
                        }}
                      />,
                    ]}
                  />
                  {!!errors?.leave_days ?
                    <FormHelperText sx={{ color: "red" }}>
                      {errors?.leave_days}
                    </FormHelperText>
                    : null}
                </Grid>
              </Stack>
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  color: "white",
                  textTransform: "none",
                  marginRight: "20px",
                  boxShadow: "none",
                }}
                type="submit"
                disabled={!!Object.values(errors).filter(item => !!item)?.length}
                isButtonLoading={isLoading.formSubmitting}
              >
                Proceed
              </Button>
              <Button
                color="secondary"
                size="large"
                variant="text"
                sx={{
                  textTransform: "none",
                }}
                type="reset"
                onClick={() => navigate(`${URLS.Leaves}?view=list&tabValue=1&groupBy=Status&status=active`)}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Grid>
    </>
  );
};

export default EditLeave;