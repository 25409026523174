import { Box, Collapse, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, tooltipClasses, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Button from '../../../../components/Button'
import { getApplicationNumber, getFormattedDate, getLeaveStatus, isDashedYearBasedOnMonth, isProvisionLeave, sortRequestArrayByDate, titleCase, updateSystemGeneratedLeaveApplicationStatus } from '../../../../utils'
import { ReactComponent as ExpandIcon } from "../../../../assets/images/expand-icon.svg";
import { ReactComponent as CollapseIcon } from "../../../../assets/images/collapse-icon.svg";
import { useNavigate } from 'react-router-dom';
import URLS from '../../../../routes/urls';
import LaunchIcon from '@mui/icons-material/Launch';
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import CircularLoader from '../../../../components/CircularLoader';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import { LEAVE_STATUS } from '../../../../constants/default-values';
import { styled } from '@mui/styles';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "18px",
    borderRadius: "5px",
    background: "#3F5C76",
    maxWidth: "366px",
  },
}));

const UserLeaveReport = ({ activeYear, userData, years, currentUser, isEditable, toggleEdit, getYearObjectById }) => {
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState("");
  const [isExpandAll, setIsExpandAll] = useState(false);
  const [open, setOpen] = useState([]);
  const [isLoading, setIsLoading] = useState({
    reportData: false,
  });
  const [leaveReportData, setLeaveReportData] = useState([]);
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  useEffect(() => {
    let activeYear = years?.find(yearData => yearData?.active);
    setSelectedYear(activeYear?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedYear) {
      getUserLeaveReportData(selectedYear);
      setIsExpandAll(false);
      setOpen([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear])

  useEffect(() => {
    if (isExpandAll) {
      setOpen(leaveReportData?.map((leaveData) => leaveData?.month));
    } else {
      setOpen([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpandAll])

  const getUserLeaveReportData = async (id) => {
    try {
      setIsLoading((prev) => ({ ...prev, reportData: true }))
      const response = await axiosInstance.get(API.getUserLeaveReportById(userData?.id, id));
      if (response?.status === 200) {
        // let reverseReportData = [...response?.data?.data]?.reverse();
        let updatedStatusdata = response?.data?.data?.map((reportData, index) => {
          if (reportData?.leaveData?.length) {
            return {
              ...reportData,
              leaveData: reportData?.leaveData?.map((leaveDetails) => {
                return updateSystemGeneratedLeaveApplicationStatus(leaveDetails)
              })
            }
          }
          return reportData;
        })

        let updatedReponseData = [];

        let updatedBalanceData = updatedStatusdata?.map((reportData, index) => {
          let totalSickBalance = 0;
          let totalCasualBalance = 0;

          if (index === updatedStatusdata?.findIndex(reportDetails => !!reportDetails?.leaveTypeSetting)) {
            totalSickBalance = getPreviousBalanceByLeaveType(
              getOpeningLeaveBalanceByType(reportData?.leaveTypeSetting, 'Sick Leave') ?? 0,
              getAllotedLeaveByType(reportData?.leaveTypeSetting, 'Sick Leave') ?? 0,
              getTakenLeaveByType(reportData?.leaveData, 'Sick Leave') ?? 0
            )

            totalCasualBalance = getPreviousBalanceByLeaveType(
              getOpeningLeaveBalanceByType(reportData?.leaveTypeSetting, 'Casual Leave') ?? 0,
              getAllotedLeaveByType(reportData?.leaveTypeSetting, 'Casual Leave') ?? 0,
              getTakenLeaveByType(reportData?.leaveData, 'Casual Leave') ?? 0
            );
          } else {
            totalSickBalance = getPreviousBalanceByLeaveType(
              updatedReponseData[index - 1]?.totalSickBalance ?? 0,
              getAllotedLeaveByType(reportData?.leaveTypeSetting, 'Sick Leave') ?? 0,
              getTakenLeaveByType(reportData?.leaveData, 'Sick Leave') ?? 0
            )

            totalCasualBalance = getPreviousBalanceByLeaveType(
              updatedReponseData[index - 1]?.totalCasualBalance ?? 0,
              getAllotedLeaveByType(reportData?.leaveTypeSetting, 'Casual Leave') ?? 0,
              getTakenLeaveByType(reportData?.leaveData, 'Casual Leave') ?? 0
            );
          }
          updatedReponseData.push({
            ...reportData,
            totalSickBalance: totalSickBalance,
            totalCasualBalance: totalCasualBalance,
          })
          return {
            ...reportData,
            totalSickBalance: totalSickBalance,
            totalCasualBalance: totalCasualBalance,
          }
        })

        setLeaveReportData(updatedBalanceData?.reverse());
      } else {
        setLeaveReportData([]);
      }
      setIsLoading((prev) => ({ ...prev, reportData: false }))
    } catch (error) {
      console.error(error);
      setLeaveReportData([]);
      setIsLoading((prev) => ({ ...prev, reportData: false }))
    }
  }

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleLeaveClick = (leave) => {
    let { user_id, id } = leave;
    navigate(`${URLS.OtherLeaveDetails}/${user_id}/${id}`);
  };

  const getYearObject = (yearId) => {
    return years?.find((year) => year?.id === yearId);
  }

  const getMonthData = (month, index) => {
    let findLastMonthOfYear = leaveReportData?.findIndex((data) => data?.month?.toLocaleLowerCase() === "december");
    return `${month} ${findLastMonthOfYear <= index ? getYearObject(selectedYear)?.year : (Number(getYearObject(selectedYear)?.year) + 1)}`
  }

  const isGreaterMonth = (month, index) => {
    let findLastMonthOfYear = leaveReportData?.findIndex((data) => data?.month?.toLocaleLowerCase() === months[new Date()?.getMonth()]?.toLocaleLowerCase());
    return findLastMonthOfYear > index || getYearObject(selectedYear)?.year > activeYear?.year;
  }

  const getLeaveCount = (leaveDayData) => {
    if (leaveDayData?.leave_duration === "full") {
      return 1
    } else {
      return `0.5 ${leaveDayData?.leave_duration ===
        "first_half"
        ? "(1st Half)"
        : leaveDayData?.leave_duration ===
          "second_half"
          ? "(2nd Half)"
          : ""
        }`
    }
  }

  const getLeaveSetOff = (leaveDayData) => {
    if (leaveDayData?.leave_duration === "full") {
      return leaveDayData?.is_paid === 1 ? "Fully Paid" : leaveDayData?.is_paid === 0.5 ? "Half Paid / Half Unpaid" : "Fully Unpaid"
    } else {
      return leaveDayData?.is_paid === 0.5 ? "Fully Paid" : "Fully Unpaid"
    }
  }

  const getTotalLeavesCount = (leaveApplications) => {
    return leaveApplications?.reduce((prevData, leaveDetails) => {
      prevData.count = leaveDetails?.leave_days?.length + prevData?.count;
      return prevData;
    }, { count: 0 })?.count;
  }

  const getAllotedLeaveByType = (leaveSettingData, leaveType) => {
    return leaveSettingData?.find((settingData) => settingData?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name?.toLowerCase() === leaveType?.toLowerCase())?.alloted_days ?? 0;
  }

  const getOpeningLeaveBalanceByType = (leaveSettingData, leaveType) => {
    return leaveSettingData?.find((settingData) => settingData?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name?.toLowerCase() === leaveType?.toLowerCase())?.UserLeavesTypeSetting?.opening_balance ?? 0;
  }

  const getPreviousBalanceByLeaveType = (prevBalance, allotedLeave, takenLeave) => {
    let totalBalance = (prevBalance ?? 0) + ((allotedLeave ?? 0) - (takenLeave ?? 0));
    return totalBalance;
  }

  const getTakenLeaveByType = (leaveApplications, leaveType) => {
    let totalLeaveCount = leaveApplications?.length ? leaveApplications?.reduce((prevData, leaveDetails) => {
      if (leaveDetails?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name?.toLowerCase() === leaveType?.toLowerCase()) {
        let totalLeaveCount = leaveDetails?.leave_days?.reduce((currentLeavePrevCountData, currentLeaveData) => {
          if (currentLeaveData?.leave_duration === "full") {
            if (leaveDetails?.status !== LEAVE_STATUS?.Cancelled)
              currentLeavePrevCountData.count = 1 + currentLeavePrevCountData?.count;
          } else {
            if (leaveDetails?.status !== LEAVE_STATUS?.Cancelled)
              currentLeavePrevCountData.count = 0.5 + currentLeavePrevCountData?.count;
          }
          return currentLeavePrevCountData;
        }, { count: 0 })?.count
        prevData.count = totalLeaveCount + prevData?.count;
      }
      return prevData;
    }, { count: 0 })?.count : 0;
    return totalLeaveCount;
  }

  const getTotalBalance = (prevBalance, allotedLeave, takenLeave) => {
    let totalBalance = prevBalance + (allotedLeave - takenLeave);
    return totalBalance < 0 ?
      <>
        <Typography display={'span'} fontSize={14} fontWeight={700} lineHeight={'14px'}>
          {0}
        </Typography>
        <Typography display={'span'} color={'dark.500'} fontSize={11} fontStyle={'italic'} fontWeight={600} lineHeight={'10px'} letterSpacing={'0.17px'}>
          {Math.abs(totalBalance)} Unpaid
        </Typography>

      </> :
      <Typography display={'span'} fontSize={14} fontWeight={600}>{prevBalance + (allotedLeave - takenLeave)}</Typography>;
  }

  const getProvisionTakenLeaveByType = (leaveApplications, leaveType, isTotal = false) => {
    let totalProvisionLeaveCount = leaveApplications?.length ? leaveApplications?.reduce((prevData, leaveDetails) => {
      if (leaveDetails?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name?.toLowerCase() === leaveType?.toLowerCase()) {
        let totalProvisionLeaveCountOfApplication = leaveDetails?.leave_days?.reduce((currentLeavePrevCountData, currentLeaveData) => {
          if (currentLeaveData?.leave_duration === "full") {
            if (new Date(currentLeaveData?.leave_date)?.getMonth() === new Date()?.getMonth()) {
              if (leaveDetails?.status === LEAVE_STATUS?.Pending) {
                currentLeavePrevCountData.pendingProvisionCount = 1 + currentLeavePrevCountData?.pendingProvisionCount;
              } else {
                if (leaveDetails?.status === LEAVE_STATUS?.Approved) {
                  currentLeavePrevCountData.approvedCount = 1 + currentLeavePrevCountData?.approvedCount;
                }
              }
            } else {
              if (leaveDetails?.status === LEAVE_STATUS?.Pending) {
                currentLeavePrevCountData.pendingProvisionCount = 1 + currentLeavePrevCountData?.pendingProvisionCount;
              } else {
                if (leaveDetails?.status === LEAVE_STATUS?.Approved) {
                  if (!currentLeaveData?.is_paid > 0) {
                    currentLeavePrevCountData.approvedProvisionCount = 1 + currentLeavePrevCountData?.approvedProvisionCount;
                  } else if (currentLeaveData?.is_paid === 0.5) {
                    currentLeavePrevCountData.approvedProvisionCount = 0.5 + currentLeavePrevCountData?.approvedProvisionCount;
                    currentLeavePrevCountData.approvedCount = 0.5 + currentLeavePrevCountData?.approvedCount;
                  } else {
                    currentLeavePrevCountData.approvedCount = 1 + currentLeavePrevCountData?.approvedCount;
                  }
                }
              }
            }
          } else {
            if (new Date(currentLeaveData?.leave_date)?.getMonth() === new Date()?.getMonth()) {
              if (leaveDetails?.status === LEAVE_STATUS?.Pending) {
                currentLeavePrevCountData.pendingProvisionCount = 0.5 + currentLeavePrevCountData?.pendingProvisionCount;
              } else {
                if (leaveDetails?.status === LEAVE_STATUS?.Approved) {
                  currentLeavePrevCountData.approvedCount = 0.5 + currentLeavePrevCountData?.approvedCount;
                }
              }
            } else {
              if (leaveDetails?.status === LEAVE_STATUS?.Pending) {
                currentLeavePrevCountData.pendingProvisionCount = 0.5 + currentLeavePrevCountData?.pendingProvisionCount;
              } else {
                if (leaveDetails?.status === LEAVE_STATUS?.Approved) {
                  if (!currentLeaveData?.is_paid > 0) {
                    currentLeavePrevCountData.approvedProvisionCount = 0.5 + currentLeavePrevCountData?.approvedProvisionCount;
                  } else {
                    currentLeavePrevCountData.approvedCount = 0.5 + currentLeavePrevCountData?.approvedCount;
                  }
                }
              }
            }
          }
          return currentLeavePrevCountData;
        }, { pendingProvisionCount: 0, approvedProvisionCount: 0, approvedCount: 0 })
        prevData.pendingProvisionCount = totalProvisionLeaveCountOfApplication?.pendingProvisionCount + prevData?.pendingProvisionCount;
        prevData.approvedProvisionCount = totalProvisionLeaveCountOfApplication?.approvedProvisionCount + prevData?.approvedProvisionCount;
        prevData.approvedCount = totalProvisionLeaveCountOfApplication?.approvedCount + prevData?.approvedCount;
      }
      return prevData;
    }, { pendingProvisionCount: 0, approvedProvisionCount: 0, approvedCount: 0 }) : { pendingProvisionCount: 0, approvedProvisionCount: 0, approvedCount: 0 };
    return isTotal ? (totalProvisionLeaveCount?.pendingProvisionCount + totalProvisionLeaveCount?.approvedProvisionCount + totalProvisionLeaveCount?.approvedCount) : totalProvisionLeaveCount;
  }

  return (
    <Box display={"flex"} sx={{ width: '100%', flex: '1', overflow: 'hidden', }}>
      <Box display={"flex"} flexDirection={"column"} sx={{ width: '100%', flex: '1', overflow: 'hidden', }}>
        <Stack spacing={4} pb={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Grid item width={265} maxWidth={265}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel htmlFor="year_label-1">
                  Select Year
                </InputLabel>
                <Select
                  id='year-range'
                  name='year'
                  inputProps={{ id: "year_label-1" }}
                  value={selectedYear}
                  onChange={(e) => handleYearChange(e)}
                  label="Select Year"
                  sx={{
                    ".MuiSelect-select": {
                      fontSize: 14,
                      fontWeight: 400,
                      color: "dark.800",
                    },
                  }}
                >
                  {years?.map((yearData, index) => (
                    <MenuItem value={yearData?.id} key={index}>
                      {isDashedYearBasedOnMonth(
                        currentUser?.organization?.renewal_month,
                        yearData?.year?.toString()
                      )?.yearDashedFormat}
                      {/* {yearData?.year} */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item display={"flex"} justifyContent={"flex-end"} gap={2}>
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                startIcon={!isExpandAll ? <ExpandIcon /> : <CollapseIcon />}
                sx={{ color: "black", BorderColor: '##ddd', boxShadow: "none", textTransform: "none", minWidth: '130px' }}
                onClick={() => setIsExpandAll(prev => !prev)}
              >
                {!isExpandAll ? "Expand All" : "Collapse All"}
              </Button>
              {(!isEditable && (years.find(yearData => yearData?.active)?.year === getYearObjectById(selectedYear)?.year)) ?
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  sx={{ color: "white", boxShadow: "none", textTransform: "none" }}
                  onClick={toggleEdit}
                  disabled={!(years.find(yearData => yearData?.active)?.year === getYearObjectById(selectedYear)?.year)}
                >
                  Leave Settings
                </Button> : null}
            </Grid>
          </Box>
        </Stack>
        <Box flex={1} overflow={"hidden"} display={"flex"}>
          {
            isLoading?.yearLoading ? <CircularLoader height="400px" width="1100px" minWidth="1100px" /> :
              <TableContainer sx={{ border: '1px solid #ddd', borderRadius: '3px', overflow: 'overlay' }}>
                <Table
                  aria-labelledby="tableTitle"
                  size={'medium'}
                  aria-label="collapsible table"
                >
                  <TableHead sx={{ position: 'sticky', top: '0', zIndex: '10' }}>
                    <TableRow fontSize={14} fontWeight={500} lineheight={'24px'} letterSpacing={'0.17px'} alignitems={'center'} sx={{ backgroundColor: '#F5F8FA', textAlign: 'center', height: '36px', maxHeight: '36px' }}>
                      <TableCell sx={{ minWidth: '160px', padding: '6px 16px 6px 16px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Month</TableCell>
                      <TableCell colSpan={2} sx={{ minWidth: '225px', padding: '6px 16px 6px 16px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Prev. Balance (A)</TableCell>
                      <TableCell colSpan={2} sx={{ minWidth: '225px', padding: '6px 16px 6px 16px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Allotted (B)</TableCell>
                      <TableCell colSpan={2} sx={{ minWidth: '225px', padding: '6px 16px 6px 16px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Taken (C)</TableCell>
                      <TableCell colSpan={2} sx={{ minWidth: '225px', padding: '6px 16px 6px 16px', boxShadow: 'inset 0px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>= Balance (A+B-C)</TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: '#F5F8FA' }}>
                      <TableCell sx={{ minWidth: '160px', padding: '2px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', }} />
                      <TableCell sx={{ minWidth: '112px', padding: '2px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Casual</TableCell>
                      <TableCell sx={{ minWidth: '112px', padding: '2px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Sick</TableCell>
                      <TableCell sx={{ minWidth: '112px', padding: '2px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Casual</TableCell>
                      <TableCell sx={{ minWidth: '112px', padding: '2px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Sick</TableCell>
                      <TableCell sx={{ minWidth: '112px', padding: '2px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Casual</TableCell>
                      <TableCell sx={{ minWidth: '112px', padding: '2px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Sick</TableCell>
                      <TableCell sx={{ minWidth: '112px', padding: '2px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Casual</TableCell>
                      <TableCell sx={{ minWidth: '112px', padding: '2px', boxShadow: 'inset 0px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center' }}>Sick</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{}}>
                    {isLoading?.reportData ?
                      <CircularLoader variant="table" cols={9} rows={10} /> :
                      leaveReportData?.map((monthData, index) => (
                        <React.Fragment key={index}>
                          <TableRow style={{ padding: '6px 16px 6px 16px' }}
                            sx={{ height: '40px', maxHeight: '40px', cursor: "pointer", backgroundColor: (months[new Date().getMonth()] === monthData?.month && getYearObject(selectedYear)?.year === activeYear?.year) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }}
                            onClick={() => setOpen(prev => prev?.includes(monthData?.month) ? prev?.filter(list => list !== monthData?.month) : [...prev, monthData?.month])}>
                            <TableCell style={{ padding: '6px 16px 6px 16px' }}
                              sx={{ boxShadow: open?.includes(monthData?.month) ? 'inset -1px 0px 0px #ddd' : 'inset -1px 0px 0px #ddd', alignContent: "center" }}
                            >
                              <IconButton size="small">
                                {!open?.includes(monthData?.month) ? <ExpandIcon /> : <CollapseIcon />}
                              </IconButton>
                              <Typography
                                component="span" fontSize={14}
                                fontWeight={open?.includes(monthData?.month) ? 700 : 400}
                                lineHeight={'20px'} letterSpacing={'0.17px'}
                                sx={{ color: '#3F5C76', verticalAlign: 'middle' }}>
                                {getMonthData(monthData?.month, index)}
                              </Typography>
                            </TableCell>
                            {/* Prev. Balance (A) */}
                            <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ boxShadow: open?.includes(monthData?.month) ? ' inset -1px 0px 0px #ddd' : 'inset -1px 0px 0px #ddd', textAlign: 'center' }}>{isGreaterMonth(monthData?.month, index) ? '-' :
                              index === leaveReportData?.findLastIndex(reportDetails => !!reportDetails?.leaveTypeSetting) ? getOpeningLeaveBalanceByType(monthData?.leaveTypeSetting, 'Casual Leave') :
                                leaveReportData[index + 1]?.totalCasualBalance < 0 ? 0 : leaveReportData[index + 1]?.totalCasualBalance ?? 0}
                            </TableCell>
                            <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ boxShadow: open?.includes(monthData?.month) ? ' inset -1px 0px 0px #ddd' : 'inset -1px 0px 0px #ddd', textAlign: 'center' }}>{isGreaterMonth(monthData?.month, index) ? '-' :
                              index === leaveReportData?.findLastIndex(reportDetails => !!reportDetails?.leaveTypeSetting) ? getOpeningLeaveBalanceByType(monthData?.leaveTypeSetting, 'Sick Leave') :
                                leaveReportData[index + 1]?.totalSickBalance < 0 ? 0 : leaveReportData[index + 1]?.totalSickBalance ?? 0}
                            </TableCell>
                            {/* Allotted (B) */}
                            <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ boxShadow: open?.includes(monthData?.month) ? ' inset -1px 0px 0px #ddd' : 'inset -1px 0px 0px #ddd', textAlign: 'center', color: '#2E7D32', fontWeight: '600' }}>{isGreaterMonth(monthData?.month, index) ? '-' : getAllotedLeaveByType(monthData?.leaveTypeSetting, 'Casual Leave')}</TableCell>
                            <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ boxShadow: open?.includes(monthData?.month) ? ' inset -1px 0px 0px #ddd' : 'inset -1px 0px 0px #ddd', textAlign: 'center', color: '#2E7D32', fontWeight: '600' }}>{isGreaterMonth(monthData?.month, index) ? '-' : getAllotedLeaveByType(monthData?.leaveTypeSetting, 'Sick Leave')}</TableCell>
                            {/* Taken (C) */}
                            <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ boxShadow: open?.includes(monthData?.month) ? ' inset -1px 0px 0px #ddd' : 'inset -1px 0px 0px #ddd', textAlign: 'center', color: '#D32F2F', fontWeight: '600' }}>
                              {isGreaterMonth(monthData?.month, index) ?
                                getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave', true) ?
                                  <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                    {isGreaterMonth(monthData?.month, index) ? getTakenLeaveByType(monthData?.leaveData, 'Casual Leave') ? getTakenLeaveByType(monthData?.leaveData, 'Casual Leave') : "-" : getTakenLeaveByType(monthData?.leaveData, 'Casual Leave')}
                                    {(getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.pendingProvisionCount || getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.approvedProvisionCount) ?
                                      <Box color={"secondary.main"} width={18} height={18}>
                                        <HtmlTooltip arrow
                                          title={
                                            <React.Fragment>
                                              <Typography variant='h4' fontSize={16} fontWeight={700} color={"white"} lineHeight={"17px"} mb={1}>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave', true)} Leaves</Typography>
                                              <Typography variant='body1' fontSize={12} fontWeight={400} color={"white"} lineHeight={"18px"} mb={1.5}>Your leave has been provisionally applied due to insufficient balance and will be settled when that leave month starts.</Typography>
                                              <Box display={"flex"} gap={3} >
                                                <Box flex={1}>
                                                  <Typography variant='h5' fontSize={14} fontWeight={600} color={"white"} lineHeight={"24px"}>Approved</Typography>
                                                  <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.approvedCount}</b> Approved Leave</Typography>
                                                </Box>
                                                {(getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.pendingProvisionCount || getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.approvedProvisionCount) ?
                                                  <Box flex={1}>
                                                    <Typography variant='h5' fontSize={14} fontWeight={600} color={"white"} lineHeight={"24px"}>Provision</Typography>
                                                    <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.pendingProvisionCount}</b> Pending Leaves</Typography>
                                                    <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.approvedProvisionCount}</b> Approved Leaves</Typography>
                                                  </Box>
                                                  : null}
                                              </Box>
                                            </React.Fragment>
                                          }
                                        >
                                          <Typography color={"white"} fontSize={10} fontWeight={700} lineHeight={"10px"} letterSpacing={"0.17px"} width={17} height={17} borderRadius={"20px"} fontStyle={"italic"} bgcolor={"#B92B2B"} display={"flex"} alignItems={"center"} justifyContent={"center"} verticalAlign={"top"} margin={"1px 0px 0px 6px"}>P</Typography>
                                        </HtmlTooltip>
                                      </Box> :
                                      null}
                                  </Box> :
                                  "-" :
                                months[new Date().getMonth()] === monthData?.month && getYearObject(selectedYear)?.year === activeYear?.year ?
                                  <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                    {isGreaterMonth(monthData?.month, index) ? getTakenLeaveByType(monthData?.leaveData, 'Casual Leave') ?
                                      getTakenLeaveByType(monthData?.leaveData, 'Casual Leave') : "-" :
                                      getTakenLeaveByType(monthData?.leaveData, 'Casual Leave')}
                                    {getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave', true) ?
                                      (getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.pendingProvisionCount || getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.approvedProvisionCount) ?
                                        <Box color={"secondary.main"} width={18} height={18}>
                                          <HtmlTooltip arrow
                                            title={
                                              <React.Fragment>
                                                <Typography variant='h4' fontSize={16} fontWeight={700} color={"white"} lineHeight={"17px"} mb={1}>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave', true)} Leaves</Typography>
                                                <Typography variant='body1' fontSize={12} fontWeight={400} color={"white"} lineHeight={"18px"} mb={1.5}>Your leave has been provisionally applied due to insufficient balance and will be settled when that leave month starts.</Typography>
                                                <Box display={"flex"} gap={3} >
                                                  <Box flex={1}>
                                                    <Typography variant='h5' fontSize={14} fontWeight={600} color={"white"} lineHeight={"24px"}>Approved</Typography>
                                                    <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.approvedCount}</b> Approved Leave</Typography>
                                                  </Box>
                                                  {(getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.pendingProvisionCount || getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.approvedProvisionCount) ?
                                                    <Box flex={1}>
                                                      <Typography variant='h5' fontSize={14} fontWeight={600} color={"white"} lineHeight={"24px"}>Provision</Typography>
                                                      <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave')?.pendingProvisionCount}</b> Pending Leaves</Typography>
                                                      {/* <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{0}</b> Approved Leaves</Typography> */}
                                                    </Box>
                                                    : null}
                                                </Box>
                                              </React.Fragment>
                                            }
                                          >
                                            <Typography color={"white"} fontSize={10} fontWeight={700} lineHeight={"10px"} letterSpacing={"0.17px"} width={17} height={17} borderRadius={"20px"} fontStyle={"italic"} bgcolor={"#B92B2B"} display={"flex"} alignItems={"center"} justifyContent={"center"} verticalAlign={"top"} margin={"1px 0px 0px 6px"}>P</Typography>
                                          </HtmlTooltip>
                                        </Box> : null
                                      : null}
                                  </Box> :
                                  getTakenLeaveByType(monthData?.leaveData, 'Casual Leave')}
                            </TableCell>
                            <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ boxShadow: open?.includes(monthData?.month) ? ' inset -1px 0px 0px #ddd' : 'inset -1px 0px 0px #ddd', textAlign: 'center', color: '#D32F2F', fontWeight: '600' }}>
                              {isGreaterMonth(monthData?.month, index) ?
                                getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave', true) ?
                                  <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                    {isGreaterMonth(monthData?.month, index) ? getTakenLeaveByType(monthData?.leaveData, 'Sick Leave') ? getTakenLeaveByType(monthData?.leaveData, 'Sick Leave') : "-" : getTakenLeaveByType(monthData?.leaveData, 'Sick Leave')}
                                    {(getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.pendingProvisionCount || getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.approvedProvisionCount) ?
                                      <Box color={"secondary.main"} width={18} height={18}>
                                        <HtmlTooltip arrow
                                          title={
                                            <React.Fragment>
                                              <Typography variant='h4' fontSize={16} fontWeight={700} color={"white"} lineHeight={"17px"} mb={1}>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave', true)} Leaves</Typography>
                                              <Typography variant='body1' fontSize={12} fontWeight={400} color={"white"} lineHeight={"18px"} mb={1.5}>Your leave has been provisionally applied due to insufficient balance and will be settled when that leave month starts.</Typography>
                                              <Box display={"flex"} gap={3} >
                                                <Box flex={1}>
                                                  <Typography variant='h5' fontSize={14} fontWeight={600} color={"white"} lineHeight={"24px"}>Approved</Typography>
                                                  <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.approvedCount}</b> Approved Leave</Typography>
                                                </Box>
                                                {(getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.pendingProvisionCount || getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.approvedProvisionCount) ?
                                                  <Box flex={1}>
                                                    <Typography variant='h5' fontSize={14} fontWeight={600} color={"white"} lineHeight={"24px"}>Provision</Typography>
                                                    <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.pendingProvisionCount}</b> Pending Leaves</Typography>
                                                    <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.approvedProvisionCount}</b> Approved Leaves</Typography>
                                                  </Box>
                                                  : null}
                                              </Box>
                                            </React.Fragment>
                                          }
                                        >
                                          <Typography color={"white"} fontSize={10} fontWeight={700} lineHeight={"10px"} letterSpacing={"0.17px"} width={17} height={17} borderRadius={"20px"} fontStyle={"italic"} bgcolor={"#B92B2B"} display={"flex"} alignItems={"center"} justifyContent={"center"} verticalAlign={"top"} margin={"1px 0px 0px 6px"}>P</Typography>
                                        </HtmlTooltip>
                                      </Box> : null}
                                  </Box> :
                                  "-" :
                                months[new Date().getMonth()] === monthData?.month && getYearObject(selectedYear)?.year === activeYear?.year ?
                                  <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                    {isGreaterMonth(monthData?.month, index) ? getTakenLeaveByType(monthData?.leaveData, 'Sick Leave') ? getTakenLeaveByType(monthData?.leaveData, 'Sick Leave') : "-" : getTakenLeaveByType(monthData?.leaveData, 'Sick Leave')}
                                    {getProvisionTakenLeaveByType(monthData?.leaveData, 'Casual Leave', true) ?
                                      (getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.pendingProvisionCount || getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.approvedProvisionCount) ? <Box color={"secondary.main"} width={18} height={18}>
                                        <HtmlTooltip arrow
                                          title={
                                            <React.Fragment>
                                              <Typography variant='h4' fontSize={16} fontWeight={700} color={"white"} lineHeight={"17px"} mb={1}>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave', true)} Leaves</Typography>
                                              <Typography variant='body1' fontSize={12} fontWeight={400} color={"white"} lineHeight={"18px"} mb={1.5}>Your leave has been provisionally applied due to insufficient balance and will be settled when that leave month starts.</Typography>
                                              <Box display={"flex"} gap={3}>
                                                <Box flex={1}>
                                                  <Typography variant='h5' fontSize={14} fontWeight={600} color={"white"} lineHeight={"24px"}>Approved</Typography>
                                                  <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.approvedCount}</b> Approved Leave</Typography>
                                                </Box>
                                                {(getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.pendingProvisionCount || getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.approvedProvisionCount) ?
                                                  <Box flex={1}>
                                                    <Typography variant='h5' fontSize={14} fontWeight={600} color={"white"} lineHeight={"24px"}>Provision</Typography>
                                                    <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{getProvisionTakenLeaveByType(monthData?.leaveData, 'Sick Leave')?.pendingProvisionCount}</b> Pending Leaves</Typography>
                                                    {/* <Typography fontSize={12} fontWeight={500} color={"white"} lineHeight={"22px"}><b>{0}</b> Approved Leaves</Typography> */}
                                                  </Box>
                                                  : null}
                                              </Box>
                                            </React.Fragment>
                                          }
                                        >
                                          <Typography color={"white"} fontSize={10} fontWeight={700} lineHeight={"10px"} letterSpacing={"0.17px"} width={17} height={17} borderRadius={"20px"} fontStyle={"italic"} bgcolor={"#B92B2B"} display={"flex"} alignItems={"center"} justifyContent={"center"} verticalAlign={"top"} margin={"1px 0px 0px 6px"}>P</Typography>
                                        </HtmlTooltip>
                                      </Box> : null
                                      : null}
                                  </Box> :
                                  getTakenLeaveByType(monthData?.leaveData, 'Sick Leave')}
                            </TableCell>
                            {/* Balance A+B+C */}
                            <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ boxShadow: open?.includes(monthData?.month) ? ' inset -1px 0px 0px #ddd' : 'inset -1px 0px 0px #ddd' }}>
                              <Box display={'flex'} justifyContent={'center'} flexDirection={"column"} gap={0.5} alignItems={'center'}>
                                {isGreaterMonth(monthData?.month, index) ? "-" :
                                  getTotalBalance(
                                    index === leaveReportData?.findLastIndex(reportDetails => !!reportDetails?.leaveTypeSetting) ? getOpeningLeaveBalanceByType(monthData?.leaveTypeSetting, 'Casual Leave') :
                                      leaveReportData[index + 1]?.totalCasualBalance < 0 ? 0 : leaveReportData[index + 1]?.totalCasualBalance ?? 0,
                                    getAllotedLeaveByType(monthData?.leaveTypeSetting, 'Casual Leave'),
                                    getTakenLeaveByType(monthData?.leaveData, 'Casual Leave')
                                  )
                                }
                              </Box>
                            </TableCell>
                            <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ textAlign: 'center', fontWeight: '600' }}>
                              <Box display={'flex'} justifyContent={'center'} flexDirection={"column"} gap={0.5} alignItems={'center'}>
                                {isGreaterMonth(monthData?.month, index) ? "-" :
                                  getTotalBalance(
                                    index === leaveReportData?.findLastIndex(reportDetails => !!reportDetails?.leaveTypeSetting) ? getOpeningLeaveBalanceByType(monthData?.leaveTypeSetting, 'Sick Leave') :
                                      leaveReportData[index + 1]?.totalSickBalance < 0 ? 0 : leaveReportData[index + 1]?.totalSickBalance ?? 0,
                                    getAllotedLeaveByType(monthData?.leaveTypeSetting, 'Sick Leave'),
                                    getTakenLeaveByType(monthData?.leaveData, 'Sick Leave')
                                  )}
                              </Box>
                            </TableCell>
                          </TableRow>

                          <TableRow sx={{}}>
                            <TableCell style={{ padding: '0px' }} sx={{}} colSpan={9}>
                              <Collapse in={open?.includes(monthData?.month)} timeout="auto" unmountOnExit sx={{ borderLeft: "4px solid #3F5C76" }}>
                                <Box margin={1} padding={'16px'}>
                                  {!getTotalLeavesCount(monthData?.leaveData) ?
                                    <Typography fontSize={14} fontWeight={400} lineHeight={"14px"} letterSpacing={"0.24px"} sx={{ textAlign: 'center' }}>
                                      No Leaves
                                    </Typography>
                                    :
                                    <Box sx={{ border: '1px solid #ddd', borderRadius: '3px' }}>
                                      <Table size="small" aria-label="leave-details">
                                        <TableHead>
                                          <TableRow sx={{
                                            'th': {
                                              backgroundColor: '#F5F8FA'
                                            },
                                          }}>
                                            <TableCell sx={{ width: '140px', minWidth: '140px' }}>Date</TableCell>
                                            <TableCell sx={{ width: '120px', minWidth: '120px' }}>Leave</TableCell>
                                            <TableCell sx={{ width: '120px', minWidth: '120px' }}>Type</TableCell>
                                            <TableCell sx={{ width: '140px', minWidth: '140px' }}>Full / Half</TableCell>
                                            <TableCell sx={{ width: '184px', minWidth: '184px' }}>Set Off</TableCell>
                                            <TableCell sx={{ width: '120px', minWidth: '120px' }}>Status</TableCell>
                                            <TableCell sx={{ minWidth: '250px' }}>By</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {!getTotalLeavesCount(monthData?.leaveData) ? null : sortRequestArrayByDate(monthData?.leaveData, "leave_days", "leave_date")?.map((leaveDetails) => (
                                            leaveDetails?.leave_days?.map((leaveDays, index) => (
                                              <TableRow
                                                key={index}
                                                sx={{
                                                  '&:hover': { background: '#F7F7F7' },
                                                  '&:hover .launchIcon': {
                                                    display: 'inline-flex',
                                                  },
                                                  '&:hover .leaveId': {
                                                    color: '#047FE0',
                                                    textDecoration: 'underline',
                                                  },
                                                  '&:hover .leave-details': {
                                                    opacity: 1,
                                                    display: 'flex',
                                                  },
                                                  '&:hover .user-info': {
                                                    opacity: 0,
                                                  },
                                                  '&:hover .avatar': {
                                                    opacity: 0,
                                                  },
                                                  '&:last-child td': {
                                                    border: 'none',
                                                  }
                                                }}>
                                                <TableCell>{getFormattedDate(leaveDays?.leave_date, "DD/MM/YYYY")}</TableCell>
                                                <TableCell>
                                                  <Box
                                                    sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      color: 'black',
                                                      cursor: 'pointer',
                                                      '&:hover .launchIcon': {
                                                        display: 'inline-flex',
                                                      },
                                                      '&:hover .leaveId': {
                                                        color: '#047FE0',
                                                        textDecoration: 'underline',
                                                      },
                                                    }}
                                                    onClick={() => handleLeaveClick(leaveDetails)}
                                                  >
                                                    <Typography
                                                      fontSize={14}
                                                      fontWeight={500}
                                                      lineHeight={"14px"}
                                                      letterSpacing={"0.17px"}
                                                      className="leaveId"
                                                      sx={{
                                                        textDecoration: 'none',
                                                      }}
                                                    >
                                                      {getApplicationNumber(leaveDetails?.application_num)}
                                                    </Typography>
                                                    <LaunchIcon
                                                      className="launchIcon"
                                                      sx={{
                                                        fontSize: 16,
                                                        marginLeft: '4px',
                                                        display: 'none',
                                                        color: '#047FE0',
                                                      }}
                                                    />
                                                  </Box>
                                                </TableCell>
                                                <TableCell>{titleCase(leaveDetails?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name?.split(" ")?.[0])}</TableCell>
                                                <TableCell>{getLeaveCount(leaveDays)}</TableCell>
                                                <TableCell>{getLeaveSetOff(leaveDays)}</TableCell>
                                                <TableCell>
                                                  <Box
                                                    display={'flex'}
                                                    alignItems={'center'}>
                                                    {getLeaveStatus(leaveDetails?.status, "chip")}
                                                    {isProvisionLeave(leaveDetails, leaveDays) ?
                                                      // <HelpOutlineRoundedIcon sx={{ fontSize: '18px', marginLeft: '6px' }} />
                                                      <Typography color={"white"} fontSize={10} fontWeight={700} lineHeight={"10px"} letterSpacing={"0.17px"} width={17} height={17} borderRadius={"20px"} fontStyle={"italic"} bgcolor={"#B92B2B"} display={"flex"} alignItems={"center"} justifyContent={"center"} verticalAlign={"top"} margin={"1px 0px 0px 0px"}>P</Typography>
                                                      : null}
                                                  </Box>
                                                </TableCell>
                                                <TableCell>
                                                  <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    sx={{
                                                      position: 'relative',
                                                      '& .leave-details': {
                                                        opacity: 0,
                                                        display: 'none',
                                                        position: 'absolute',
                                                        left: 0,
                                                        top: 0,
                                                        bottom: 0,
                                                        color: '#047FE0',
                                                        transition: 'opacity 0.9s ease',
                                                        cursor: 'pointer',
                                                      },
                                                    }}
                                                  >
                                                    {leaveDetails?.processedBy ? <>
                                                      <BackgroundLetterAvatars
                                                        user={leaveDetails?.processedBy?.[0]}
                                                        sx={{ width: 30, height: 30, mr: 1, fontSize: "13px", fontWeight: '500' }}
                                                        className="avatar"
                                                      />
                                                      <Typography
                                                        fontSize={14}
                                                        fontWeight={400}
                                                        lineHeight={'20px'}
                                                        letterSpacing={'0.17px'}
                                                        className="user-info"
                                                      >
                                                        {titleCase(leaveDetails?.processedBy?.[0]?.name)}{" at "}
                                                        {getFormattedDate(leaveDetails?.process_date, "DD/MM/YYYY")}
                                                      </Typography>
                                                    </> : <Box className="avatar user-info">-</Box>}
                                                    <Box
                                                      sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        color: 'black',
                                                        cursor: 'pointer',
                                                      }}
                                                      className="leave-details"
                                                      onClick={() => handleLeaveClick(leaveDetails)}
                                                    >
                                                      <Typography
                                                        fontSize={14}
                                                        fontWeight={500}
                                                        lineHeight={'20px'}
                                                        letterSpacing={'0.17px'}
                                                        sx={{
                                                          textDecoration: 'underline',
                                                          color: '#047FE0'
                                                        }}
                                                      >
                                                        Leave Details
                                                      </Typography>
                                                      <LaunchIcon
                                                        className="launchIcon"
                                                        sx={{
                                                          fontSize: 16,
                                                          marginLeft: '4px',
                                                          display: 'none',
                                                          color: '#047FE0',
                                                        }}
                                                      />
                                                    </Box>
                                                  </Box>
                                                </TableCell>
                                              </TableRow>
                                            ))
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  }
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
          }
        </Box>
      </Box>
    </Box>
  )
}

export default UserLeaveReport;