import { Badge, Box, Button, Chip, FormControl, Grid, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, tooltipClasses, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BackgroundLetterAvatars from '../../../components/BackgroundLetterAvatars'
import { calculateLeaveCount, getApplicationNumber, getApplyDateTime, getDayName, getFormattedDate, getFullName, getInputId, getLeaveDateArray, getLeaveDates, getLeaveStatus, isProvisionLeave, showInformationAuthority, sortArrayByKey, sortRequestArrayByDate, titleCase, updateSystemGeneratedLeaveStatus } from '../../../utils'
import ICONS from '../../../constants/icons'
import DesktopMacRoundedIcon from '@mui/icons-material/DesktopMacRounded';
import { styled } from '@mui/styles'
import { DASHBOARD_LEAVE_FILTER, LEAVE_STATUS } from '../../../constants/default-values'
import useAuthentication from '../../../hook/useAuthentication'
import axiosInstance from '../../../axios'
import API from '../../../axios/api'
import { useNavigate } from 'react-router-dom'
import URLS from '../../../routes/urls'
import TablePagination from '../../../components/TablePagination'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector } from 'react-redux'
import CircularLoader from '../../../components/CircularLoader'

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: "16px",
        borderRadius: "5px",
        background: "#3F5C76",
        maxWidth: "500px"
    },
}));

const LeaveList = () => {
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState({
        leaveListLoading: false
    });
    const { users, isApiFalied } = useSelector((state) => state.repoteeData);
    const isReportee = currentUser?.is_reportee;
    const [leaveList, setLeaveList] = useState([]);
    const [leaveCount, setLeaveCount] = useState(0);

    const [filter, setFilter] = useState((!!isReportee && !isReportee && !["hr", "administrator"]?.includes(currentUser?.user_role?.toLowerCase())) ?
        Object.keys(DASHBOARD_LEAVE_FILTER)[1] : Object.keys(DASHBOARD_LEAVE_FILTER)[3]);
    const [isPageRendered, setIsPageRendered] = useState(false);
    const [isApiCalled, setIsApiCalled] = useState(false);

    const [queryParams, setQueryParams] = useState({
        userId: currentUser?.id,
        orgId: currentUser?.organization_id,
        filter: filter,
        limit: 5,
        page: 0,
        reportiesUsersList: []
    })

    useEffect(() => {
        if (currentUser?.id) {
            // If repotee person is exist and user length is 0 then do not call API for others users leave list else call API
            if (isReportee && users?.length) {
                if (!isPageRendered) setIsPageRendered(true)
                getAllLeaveData();
            } else if (!isReportee) {
                if (!isPageRendered) setIsPageRendered(true)
                getAllLeaveData();
            } else {
                setIsLoading(prev => ({ ...prev, leaveListLoading: false }))
            }
            if (isApiFalied) {
                setIsApiCalled(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])

    useEffect(() => {
        if (users?.length) {
            setIsLoading(prev => ({ ...prev, leaveListLoading: true }))
            setQueryParams(prev => ({ ...prev, page: 0, limit: 5, 'reportiesUsersList': users?.map(data => data?.id) }))
        }
    }, [users])

    const isAccessToFilter = () => {
        let isAdmin = ["hr", "administrator"]?.includes(currentUser?.user_role?.toLowerCase())
        return isReportee || isAdmin
    };

    const getAllLeaveData = async () => {
        try {
            setIsApiCalled(true);
            setIsLoading(prev => ({ ...prev, leaveListLoading: true }))
            let response = isAccessToFilter() ? await axiosInstance.post(API.getOthersUsersLeaveList, {
                ...queryParams,
                userId: currentUser?.id,
                orgId: currentUser?.organization_id,
            }) : await axiosInstance.get(API.getUsersLeaveList)
            if (response?.status === 200) {
                if (response?.data?.data?.count) {
                    let sortedArray = sortRequestArrayByDate(updateSystemGeneratedLeaveStatus(response?.data?.data?.rows), "leave_days", "leave_date");
                    setLeaveList(sortedArray);
                    setLeaveCount(response?.data?.data?.count);
                } else {
                    if (!isPageRendered && isAccessToFilter()) {
                        setQueryParams(prev => ({ ...prev, page: 0, limit: 5, 'filter': Object.keys(DASHBOARD_LEAVE_FILTER)[1] }))
                        setFilter(Object.keys(DASHBOARD_LEAVE_FILTER)[1]);
                        return true;
                    } else {
                        setLeaveCount(0);
                        setLeaveList([]);
                    }
                }
            } else {
                setLeaveCount(0);
                setLeaveList([]);
            }
            setIsLoading(prev => ({ ...prev, leaveListLoading: false }))
        } catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, leaveListLoading: false }))
        }
    }

    const hasAttachmentInList = () => {
        if (leaveList?.length) {
            return leaveList?.some(leave => leave?.attachment_references?.length);
        }
    }

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        setQueryParams(prev => ({ ...prev, page: 0, limit: 5, 'filter': event.target.value }))
    };

    const handleChangePage = (event, newPage) => {
        setQueryParams((prev) => ({
            ...prev,
            page: newPage,
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setQueryParams((prev) => ({
            ...prev,
            limit: event.target.value,
            page: 0,
        }));
    };

    const calculateCreditCount = (leaveDays) => {
        return leaveDays?.reduce((previousValue, currentValue) => {
            let creditUsed = currentValue?.credit_used || 0;
            previousValue.creditCount = previousValue.creditCount + creditUsed;
            return previousValue;
        }, { creditCount: 0, })?.creditCount;
    }

    const handleClickSeeAll = () => {
        navigate(`${URLS.Leaves}?view=list&tabValue=${isAccessToFilter() ? 0 : 1}&groupBy=Status&status=active`)
    }

    const handleLeaveClick = (leave) => {
        let { User, id } = leave;
        navigate(`${currentUser?.id === User?.id ? URLS.ViewMyLeaveApplication : URLS.OtherLeaveDetails}/${User?.id}/${id}?previousPage=dashboard`);
    }

    return (
        <Box bgcolor={"white"} p={3} borderRadius={"4px"} sx={{ boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)' }}>
            <Grid container justifyContent="space-between" alignItems="flex-end">
                <Grid item display="flex" alignItems={"center"}>
                    <Typography color="secondary.main" fontSize={18} fontWeight={600} lineHeight={"27px"} letterSpacing={"0.15px"} component={"div"}>
                        Leaves
                    </Typography>
                    {leaveCount ? <Badge variant="standard" badgeContent={leaveCount} color="info" sx={{ marginLeft: '4px', 'span': { position: 'relative', transform: 'none' } }} /> : null}
                </Grid>
                <Grid item display="flex" justifyContent={"flex-end"}>
                    {isAccessToFilter() ? <FormControl
                        variant="standard"
                        sx={{ mr: { xs: 2 }, minWidth: { xs: 210 }, maxWidth: { xs: 210 } }}>
                        <Select
                            id="group-by"
                            inputProps={{ id: getInputId("group-by", 0) }}
                            value={filter || ""}
                            onChange={(e) => handleFilterChange(e)}
                            label="Group By"
                            disabled={isLoading?.leaveListLoading}
                        >
                            {Object.entries(DASHBOARD_LEAVE_FILTER)?.map(
                                (name, index) => (
                                    <MenuItem key={index} value={name[0]}>
                                        {name[1]}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl> : null}
                    {leaveCount > 0 ?
                        <Stack spacing={2} direction="row">
                            <Button variant='text' color='primary'
                                size="medium"
                                sx={{ fontSize: '14px', fontWeight: '500', textTransform: 'none' }}
                                onClick={() => handleClickSeeAll()}
                            >See All</Button>
                        </Stack>
                        : null}
                </Grid>
            </Grid>
            <Paper
                sx={{
                    borderRadius: "3px",
                    boxShadow: 'none',
                }}>
                <TableContainer>
                    <Table
                        aria-label="simple table"
                        sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0px 8px",
                        }}
                    >
                        {
                            (isLoading?.leaveListLoading) ?
                                <CircularLoader height={"100px"} /> :
                                <TableBody>
                                    {(!!leaveList?.length && leaveList?.length) ?
                                        (<>
                                            {leaveList?.map((leaveData, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{
                                                        td: {
                                                            border: 0,
                                                            padding: { xs: "8px 8px", md: "8px 16px" },
                                                        },
                                                        'td:first-of-type': {
                                                            borderRadius: "4px 0px 0px 4px",
                                                        },
                                                        'td:last-child': {
                                                            borderRadius: "0px 4px 4px 0px",
                                                        },
                                                        cursor: 'pointer',
                                                        background: "#EBEFF3",
                                                    }}
                                                    onClick={() => handleLeaveClick(leaveData)}
                                                >
                                                    {isAccessToFilter() ? <TableCell
                                                        sx={{
                                                            width: "250px",
                                                            minWidth: "250px",
                                                            maxWidth: "250px",
                                                        }}
                                                    >
                                                        <Grid
                                                            item
                                                            display="flex"
                                                            alignItems="center"
                                                        >
                                                            <BackgroundLetterAvatars
                                                                user={leaveData?.User}
                                                                src={leaveData?.User?.profile_img}
                                                                sx={{ width: 32, height: 32 }}
                                                            />
                                                            <Box
                                                                ml={1}
                                                                display="flex"
                                                                flexWrap="wrap"
                                                                flexDirection={"column"}
                                                                overflow={"hidden"}
                                                            >
                                                                <Box display={"flex"} gap={"4px"} width={"100%"}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        color="dark.800"
                                                                        fontSize={14}
                                                                        fontWeight={500}
                                                                        sx={{
                                                                            lineHeight: "22px",
                                                                            letterSpacing: "0.17px",
                                                                            display: "block",
                                                                            width: "100%",
                                                                            overflow: "hidden",
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: "nowrap"
                                                                        }}
                                                                    >
                                                                        {getFullName(leaveData?.User)}
                                                                    </Typography>
                                                                    <Typography color={"#7E7E7E"} fontSize={13} fontWeight={600} fontStyle={"italic"} lineHeight={"22px"} whiteSpace={"nowrap"}>{currentUser?.id === leaveData?.User?.id ? '- yours' : ''}</Typography>
                                                                </Box>
                                                                {leaveData?.User
                                                                    ?.is_dedicated_developer ? (
                                                                    <Typography
                                                                        color="#9747FF"
                                                                        fontSize={12}
                                                                        fontWeight={700}
                                                                        sx={{
                                                                            display: "inline-block",
                                                                            mr: 0.5,
                                                                        }}
                                                                    >
                                                                        Dedicated
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography
                                                                        color="primary"
                                                                        fontSize={12}
                                                                        fontWeight={700}
                                                                        sx={{
                                                                            display: "inline-block",
                                                                            mr: 0.5,
                                                                        }}
                                                                    >
                                                                        {leaveData?.User?.OrganizationWorkTypeSetting?.name}
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                    </TableCell> : null}
                                                    <TableCell
                                                        sx={{
                                                            minWidth: "230px",
                                                        }}
                                                    >
                                                        <Box display={"flex"} gap={0.5} alignItems={"center"}>
                                                            {leaveData?.is_auto_generated ?
                                                                <HtmlTooltip
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Typography color="white" fontSize={14} fontWeight={500} lineHeight={"21px"}>System Generated Leave</Typography>
                                                                        </React.Fragment>
                                                                    }
                                                                >
                                                                    <i style={{ height: 20, width: 20, minWidth: 20, flex: '0px 0px 20px', borderRadius: '20px', background: '#12B495', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <DesktopMacRoundedIcon sx={{ fontSize: '12px', color: '#ffffff' }} />
                                                                    </i>
                                                                </HtmlTooltip>
                                                                : null}
                                                            <Typography
                                                                variant="body2"
                                                                color="dark.800"
                                                                lineHeight="14px"
                                                                letterSpacing="0.17px"
                                                                fontWeight={500}
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                component={"span"}
                                                            >
                                                                {leaveData?.is_auto_generated ? 'System Leave' : titleCase(leaveData?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name)}
                                                            </Typography>
                                                            <Chip
                                                                label={getApplicationNumber(leaveData?.application_num)}
                                                                color="secondary"
                                                                size="small"
                                                                variant="filled"
                                                                sx={{ marginLeft: '4px', marginRight: '4px' }}
                                                            />
                                                        </Box>
                                                        <Typography
                                                            color="dark.500"
                                                            fontSize={10}
                                                            lineHeight="15px"
                                                            letterSpacing="0.17px"
                                                            fontWeight={500}
                                                        >
                                                            {calculateCreditCount(
                                                                leaveData?.leave_days
                                                            ) > 0 ? `${calculateCreditCount(
                                                                leaveData?.leave_days
                                                            )} ${calculateCreditCount(
                                                                leaveData?.leave_days
                                                            ) > 1 ? 'Credits' : 'Credit'} Used` : 'No Credit Used'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            minWidth: "240px",
                                                            maxWidth: '0px'
                                                        }}
                                                    >
                                                        <Typography
                                                            color="dark.500"
                                                            fontSize={10}
                                                            lineHeight="15px"
                                                            letterSpacing="0.17px"
                                                            fontWeight={500}
                                                            mb="6px"
                                                        >
                                                            Leave dates ({calculateLeaveCount(
                                                                leaveData?.leave_days
                                                            )}{" "}
                                                            {leaveData?.leave_days?.length > 1 ? 'days' : 'day'})
                                                        </Typography>
                                                        <Grid
                                                            item
                                                            display="flex"
                                                            alignItems="center"
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                color="dark.800"
                                                                lineHeight="14px"
                                                                letterSpacing="0.17px"
                                                                fontWeight={500}
                                                                mr="6px"
                                                                whiteSpace="nowrap"
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"
                                                            >
                                                                {getLeaveDates(getLeaveDateArray(leaveData?.leave_days, 'leave_date'))}
                                                            </Typography>
                                                            <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography color={"white"} fontSize={16} lineHeight={"17px"} fontWeight={500} mb={1.5}>{calculateLeaveCount(leaveData?.leave_days)} Leaves on</Typography>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow
                                                                                    sx={{
                                                                                        'th': {
                                                                                            padding: '10px 4px',
                                                                                            color: 'white',
                                                                                            fontSize: 12,
                                                                                            lineHeight: '19px',
                                                                                            fontWeight: 700,
                                                                                            background: 'rgba(255,255,255,0.1)',
                                                                                            border: 'none',
                                                                                            '&:first-of-type': {
                                                                                                padding: '10px',
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <TableCell align="left" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Date</TableCell>
                                                                                    <TableCell align="left" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Days</TableCell>
                                                                                    <TableCell align="left" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Full / Half</TableCell>
                                                                                    <TableCell align="left" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Provision</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody
                                                                                sx={{
                                                                                    'td': {
                                                                                        padding: '4px 10px',
                                                                                        color: 'white',
                                                                                        fontSize: 14,
                                                                                        lineHeight: '19px',
                                                                                        fontWeight: 500,
                                                                                        border: 'none',
                                                                                    },
                                                                                    'tr:first-of-type td': {
                                                                                        paddingTop: '10px',
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {sortArrayByKey(leaveData?.leave_days, 'asc', 'leave_date')?.map((leaveDate, index) => (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell sx={{ padding: '10px 4px !important' }}>{getFormattedDate(leaveDate?.leave_date)}</TableCell>
                                                                                        <TableCell sx={{ padding: '10px 4px !important' }}>({getDayName(getFormattedDate(leaveDate?.leave_date))})</TableCell>
                                                                                        <TableCell sx={{ padding: '10px 4px !important' }}>{`${leaveDate?.leave_duration ===
                                                                                            "first_half"
                                                                                            ? "1st Half"
                                                                                            : leaveDate?.leave_duration ===
                                                                                                "second_half"
                                                                                                ? "2nd Half"
                                                                                                : "Full-day"
                                                                                            }`}</TableCell>
                                                                                        <TableCell sx={{ padding: '10px 4px !important' }}>{isProvisionLeave(leaveData, leaveDate) ? 'Yes' : '-'}</TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <i
                                                                    style={{
                                                                        height: 18,
                                                                        maxWidth: 18,
                                                                        flex: "0 0 18px",
                                                                    }}
                                                                >
                                                                    <VisibilityIcon
                                                                        color="secondary"
                                                                        sx={{ fontSize: 18 }}
                                                                    />
                                                                </i>
                                                            </HtmlTooltip>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            width: "200px",
                                                            minWidth: "200px",
                                                            maxWidth: "200px",
                                                        }}
                                                    >
                                                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                            <Box>
                                                                <Typography
                                                                    color="dark.500"
                                                                    fontSize={10}
                                                                    lineHeight="15px"
                                                                    letterSpacing="0.17px"
                                                                    fontWeight={500}
                                                                    mb="6px"
                                                                >
                                                                    Applied on{" "}
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="dark.800"
                                                                    lineHeight="14px"
                                                                    letterSpacing="0.17px"
                                                                    fontWeight={500}
                                                                >
                                                                    {getApplyDateTime(leaveData?.createdAt || leaveData?.apply_date, "DD/MM/YYYY")}
                                                                </Typography>
                                                            </Box>
                                                            {hasAttachmentInList() ?
                                                                <>
                                                                    {leaveData?.attachment_references?.length ? <Box
                                                                        sx={{
                                                                            alignItems: 'center',
                                                                            color: "secondary",
                                                                            display: "flex",
                                                                        }}
                                                                    >

                                                                        <i style={{
                                                                            height: "18px",
                                                                            minWidth: "18px",
                                                                            width: "18px",
                                                                            flex: "0 0 18px",
                                                                            display: "flex",
                                                                        }}
                                                                        >
                                                                            {ICONS.Attachment}
                                                                        </i>
                                                                        <Box
                                                                            sx={{ alignItems: 'center' }}
                                                                        >
                                                                            <Typography
                                                                                variant="body1"
                                                                                color="secondary"
                                                                                fontSize={14}
                                                                                fontWeight={500}
                                                                                sx={{
                                                                                    lineHeight: "24px",
                                                                                    letterSpacing: "0.17px",
                                                                                    marginBottom: "2px",
                                                                                    display: "block",
                                                                                    width: "100%",
                                                                                }}
                                                                            >
                                                                                {leaveData?.attachment_references?.length}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box> : null}
                                                                </>
                                                                : null}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            width: "140px",
                                                            minWidth: "140px",
                                                            maxWidth: "140px",
                                                        }}
                                                    >
                                                        {leaveData?.status !== LEAVE_STATUS.Pending ?
                                                            <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography fontSize={14} fontWeight={600} lineHeight={"24px"} letterSpacing={"0.15px"} display={"inline-block"} mr={0.5} width={"100%"}>
                                                                            {showInformationAuthority(leaveData?.processed_by) || "-"}
                                                                        </Typography>
                                                                        <Typography
                                                                            color="text.white" fontSize={12} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.15px"} width={"100%"}>
                                                                            at {leaveData?.process_date ? `${getApplyDateTime(leaveData?.process_date, "hh:mm A")}, on ${getApplyDateTime(leaveData?.process_date, "DD/MM/YYYY (dddd)")}` : "-"}
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <Box
                                                                    display={'inline-flex'}
                                                                    alignItems={'center'} sx={{ '> div': { marginRight: '0px' } }}>
                                                                    {getLeaveStatus(leaveData?.status, "chip")}
                                                                    {leaveData?.leave_days?.some(leaveDayData => isProvisionLeave(leaveData, leaveDayData)) ? <Typography color={"white"} fontSize={10} fontWeight={700} lineHeight={"10px"} letterSpacing={"0.17px"} width={17} height={17} borderRadius={"20px"} fontStyle={"italic"} bgcolor={"#B92B2B"} display={"flex"} alignItems={"center"} justifyContent={"center"} verticalAlign={"top"} margin={"1px 0px 0px 8px"} padding={"0px 0px 1px 0px"}>P</Typography> : null}
                                                                </Box>
                                                            </HtmlTooltip>
                                                            : <Box
                                                                display={'inline-flex'}
                                                                alignItems={'center'} sx={{ '> div': { marginRight: '0px' } }}>
                                                                {getLeaveStatus(leaveData?.status, "chip")}
                                                                {leaveData?.leave_days?.some(leaveDayData => isProvisionLeave(leaveData, leaveDayData)) ? <Typography color={"white"} fontSize={10} fontWeight={700} lineHeight={"10px"} letterSpacing={"0.17px"} width={17} height={17} borderRadius={"20px"} fontStyle={"italic"} bgcolor={"#B92B2B"} display={"flex"} alignItems={"center"} justifyContent={"center"} verticalAlign={"top"} margin={"1px 0px 0px 8px"} padding={"0px 0px 1px 0px"}>P</Typography> : null}
                                                            </Box>}
                                                        {/* <Box
                                                            display={'flex'}
                                                            alignItems={'center'}>
                                                            {getLeaveStatus(leaveData?.status, "chip")}
                                                            {leaveData?.leave_days?.some(leaveDayData => isProvisionLeave(leaveData, leaveDayData)) ? <Typography color={"white"} fontSize={10} fontWeight={700} lineHeight={"10px"} letterSpacing={"0.17px"} width={17} height={17} borderRadius={"20px"} fontStyle={"italic"} bgcolor={"#B92B2B"} display={"flex"} alignItems={"center"} justifyContent={"center"} verticalAlign={"top"} margin={"1px 0px 0px 0px"} padding={"0px 0px 1px 0px"}>P</Typography> : null}
                                                        </Box> */}
                                                    </TableCell>
                                                </TableRow>))}
                                        </>
                                        ) :
                                        <TableRow
                                            key={0}
                                            sx={{
                                                td: {
                                                    border: 0,
                                                    bgcolor: "white",
                                                    textAlign: 'center',
                                                    padding: { xs: "8px 8px", md: "8px 16px" },
                                                },
                                                'td:first-of-type': {
                                                    borderRadius: "4px 4px 4px 4px",
                                                },
                                                cursor: 'default'
                                            }}
                                        >
                                            <TableCell sx={{ padding: `${isApiCalled ? '18px' : '50px'} !important`, background: '#FAFBFF !important' }}>
                                                <Typography
                                                    color="dark.800"
                                                    fontSize={16}
                                                    lineHeight="24px"
                                                    fontWeight={400}
                                                    letterSpacing={"0.15px"}
                                                >
                                                    {isApiCalled ? "No Leaves" : ""}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                        }
                    </Table>
                </TableContainer>
                {(isAccessToFilter() && !isLoading?.leaveListLoading && leaveList?.length) ? <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={leaveCount}
                    rowsPerPage={queryParams.limit}
                    page={queryParams?.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> : null}
            </Paper>
        </Box>
    )
}

export default LeaveList