/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import BasicSettings from './BasicSettings';
import WorkTypeSettings from './WorkTypeSettings';
import useQuery from '../../../../hook/useQuery';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const AdministratorLeaveSettings = () => {
  let { getQueryParams, setQueryParams } = useQuery();
  let tabValue = parseInt(getQueryParams("tabValue")) || 0;

  const handleTabChange = (event, newValue) => {
    setQueryParams("tabValue", newValue);
  };

  return (
    <Box width="100%" bgcolor="white" display="flex" flexDirection="column" >
      <Box sx={{ backgroundColor: 'white', position: 'sticky', top: 0, zIndex: 5, paddingBottom: '0px !important' }} p={{ xs: 2, lg: 3, xl: 4 }} pt={{ xs: 3, lg: 3, xl: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Basic" {...a11yProps(0)} />
            <Tab label="Work Type" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>
      <Box bgcolor="secondary"
        flex={1}
        overflow="hidden"
        sx={{
          "> div": {
            height: '100%',
          },

          ".tabspanel > .MuiBox-root": {
            padding: "0px",
            height: '100%',
            "> p": {
              height: '100%',
            }
          },
          overflowY: 'auto'
        }}
      >
        <CustomTabPanel value={tabValue} index={0} className="tabspanel">
          <BasicSettings />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1} className="tabspanel">
          <WorkTypeSettings />
        </CustomTabPanel>
      </Box>
    </Box>
  )
}

export default AdministratorLeaveSettings;