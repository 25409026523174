/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import React from 'react';
import { Button, Chip, Dialog, DialogContent, FormControl, Grid, IconButton, InputLabel, List, ListItem, Menu, MenuItem, Modal, Select, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material';
// import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ICONS from '../../../constants/icons';
import { styled } from '@mui/styles';
import useAuthentication from '../../../hook/useAuthentication';
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import CircularLoader from '../../../components/CircularLoader';
import { getFullName, getInputId, titleCase } from '../../../utils';
import SearchBoxInput from '../../../components/SearchBoxInput';
import useDebounce from '../../../hook/useDebounce';
import URLS from '../../../routes/urls';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../hook/useQuery';
import { usePageTitle } from '../../../hook/useTitle';
import BackgroundLetterAvatars from '../../../components/BackgroundLetterAvatars';
import UserRoleChip from '../../../components/UserRoleChip';
import LeaveReportModal from './UserTabPages/leaveReportModal';
import TablePagination from '../../../components/TablePagination';
import { useAlert } from '../../../hook/useAlert';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#3F5C76',
        padding: '16px'
    },
}));

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        overflowX: 'hidden',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
    },
}));

const Users = () => {
    const navigate = useNavigate();
    const showAlert = useAlert();
    const { getCurrentUser } = useAuthentication();
    const { setPageTitle } = usePageTitle();
    const { getQueryParams, setQueryParams } = useQuery();
    const currentUser = getCurrentUser();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('emp_id');
    const [expandedRow, setExpandedRow] = useState([])
    const [userRolesData, setUserRolesData] = useState([]);
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: true,
        userRoleLoading: true,
        userTypeLoading: true,
        years: true
    });
    const Group_Filter = {
        'none': 'None',
        'status': 'Status',
        'role': 'Role',
        'workType': 'Work Type'
    }
    let groupBy = getQueryParams("groupBy") && Object.keys(Group_Filter)?.includes(getQueryParams("groupBy")) ? getQueryParams("groupBy") : Object.keys(Group_Filter)[0];
    let role = getQueryParams("role") || "All";
    const [userData, setUserData] = useState({});
    const [userDataCount, setUserDataCount] = useState(0);
    const [searchString, setSearchString] = useState("");
    const debouncedSearchTerm = useDebounce(searchString);
    const [pageBeenRendered, setPageBeenRendered] = useState(false);
    const [openElem, setOpenElem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [isMakeAnAdminOpen, setIsMakeAnAdminOpen] = useState(false);
    const [editableUserModalData, setEditableUserModalData] = useState({});
    const [userTypes, setUserTypes] = useState([]);
    const [isReporModalOpen, setIsReporModalOpen] = useState(false);
    const [activeYear, setActiveYear] = useState({});
    const [leaveReportyears, setLeaveReportYears] = useState([]);
    const [leaveData, setLeaveData] = useState({});


    setPageTitle("Users");

    const [params, setParams] = useState({
        page: 0,
        limit: 25,
        sortOrder: "asc",
        sortField: "id",
        group: groupBy,
        filter: role === "All" ? "" : role,
        search: null
    });

    useEffect(() => {
        const getUserRolesData = async () => {
            try {
                setLoading((prev) => ({ ...prev, userRoleLoading: true }));
                const response = await axiosInstance.get(
                    API.organisationUserRoles(currentUser?.organization_id)
                );
                if (response.status === 200) {
                    setUserRolesData([{ id: (response?.data?.data?.length + 1), name: 'All', canDelete: true }, ...response?.data?.data]);
                }
                setLoading((prev) => ({ ...prev, userRoleLoading: false }));
            } catch (error) {
                console.error(error);
            }
        };

        const getUserTypeData = async () => {
            try {
                setLoading((prev) => ({ ...prev, userTypeLoading: true }));
                const response = await axiosInstance.get(API.getUserTypes);
                if (response.status === 200) {
                    setUserTypes(response.data.data);
                }
                setLoading((prev) => ({ ...prev, userTypeLoading: false }));
            } catch (error) {
                console.error(error);
            }
        }
        if (currentUser?.organization_id)
            getUserRolesData();
        getUserTypeData();
        if (currentUser?.organization_id) {
            getUserRolesData();
            getAllHolidayYears();
        }
    }, []);

    useEffect(() => {
        setUserDataCount(0);
        getAllUserListByOrgId();
    }, [params]);

    useEffect(() => {
        if (pageBeenRendered) {
            setParams((prev) => ({
                ...prev,
                search: debouncedSearchTerm,
            }));
        }
        setPageBeenRendered(true)
    }, [debouncedSearchTerm]);

    const handleClick = (elem) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };

    const getAllHolidayYears = async () => {
        try {
            setLoading((prev) => ({ ...prev, years: true }));
            const response = await axiosInstance.get(
                API.getAllHolidaysYear(currentUser.organization_id)
            );
            if (response.status === 200) {
                let sortedYear = response?.data?.result?.sort((a, b) =>
                    a?.year?.toString()?.localeCompare(b?.year?.toString())
                );

                setLeaveReportYears(sortedYear);
                let activeYear = sortedYear?.find(yearData => yearData?.active);
                setActiveYear(activeYear)
            }
            setLoading((prev) => ({ ...prev, years: false }));
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading((prev) => ({ ...prev, years: false }));
        }
    };

    const getAllUserListByOrgId = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            let queryParamsPageIncrease = { ...params, page: params?.page + 1 };
            if (groupBy === "none") {
                queryParamsPageIncrease.group = ""
            }
            const response = await axiosInstance.post(API.getAllUsersDataByOrgId(currentUser?.organization_id), queryParamsPageIncrease);
            if (response?.status === 200) {
                if (response?.data?.results) {
                    setDataAccordingToFilters(response?.data?.results)
                    setUserDataCount(response?.data?.totalData)
                } else {
                    setUserData({})
                }
            } else {
                setUserData({})
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const sortDataForDefaultView = (data) => {
        return data?.sort((a, b) => {
            const roleA = a?.user_role?.toUpperCase();
            const roleB = b?.user_role?.toUpperCase();
            if (roleA < roleB)
                return -1;
            if (roleA > roleB)
                return 1;
            return 0;
        })
    }

    const setDataAccordingToFilters = (data) => {
        let keyToFilter = (groupBy === 'role') ? 'user_role' : groupBy === 'workType' ? 'work_type' : 'active';
        if (params?.sortField === '') {
            data = sortDataForDefaultView([...data]);
        }
        let filteredData = data?.reduce((result, item) => {
            const _key = keyToFilter === 'active' ? item[keyToFilter] ? titleCase(keyToFilter) : 'Inactive' : titleCase(item[keyToFilter]);
            if (!result[_key]) {
                result[_key] = [];
            }
            result[_key].push(item);
            return result;
        }, {});
        setExpandedRow(Object.keys(filteredData));
        setUserData(filteredData)
    }

    const handleChangePage = (event, newPage) => {
        setParams((prev) => ({
            ...prev,
            page: newPage,
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setParams((prev) => ({
            ...prev,
            limit: event.target.value,
            page: 0,
        }));
    };

    const handleFilterChange = (event) => {
        setQueryParams(event?.target?.name === "group" ? "groupBy" : "role", event?.target?.value)
        setParams((prev) => ({
            ...prev,
            [event?.target?.name]: event?.target?.value === "All" ? '' : event?.target?.value
        }))
        setExpandedRow([]);
    }

    const handleRowClick = (filterName) => {
        setExpandedRow((prev) => prev.includes(filterName) ? prev?.filter(name => name !== filterName) : [...prev, filterName])
    }

    const handleSearch = (value) => {
        setSearchString(value);
    };

    const createSortHandler = (property) => {
        const isAsc = (orderBy === property) ? (order === 'asc') ? true : false : true;
        setOrder(isAsc ? 'desc' : 'asc');
        setParams((prev) => ({ ...prev, sortOrder: isAsc ? 'desc' : 'asc', sortField: property }))
        setOrderBy(property);
    }

    const handleLeaveCount = (userDetail) => {
        let total_leave = userDetail?.userLeaveTypeData?.reduce((total, leave) => {
            return total + leave?.leave_used
        }, 0);
        return `${total_leave || 0}/${(userDetail?.total_leave_allocation || 0)}`;
    }

    const handleRemainingLeaveCount = (userLeaveData) => {
        let remainingLeave = userLeaveData?.reduce((result, item) => {
            const _key = item?.leave_type;
            if (!result[_key]) {
                result[_key] = [];
            }
            result[_key] = `${item?.leave_used || 0}/${(item?.total_leave_allocation) || 0} Days`;
            return result;
        }, {});
        return remainingLeave;
    }

    const handleClearFilter = () => {
        if (params?.search?.length || params?.sortField !== "" || params?.group !== 'role' || params?.filter !== "") {
            setParams(prev => ({
                ...prev,
                page: 0,
                sortOrder: "asc",
                sortField: "id",
                group: "",
                filter: "",
                search: null
            }))
        }
        setQueryParams("groupBy", "none")
        setQueryParams("role", "All")
        setSearchString('')
    }

    const handleViewUser = (userDetail, tabValue = 2) => {
        handleClose();
        navigate(`${URLS.UserDetails}/${userDetail?.id}?tabValue=${tabValue}`)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };

    const handleClickEdit = (userDetail) => {
        navigate(`${URLS.UserDetails}/${userDetail?.id}?tabValue=0&isEdit=true`)
    }

    const handleSubmitMakeAdmin = async () => {
        try {
            setLoading((prev) => ({ ...prev, formSubmitting: true }));
            let adminTypeId = userTypes?.find(item => item?.name?.toLowerCase() === "admin")?.id;
            let administratorRoleId = userRolesData?.find(item => item?.name?.toLowerCase() === "administrator")?.id;
            const body = {
                "name": editableUserModalData?.name,
                "reporting_authorities": editableUserModalData?.reporting_authorities?.length ? editableUserModalData?.reporting_authorities : null,
                "second_reporting_authorities": editableUserModalData?.second_reporting_authorities?.length ? editableUserModalData?.second_reporting_authorities : null,
                "current_user_type_id": editableUserModalData?.is_admin ? editableUserModalData?.current_user_type_id : adminTypeId ?? editableUserModalData?.current_user_type_id,
                "current_user_role_id": editableUserModalData?.is_admin ? editableUserModalData?.current_user_role_id : administratorRoleId ?? editableUserModalData?.current_user_role_id,
                "org_work_type_setting_id": editableUserModalData?.org_work_type_setting_id,
                "is_admin": editableUserModalData?.is_admin ? false : true,
                "is_dedicated_developer": editableUserModalData?.is_dedicated_developer,
                "enable_sys_generated_leave": editableUserModalData?.enable_sys_generated_leave,
                "active": editableUserModalData?.active,
            };
            if (editableUserModalData?.joining_date) {
                body['joining_date'] = editableUserModalData?.joining_date
            }
            if (editableUserModalData?.termination_date) {
                body['termination_date'] = editableUserModalData?.termination_date
            }

            let response = await axiosInstance.put(API.fetchUserDetails(editableUserModalData?.id), body);
            if (response.status === 200) {
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
                showAlert(response.data.message);
                getAllUserListByOrgId();
                handleCloseModal();
            }
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        } catch (error) {
            console.error(error);
            handleCloseModal();
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
    }

    const handleModalOpen = (userDetails) => {
        setIsMakeAnAdminOpen(true);
        setEditableUserModalData(userDetails);
    }

    const handleCloseModal = () => {
        setIsMakeAnAdminOpen(false);
        setAnchorEl(null);
        setOpenElem(null);
        setEditableUserModalData({});
    }

    const handleClickOpenModal = (userDetails) => {
        setIsReporModalOpen(true)
        setLeaveData(userDetails)
    }

    const handleReportModalClose = () => {
        setIsReporModalOpen(false)
        setLeaveData({})
    }

    return (
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <Box px={2} py={1} borderBottom="1px solid #E0E0E0" bgcolor="white" position="sticky" top={0} zIndex={1}>
                <Typography variant='h2' color="secondary" fontSize={18} fontWeight={600} sx={{ lineHeight: "27px", letterSpacing: '0.15px' }}>Users</Typography>
            </Box>
            <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} sx={{ background: '#ffffff' }} flex={1} overflow="auto">
                {(loading?.userRoleLoading || loading?.years) ?
                    <CircularLoader height="500px" /> :
                    <Box borderRadius={0.5} border="1px solid #E0E0E0" overflow="hidden">
                        <Grid container p={{ xs: 2, xl: 3 }} display="flex" justifyContent="space-between" borderBottom="1px solid #E0E0E0">
                            <Grid item display="flex">
                                <Stack direction="row" spacing={2}>
                                    <Grid item width={{ xs: 180, xl: 230 }}>
                                        <SearchBoxInput
                                            label="Search"
                                            variant="standard"
                                            placeholder="Name, email, etc..."
                                            handleSearch={handleSearch}
                                            value={searchString}
                                            isUseDebounce={false}
                                            isOverwritePlaceholder={true}
                                        />
                                    </Grid>
                                    <Grid item width={{ xs: '150px', xl: '180px' }}>
                                        <FormControl variant="standard" sx={{ width: '100%' }}>
                                            <InputLabel htmlFor={getInputId("group", 0)}>Group By</InputLabel>
                                            <Select
                                                id='group'
                                                value={groupBy || ''}
                                                label="Group By"
                                                name='group'
                                                onChange={(e) => handleFilterChange(e)}
                                                inputProps={{ id: getInputId("group", 0) }}
                                            >
                                                {Object.keys(Group_Filter)?.map((key, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={key}
                                                    >
                                                        {Group_Filter[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item width={{ xs: '150px', xl: '180px' }}>
                                        <FormControl variant="standard" sx={{ width: '100%' }}>
                                            <InputLabel htmlFor={getInputId("filter", 0)}>Role</InputLabel>
                                            <Select
                                                label="Role"
                                                name='filter'
                                                value={role || ''}
                                                onChange={(e) => handleFilterChange(e)}
                                                inputProps={{ id: getInputId("filter", 0) }}
                                            >
                                                {userRolesData?.map(
                                                    ({ name }, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={name}
                                                        >
                                                            {titleCase(name)}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Button variant='text' color='primary'
                                        size="small"
                                        sx={{ marginTop: '16px !important', textTransform: 'none' }}
                                        onClick={() => handleClearFilter()}>Clear Filters</Button>
                                </Stack>
                            </Grid>
                            <Grid item display="flex" justifyContent="flex-end" alignItems="center">
                                <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size="small"
                                        sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                        onClick={() => navigate(URLS.CreateUser)}
                                    >

                                        Add User
                                    </Button>
                                    {/* <IconButton color='secondary' size="small" borderRadius={0}>
                                        <SettingsRoundedIcon sx={{ fontSize: '24px' }} />
                                    </IconButton> */}
                                </Stack>
                            </Grid>
                        </Grid>
                        <Paper sx={{ width: '100%', borderRadius: '0px', boxShadow: 'none', }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                >
                                    <TableHead>
                                        <TableRow sx={{
                                            whiteSpace: 'nowrap',
                                            'th': {
                                                bgcolor: "bgColor", padding: "8px"
                                            }
                                        }}>

                                            <TableCell sx={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}
                                                sortDirection={orderBy === 'user_role' ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === 'user_role'}
                                                    direction={orderBy === 'user_role' ? order : 'asc'}
                                                    onClick={() => !loading.pageLoading && createSortHandler('user_role')}
                                                >
                                                    {params?.group === "none" ? "" : titleCase(params?.group)}
                                                    {orderBy === 'user_role' ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ width: '100px', maxWidth: '100px', minWidth: '100px', paddingRight: '0px !important' }}>
                                                <TableSortLabel
                                                    active={orderBy === 'emp_id'}
                                                    direction={orderBy === 'emp_id' ? order : 'asc'}
                                                    onClick={() => !loading.pageLoading && createSortHandler('emp_id')}
                                                >
                                                    Emp ID
                                                    {orderBy === 'emp_id' ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'name'}
                                                    direction={orderBy === 'name' ? order : 'asc'}
                                                    onClick={() => !loading.pageLoading && createSortHandler('name')}
                                                >
                                                    User
                                                    {orderBy === 'name' ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ width: '140px', maxWidth: '140px', minWidth: '140px' }}>
                                                Leaves Taken
                                            </TableCell>
                                            <TableCell sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                User Type
                                            </TableCell>
                                            {groupBy !== "workType" ?
                                                <TableCell sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                    <TableSortLabel
                                                        active={orderBy === 'work_type'}
                                                        direction={orderBy === 'work_type' ? order : 'asc'}
                                                        onClick={() => !loading.pageLoading && createSortHandler('work_type')}
                                                    >
                                                        Work Type
                                                        {orderBy === 'work_type' ? (
                                                            <Box component="span" sx={visuallyHidden}>
                                                                {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </Box>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                                : null}
                                            {groupBy !== "role" ? <TableCell sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                User Role
                                            </TableCell> : null}
                                            {/* <TableCell sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                Is Admin
                                            </TableCell> */}
                                            {/* <TableCell sx={{ width: '140px', maxWidth: '140px', minWidth: '140px' }}>
                                                Projects
                                            </TableCell> */}
                                            {groupBy !== "status" ? <TableCell sx={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}>
                                                Status
                                            </TableCell> : null}
                                            <TableCell sx={{ textAlign: 'center', width: '48px', maxWidth: '48px', minWidth: '48px' }}>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading.pageLoading ?
                                            <CircularLoader variant="table" rows={params.limit} cols={8} /> :
                                            Object.entries(userData)?.length ? (Object.entries(userData)?.map((user, index) => {
                                                let isOpen = expandedRow?.includes(user?.[0]);
                                                return (
                                                    <React.Fragment key={index}>
                                                        {params.group !== "none" ?
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                sx={{ cursor: 'pointer' }}
                                                                onClick={() => handleRowClick(user?.[0])}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    align='left'
                                                                    color='secondary'
                                                                    fontSize="14"
                                                                    fontWeight='500'
                                                                    sx={{ padding: '8px 16px' }}
                                                                    colSpan={10}
                                                                >
                                                                    <Grid item display='flex' alignItems='center' color="secondary.main" fontSize={14} fontWeight={500} sx={{ lineHeight: '22px' }}>
                                                                        {expandedRow?.includes(user[0]) ? <ExpandMoreIcon sx={{ fontSize: '18px', mr: 2 }} /> : <ChevronRightIcon sx={{ fontSize: '18px', mr: 2 }} />}
                                                                        {`${user?.[0]} (${user?.[1]?.length})`}
                                                                    </Grid>
                                                                </TableCell>
                                                            </TableRow> : null}
                                                        {isOpen ? user?.[1]?.map((userDetail, index) => (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={index}
                                                                sx={{ 'td': { cursor: "pointer", color: 'rgba(0, 0, 0, 0.87)', fontSize: '14px', fontWeight: '400', lineHeight: '20px', letterSpacing: '0.17px', padding: '13px 8px' } }}
                                                            // onClick={() => navigate(`${URLS.UserDetails}/${userDetail?.id}`)}
                                                            >
                                                                <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}></TableCell>
                                                                <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '100px', maxWidth: '100px', minWidth: '100px', paddingRight: '0px !important' }}>#{userDetail?.emp_id}</TableCell>
                                                                <TableCell onClick={() => handleViewUser(userDetail, 0)}>
                                                                    <Grid item display="flex" alignItems="center">
                                                                        <BackgroundLetterAvatars
                                                                            user={userDetail}
                                                                            sx={{ width: "32px", height: "32px", fontSize: '90%' }}
                                                                        />
                                                                        <Grid item ml={1}>
                                                                            <Typography variant='body1' fontSize={15} fontWeight={500} color="dark.800" sx={{ lineHeight: '24px', letterSpacing: '0.17px' }}> {getFullName(userDetail)}</Typography>
                                                                            <Typography variant='body1' fontSize={12} fontWeight={400} color="main.900" sx={{ lineHeight: '15px' }}>{userDetail?.email}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell sx={{ width: '140px', maxWidth: '140px', minWidth: '140px' }}>
                                                                    <HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                <Typography variant='body1' fontWeight={500} color="white" mb={2}>Remaining Leaves</Typography>
                                                                                <List sx={{
                                                                                    p: 0,
                                                                                    minWidth: '238px',
                                                                                    'li': { p: 0, display: 'flex', justifyContent: 'space-between', marginBottom: '2px' }
                                                                                }}>
                                                                                    {Object.entries(handleRemainingLeaveCount(userDetail?.userLeaveTypeData))?.length ? Object.entries(handleRemainingLeaveCount(userDetail?.userLeaveTypeData))?.map((leaveDetail, index) => (
                                                                                        <ListItem key={index}>
                                                                                            <Typography variant='body2' fontSize={13} fontWeight={500} pr={1} sx={{ lineHeight: '19px' }}>{leaveDetail?.[0]}</Typography>
                                                                                            <Typography variant='body2' fontSize={13} fontWeight={500} sx={{ lineHeight: '19px' }}>{leaveDetail?.[1]}</Typography>
                                                                                        </ListItem>
                                                                                    )) : <ListItem>No leaves Data Found</ListItem>}
                                                                                </List>
                                                                            </React.Fragment>
                                                                        }
                                                                    >
                                                                        <Typography fontSize={14} fontWeight={400} color="dark.800" display={"inline-block"}
                                                                            sx={{
                                                                                lineHeight: '20px',
                                                                                letterSpacing: '0.17px',
                                                                                "&:hover": {
                                                                                    color: "primary.main",
                                                                                },
                                                                            }}
                                                                            onClick={() => handleClickOpenModal(userDetail)}> {handleLeaveCount(userDetail)}</Typography>
                                                                    </HtmlTooltip>
                                                                </TableCell>
                                                                <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>{titleCase(userDetail?.user_type) || "-"}</TableCell>
                                                                {groupBy !== "workType" ? <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>{titleCase(userDetail?.work_type) || "-"}</TableCell> : null}
                                                                {groupBy !== "role" ? <TableCell onClick={() => handleViewUser(userDetail)} sx={{
                                                                    width: '160px', maxWidth: '160px', minWidth: '160px',
                                                                    '.MuiChip-filled': {
                                                                        padding: '3px 10px 3px 0px',
                                                                        height: 'auto',
                                                                        '.MuiChip-avatarSmall': {
                                                                            width: '24px',
                                                                            height: '24px'
                                                                        }
                                                                    }
                                                                }}>
                                                                    <UserRoleChip label={titleCase(userDetail?.user_role) || "-"} />
                                                                </TableCell> : null}
                                                                {/* <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                                    <Switch
                                                                        name="is_admin"
                                                                        id="is_admin"
                                                                        size="small"
                                                                        checked={userDetail?.is_admin}
                                                                        disabled={true}
                                                                    />
                                                                </TableCell> */}
                                                                {/* <TableCell onClick={() => handleViewUser(userDetail, 1)} sx={{ width: '140px', maxWidth: '140px', minWidth: '140px' }}>
                                                                    <Button variant='text'
                                                                        color='primary'
                                                                        size="small" fontSize={13} fontWeight={500} p={0}
                                                                        sx={{
                                                                            textTransform: 'none',
                                                                        }}>6 Projects</Button>
                                                                </TableCell> */}
                                                                {groupBy !== "status" ? <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}>
                                                                    {userDetail?.active ?
                                                                        <Chip label="Active" color='secondary' size="small" variant='filled'></Chip>
                                                                        :
                                                                        <Chip label="Inactive" size="small" variant='filled'></Chip>}
                                                                </TableCell> : null}
                                                                <TableCell sx={{ textAlign: 'center', width: '48px', maxWidth: '48px', minWidth: '48px', paddingLeft: '0px !important', paddingRight: '0px !important', }}>
                                                                    <IconButton
                                                                        aria-label="more"
                                                                        aria-haspopup="true"
                                                                        onClick={handleClick(userDetail)}
                                                                        sx={{
                                                                            height: "30px",
                                                                            width: "30px",
                                                                            padding: "0px",
                                                                            borderRadius: "4px",
                                                                        }}
                                                                        id="basic-button"
                                                                        aria-controls={open ? 'basic-menu' : ""}
                                                                        aria-expanded={open ? 'true' : "false"}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                height: "20px",
                                                                                width: "20px",
                                                                                display: "flex",
                                                                            }}
                                                                        >
                                                                            {ICONS.DotsHorizontal}
                                                                        </i>
                                                                    </IconButton>
                                                                    <Menu
                                                                        id="basic-menu"
                                                                        anchorEl={anchorEl}
                                                                        open={open && openElem?.id === userDetail?.id}
                                                                        onClose={handleClose}
                                                                        MenuListProps={{
                                                                            'aria-labelledby': 'basic-button',
                                                                        }}
                                                                        slotProps={{
                                                                            paper: {
                                                                                style: {
                                                                                    minWidth: "180px",
                                                                                },
                                                                            },
                                                                        }}
                                                                        sx={{
                                                                            '.MuiMenu-paper': {
                                                                                boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.1)',
                                                                            }
                                                                        }}
                                                                    >
                                                                        <MenuItem onClick={() => handleClickEdit(userDetail)}>Edit</MenuItem>
                                                                        {(!userDetail?.is_admin && userDetail?.active) ? <MenuItem onClick={() => handleModalOpen(userDetail)} disabled={userDetail?.external}>{'Make an admin'}</MenuItem> : null}
                                                                        {(userDetail?.user_type === 'manager' && userDetail?.is_admin && userDetail?.active) ? <MenuItem onClick={() => handleModalOpen(userDetail)}>{'Remove from an admin'}</MenuItem> : null}
                                                                    </Menu>
                                                                </TableCell>
                                                            </TableRow>
                                                        )) : null}
                                                    </React.Fragment>
                                                )
                                            })) : (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={8}
                                                        align="center"
                                                        sx={{ padding: "12px 16px;" }}
                                                    >
                                                        <Typography
                                                            color={"secondary"}
                                                            display={"flex"}
                                                            alignItems={"center"}
                                                            justifyContent={"center"}
                                                            fontSize={13}
                                                            sx={{ opacity: "0.5", height: "236px" }}
                                                        >
                                                            No records found.
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                count={userDataCount}
                                rowsPerPage={params.limit}
                                page={params.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                }
            </Box>
            <Modal
                open={isMakeAnAdminOpen}
                onClose={() => setIsMakeAnAdminOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '> div': {
                        overflow: 'hidden',
                        '> div': {
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    }
                }}
            >
                <Box sx={[style, {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto !important",
                }]}>
                    <Box>
                        <Grid item py={2} px={3} borderBottom="1px solid #E2E4EC" sx={{
                            position: "sticky",
                            bottom: 0,
                            left: 0,
                            background: "white",
                        }}>
                            <Typography fontSize={20} lineHeight={'32px'} letterSpacing={'0.15px'} fontWeight={500}>{editableUserModalData?.is_admin ? 'Remove from an Admin' : 'Make an Admin'}</Typography>
                        </Grid>
                    </Box>
                    <Grid item py={3} px={3} flex={1} overflow={"auto"} width={576}>
                        <Box>
                            <Typography fontSize={16} lineHeight={'26px'} letterSpacing={'0.15px'} fontWeight={400}>
                                {editableUserModalData?.is_admin ?
                                    <>
                                        Removing <b>{`${editableUserModalData?.name ?? "this user"}`}</b> from an admin?
                                    </> :
                                    <>
                                        Assigning <b>{`${editableUserModalData?.name ?? "this user"}`}</b> as an admin?
                                    </>
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        py={2}
                        px={3}
                        sx={{
                            borderTop: "1px solid #E2E4EC",
                            display: "flex",
                            justifyContent: "space-between",
                            position: "sticky",
                            bottom: 0,
                            left: 0,
                            background: "white",
                            flexDirection: 'row !important',
                            overflow: 'visible !important',
                        }}
                    >
                        <Stack spacing={1} direction="row">
                            <Button
                                variant="contained"
                                size="medium"
                                color={editableUserModalData?.is_admin ? "error" : "primary"}
                                fontWeight="500"
                                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                onClick={() => handleSubmitMakeAdmin()}
                                isButtonLoading={loading?.formSubmitting}
                                disabled={loading?.formSubmitting}
                            >
                                {"Yes"}
                            </Button>
                            <Button
                                variant="text"
                                color="secondary"
                                sx={{ textTransform: "none" }}
                                size="medium"
                                fontWeight="500"
                                onClick={() => handleCloseModal()}
                                disabled={loading?.formSubmitting}
                            >
                                {"Cancel"}
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </Modal>
            <BootstrapDialog
                open={isReporModalOpen}
                onClose={() => handleReportModalClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    alignItems: 'flex-start',
                    '.MuiDialog-paper': {
                        maxWidth: '100%',
                        width: '1200px',
                        ' > div': {
                            overflow: 'hidden',
                            display: 'flex',
                            '> div': {
                                display: 'flex',
                            }
                        },
                    },
                    '.MuiDialog-container': { alignItems: 'flex-start' }
                }}
            >
                <DialogContent sx={{ width: '100%' }}>
                    <Box sx={{ width: '100%' }}>
                        <LeaveReportModal activeYear={activeYear} handleReportModalClose={handleReportModalClose} userData={leaveData} years={leaveReportyears} currentUser={currentUser} />
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </Box>
    );
};

export default Users;