import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react';
import { Checkbox, Divider, Drawer, FormControlLabel, Grid, IconButton, List, ListItem, Stack, Typography } from '@mui/material'
import API from "../../../../axios/api";
import axiosInstance from "../../../../axios";
import CircularLoader from '../../../../components/CircularLoader';
import { usePageTitle } from '../../../../hook/useTitle';
import { useAlert } from '../../../../hook/useAlert';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import Button from '../../../../components/Button';
import URLS from '../../../../routes/urls';
import { useNavigate } from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from '@mui/icons-material/Email';
import EmptyPage from '../../../../components/EmptyPage';
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import { getFullName, getInputId } from '../../../../utils';


const EmailTemplates = () => {
    const navigate = useNavigate();
    const showAlert = useAlert();
    const { setPageTitle } = usePageTitle();
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: false,
        sidebarLoading: false
    });

    const [templateData, setTemplateData] = useState([]);
    const [contactsForMailList, setContactsForMailList] = useState([]);
    const [isDeleteIsOpen, setIsDeleteIsOpen] = useState(false);
    const [deletedTemplateData, setDeletedTemplateData] = useState(null);
    const [showSideBarForSendMail, setShowSideBarForSendMail] = useState(false);
    const [contactMailFormData, setContactMailFormData] = useState({
        recipeintIdsList: [],
        templateId: null,
    })

    setPageTitle("Campaigns - Templates");


    useEffect(() => {
        getAllTemplateList();
        getAllContactList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllTemplateList = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.post(
                API.getAllTemplateList
            );

            if (response?.status === 200) {
                setTemplateData(response?.data?.data);
            } else {
                setTemplateData([]);
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const getAllContactList = async () => {
        try {
            setLoading((prev) => ({ ...prev, sidebarLoading: true }));
            const response = await axiosInstance.get(
                API.getAllContactsList
            );

            if (response?.status === 200) {
                setContactsForMailList(response?.data?.data);
            } else {
                setContactsForMailList([]);
            }
            setLoading((prev) => ({ ...prev, sidebarLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, sidebarLoading: false }));
        }
    };

    const handleSubmitDelete = async () => {
        try {
            setLoading((prev) => ({ ...prev, formSubmitting: true }));
            const response = await axiosInstance.delete(API.deleteContact(deletedTemplateData?.id));
            if (response?.status === 200) {
                setTemplateData(prev => prev.filter((contact) => contact?.id !== deletedTemplateData?.id));
                setDeletedTemplateData(null);
                setIsDeleteIsOpen(false);
                showAlert(response?.data?.message)
            }
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        } catch (error) {
            console.error("Error:", error);
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
    };

    const handleSendEmailToContacts = async () => {
        try {
            setLoading(prev => ({ ...prev, formSubmitting: true }));
            const response = await axiosInstance.post(API.sendEmailTemplateToContacts, contactMailFormData);
            if (response?.status === 200) {
                showAlert(response?.data?.message);
                setShowSideBarForSendMail(false);
                setContactMailFormData({
                    recipeintIdsList: [],
                    templateId: null,
                });
            } else {
                showAlert(response?.data?.message, "error");
            }
            setLoading(prev => ({ ...prev, formSubmitting: false }));
        } catch (error) {
            console.error(error);
            setLoading(prev => ({ ...prev, formSubmitting: false }));
        }
    }

    const DrawerList = (
        <Box
            sx={{ width: 250 }}
            display={"flex"}
            flexDirection={"column"}
            overflow={"hidden"}
            role="presentation"
        >
            <Grid
                item
                p={1.5}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ top: "0", zIndex: "1" }}
            >
                <Box display={"flex"} flexDirection={"row"}>
                    <Typography
                        color="secondary"
                        fontSize={15}
                        fontWeight={600}
                        sx={{ lineHeight: "22px", mr: "10px" }}
                    >
                        Contacts
                    </Typography>
                    <Typography
                        color="secondary"
                        bgcolor="dark.100"
                        fontSize={12}
                        fontWeight={600}
                        sx={{ lineHeight: "22px", padding: "0px 5px", borderRadius: "5px" }}
                    >
                        {contactsForMailList?.length}
                    </Typography>
                    <Divider />
                </Box>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        className="edit-btn"
                        onClick={() => {
                            setShowSideBarForSendMail(false);
                            setContactMailFormData({
                                recipeintIdsList: [],
                                templateId: null,
                            });
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid
                item
                p={1.5}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ top: "0", zIndex: "1" }}
            >
                <FormControlLabel
                    id={getInputId("select-all-contacts")}
                    name="select-all-contacts"
                    control={<Checkbox />}
                    checked={contactMailFormData.recipeintIdsList.length === contactsForMailList.length}
                    label="Select All"
                    onChange={(event, checked) => {
                        if (checked) {
                            const selectedContacts = [];
                            contactsForMailList.forEach((user) => selectedContacts.push(user?.id));
                            setContactMailFormData(prev => ({ ...prev, recipeintIdsList: selectedContacts }))
                        } else {
                            setContactMailFormData(prev => ({ ...prev, recipeintIdsList: [] }))
                        }
                    }}
                />
                <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                    <Button
                        variant='contained'
                        color='primary'
                        size="small"
                        sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                        onClick={handleSendEmailToContacts}
                    >

                        Send Email
                    </Button>
                </Stack>
            </Grid>
            <Box
                sx={{ height: "100%", visibility: "visible" }}
                flex={1}
                overflow={"auto"}
            >
                <Stack spacing={3}>
                    <div>
                        {contactsForMailList?.length > 0 ? (
                            contactsForMailList?.map((userData, index) => (
                                <List sx={{ p: 0 }} key={index}>
                                    <ListItem
                                        sx={{
                                            padding: "8px 12px",
                                        }}
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            color="secondary"
                                            sx={{ opacity: "0", paddingRight: "10px" }}

                                        >
                                            <SettingsIcon fontSize="small" />
                                        </IconButton>
                                        <FormControlLabel
                                            id={getInputId("mail-check")}
                                            name="mail-check"
                                            control={<Checkbox />}
                                            checked={contactMailFormData.recipeintIdsList?.includes(userData?.id)}
                                            label=""
                                            onChange={(event, checked) => {
                                                if (checked) {
                                                    setContactMailFormData(prev => ({ ...prev, recipeintIdsList: [...prev.recipeintIdsList, userData?.id] }))
                                                } else {
                                                    setContactMailFormData(prev => ({ ...prev, recipeintIdsList: prev.recipeintIdsList.filter(id => id !== userData?.id) }))
                                                }
                                            }}
                                        />
                                        <BackgroundLetterAvatars
                                            user={userData}
                                            sx={{ width: 30, height: 30, mr: 1, fontSize: "16px" }}
                                        />
                                        <Typography
                                            color="secondary"
                                            fontSize={14}
                                            fontWeight={500}
                                            sx={{
                                                lineHeight: "22px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                            flex={1}
                                        >
                                            {getFullName(userData)}
                                        </Typography>
                                    </ListItem>
                                </List>
                            ))
                        ) : (
                            <EmptyPage
                                title="No users found"
                                isButtonShow={false}
                            />
                        )}
                    </div>
                </Stack>
            </Box>
        </Box>
    );

    return (
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} sx={{ background: '#ffffff' }} flex={1} overflow="auto">
                {(loading?.userRoleLoading || loading?.years) ?
                    <CircularLoader height="500px" /> :
                    <Box borderRadius={0.5} border="1px solid #E0E0E0" overflow="hidden">
                        <Grid item display="flex" justifyContent="flex-end" alignItems="center" gap={1} padding={2}>
                            <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size="small"
                                    sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                    onClick={() => navigate(URLS.CreateEmailTemplate)}
                                >

                                    Add Template
                                </Button>
                            </Stack>
                        </Grid>
                        <Paper sx={{ width: '100%', borderRadius: '0px', boxShadow: 'none', }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                >
                                    <TableHead>
                                        <TableRow sx={{
                                            whiteSpace: 'nowrap',
                                            'th': {
                                                bgcolor: "bgColor", padding: "8px"
                                            }
                                        }}>
                                            <TableCell sx={{ width: '100px', maxWidth: '100px', minWidth: '100px', paddingRight: '0px !important' }}>#</TableCell>
                                            <TableCell>Title</TableCell>
                                            <TableCell>Sub Title</TableCell>
                                            <TableCell sx={{ textAlign: 'center', width: '48px', maxWidth: '48px', minWidth: '48px' }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading.pageLoading ?
                                            <CircularLoader variant="table" rows={25} cols={8} /> :
                                            templateData?.length ? templateData?.map((template, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={15} fontWeight={500} color="dark.800" sx={{ letterSpacing: '0.17px' }}>{index + 1}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={15} fontWeight={500} color="dark.800" sx={{ letterSpacing: '0.17px' }}>{template?.title}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={15} fontWeight={500} color="dark.800" sx={{ letterSpacing: '0.17px' }}>{template?.sub_title}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center', width: '100px', maxWidth: '100px', minWidth: '100px', paddingLeft: '0px !important', paddingRight: '0px !important', display: "flex", gap: 1 }}>
                                                                <IconButton
                                                                    aria-label="edit"
                                                                    size="small"
                                                                    color="secondary"
                                                                    onClick={() => navigate(`${URLS.UpdateEmailTemplate}/${template?.id}`)}
                                                                >
                                                                    <EditIcon fontSize="small" />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="edit"
                                                                    size="small"
                                                                    color="secondary"
                                                                    onClick={() => { setShowSideBarForSendMail(true); setContactMailFormData(prev => ({ ...prev, templateId: template?.id })) }}
                                                                >
                                                                    <EmailIcon fontSize="small" />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>)
                                            }) : (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={8}
                                                        align="center"
                                                        sx={{ padding: "12px 16px;" }}
                                                    >
                                                        <Typography
                                                            color={"secondary"}
                                                            display={"flex"}
                                                            alignItems={"center"}
                                                            justifyContent={"center"}
                                                            fontSize={13}
                                                            sx={{ opacity: "0.5", height: "236px" }}
                                                        >
                                                            No records found.
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                }
            </Box>
            <Drawer open={showSideBarForSendMail} anchor="right" onClose={() => { }}>
                {DrawerList}
            </Drawer>
            <ConfirmationModal
                isOpen={isDeleteIsOpen}
                title={'Template'}
                deletableDataName={deletedTemplateData?.name}
                dataContentName={'Template'}
                handleClose={() => setIsDeleteIsOpen(false)}
                onConfirm={handleSubmitDelete}
                onCancel={() => setIsDeleteIsOpen(false)}
                isButtonLoading={loading.formSubmitting}
                subList={<span>This will remove <b>{deletedTemplateData?.name}</b> from your contact list.</span>}
            />
        </Box>
    );
};

export default EmailTemplates;
