import { Box } from '@mui/material';
import React from 'react';
// import TimeTracking from './TimeTracking';
import { usePageTitle } from '../../../hook/useTitle';
import QuickActions from './QuickActions';
import UserSummary from './UserSummary';
import SystemGeneratedLeaveNotification from './SystemGenretedLeaveNotification';
import useAuthentication from '../../../hook/useAuthentication';
import CurrentStatusReport from './CurrentStatusReport';
import LeaveList from './leaveList';


const Dashboard = () => {
    const { setPageTitle } = usePageTitle();
    const currentUser = useAuthentication().getCurrentUser();
    setPageTitle("Dashboard");
    return (
        <>
            <Box
                className="organisation-dashboard-wrapper"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    overflow: "auto",
                    gap: 3,
                    background: "#FAFBFF",
                }}
                p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }}
            >
                {/* <TimeTracking /> */}
                <SystemGeneratedLeaveNotification />
                <QuickActions />
                <LeaveList />
                {currentUser?.is_admin ?
                    <CurrentStatusReport /> :
                    <UserSummary />
                }
            </Box>
        </>
    )
}

export default Dashboard;