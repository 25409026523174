/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonGroup,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
    tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ICONS from "../../../../constants/icons";
import { styled } from "@mui/styles";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { LEAVE_STATUS, OTHERS_LEAVE_FILTER, REQUEST_TYPE, STATUS_BY_FILTER } from "../../../../constants/default-values";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import useAuthentication from "../../../../hook/useAuthentication";
import CircularLoader from "../../../../components/CircularLoader";
import { calculateLeaveCount, getAllLeaveDates, getApplicationNumber, getApplyDateTime, getFirstLetterOfString, getFullName, getInputId, getLeaveDateArray, getLeaveDates, getLeaveStatus, isInPastDays, isMoreThan30Days, isWithinNext30Days, isWithinNext7Days, showInformationAuthority, sortRequestArrayByDate, specialRequestList, titleCase } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import URLS from "../../../../routes/urls";
import useQuery from "../../../../hook/useQuery";
import WFHCalendarView from "./WHFListing/WFHCalendarView";
import BackgroundLetterAvatars from "../../../../components/BackgroundLetterAvatars";
import EmptyPage from "../../../../components/EmptyPage";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: "16px",
        borderRadius: "5px",
        background: "#3F5C76",
    },
}));

const GROUP_FILTER = {
    Status: "Status",
    "WFH Date": "WFH Date",
    "User Role": "User Role",
    "WFH Type": "WFH Type",
    "Work Type": "Work Type",
    "User": "User"
};

const OthersWFHRequests = () => {
    const { getCurrentUser } = useAuthentication();
    const { setQueryParams, getQueryParams, deleteQueryParams } = useQuery();
    let view = getQueryParams("view") || "list";
    let groupBy = getQueryParams("groupBy") && Object.values(GROUP_FILTER)?.includes(getQueryParams("groupBy")) ? getQueryParams("groupBy") : Object.values(GROUP_FILTER)[0];
    let status = getQueryParams("status") && Object.keys(OTHERS_LEAVE_FILTER)?.includes(getQueryParams("status")) ? getQueryParams("status") : Object.keys(OTHERS_LEAVE_FILTER)[0];
    let filterStatus = getQueryParams("filterStatus") && Object.values(STATUS_BY_FILTER)?.includes(getQueryParams("filterStatus")) ? getQueryParams("filterStatus") : Object.values(STATUS_BY_FILTER)[0];
    const navigate = useNavigate();
    const currentUser = getCurrentUser();
    const [othersLeaveData, setOthersLeaveData] = useState({});
    const [othersLeaveDataForCalendar, setOthersLeaveDataForCalendar] = useState([]);
    const [optionalOthersLeaveData, setOptionalOthersLeaveData] = useState([]);
    const [optionalUserLeaveData, setOptionalUserLeaveData] = useState([]);
    const [isLoading, setIsLoading] = useState({
        dataLoading: true,
        pageLoading: true,
        singleUserDataLoading: false
    });
    const [openAccordianList, setOpenAccordianList] = useState([]);
    const [userFilter, setUserFilter] = useState({});
    const [filterUserName, setFilterUserName] = useState("");
    const [allUserList, setAllUserList] = useState({});

    useEffect(() => {
        if (groupBy === GROUP_FILTER["User"])
            getDataAccordingToGroupByFilters(optionalUserLeaveData);
        else
            getDataAccordingToGroupByFilters(optionalOthersLeaveData);
    }, [groupBy, filterStatus]);

    useEffect(() => {
        if (status)
            getOthersLeaveData(status);
    }, [status])

    const getOthersLeaveData = async (status) => {
        try {
            setIsLoading((prev) => ({ ...prev, dataLoading: true }));
            let queryParams = {
                // page: 1,
                // limit: 100,
                status: status,
            };
            const response = await axiosInstance.get(
                API.getOtWfhApplicationList(currentUser.id),
                {
                    params: queryParams,
                }
            );
            if (response?.status === 200) {
                if (response?.data?.data?.length) {
                    const mapperObj = {
                        1: "work from home",
                        2: "work from other place",
                        3: "availability change",
                        4: "leave early",
                        5: "come late",
                        6: "emergency",
                        7: "over lapping max absent allowed",
                        8: "unpaid leaves",
                        9: "max club leave",
                        10: "leave on same day",
                        11: "dedicated relaxation leave",
                    };
                    let WfhData = response?.data?.data?.map((wfhApp) => { return { ...wfhApp, work_type: mapperObj[wfhApp?.static_id] } })
                    let sortedArray = sortRequestArrayByDate(WfhData, "WfhDays", "date", status === Object.keys(OTHERS_LEAVE_FILTER)[0] ? "asc" : "desc");
                    setOthersLeaveDataForCalendar(sortedArray);
                    setOptionalOthersLeaveData(sortedArray);
                    setOptionalUserLeaveData(sortedArray)
                    getDataAccordingToGroupByFilters(sortedArray);
                } else {
                    setOthersLeaveData({});
                    setOthersLeaveDataForCalendar([]);
                    setOptionalUserLeaveData([])
                    setOptionalOthersLeaveData([]);
                }
            } else {
                setOthersLeaveData({});
                setOthersLeaveDataForCalendar([]);
                setOptionalUserLeaveData([])
                setOptionalOthersLeaveData([]);
            }
            setIsLoading((prev) => ({ ...prev, dataLoading: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, dataLoading: false }));
            setOthersLeaveData({});
            setOthersLeaveDataForCalendar([]);
            setOptionalUserLeaveData([])
            setOptionalOthersLeaveData([]);
        }
    };

    const getDataAccordingToGroupByFilters = (data) => {
        if (data?.length) {
            if (groupBy === GROUP_FILTER["Status"]) {
                if (data?.length) {
                    let statusData = REQUEST_TYPE?.reduce((result, type) => {
                        let filteredTypeData = data?.filter((leave) => leave?.status === type);
                        result[type] = filteredTypeData;
                        return result;
                    }, {});

                    let accordian = []
                    if (Object.values(statusData)?.some(data => data?.length)) {
                        for (let key = 0; key < REQUEST_TYPE?.length; key++) {
                            if (statusData[REQUEST_TYPE[key]]?.length) {
                                accordian.push(REQUEST_TYPE[key])
                                break;
                            }
                        }
                    }
                    setOpenAccordianList(accordian);
                    setOthersLeaveData(statusData);
                    setTimeout(() => {
                        setIsLoading(prev => ({ ...prev, dataLoading: false }));
                    }, 1000);
                } else {
                    setOthersLeaveData({})
                    setOpenAccordianList([])
                    setIsLoading(prev => ({ ...prev, dataLoading: false }));
                }
            } else if (groupBy === GROUP_FILTER["WFH Date"]) {
                let leaveDateArray = [];
                let organizedLeaves = {};

                if (status === Object.keys(OTHERS_LEAVE_FILTER)[0]) {
                    leaveDateArray = [
                        "Next 7 days",
                        "Next 30 days",
                        "More than 30 days",
                    ];
                    organizedLeaves = {
                        "Next 7 days": [],
                        "Next 30 days": [],
                        "More than 30 days": [],
                    };
                } else {
                    leaveDateArray = [
                        "Past WFH",
                    ];
                    organizedLeaves = {
                        "Past WFH": [],
                    };
                }

                let filteredData = [...data]
                if (filterStatus !== Object.values(STATUS_BY_FILTER)[0]) filteredData = data?.filter((leaveData) => leaveData?.status === filterStatus.toLocaleLowerCase())

                leaveDateArray?.map((key) => {
                    filteredData?.map((userData) => {
                        const filteredLeaves = userData?.WfhDays?.filter((leave) => {
                            if (key === "Past WFH") {
                                return isInPastDays(leave?.date);
                            } else if (key === "Next 7 days") {
                                return isWithinNext7Days(leave?.date);
                            } else if (key === "Next 30 days") {
                                return isWithinNext30Days(leave?.date);
                            } else if (key === "More than 30 days") {
                                return isMoreThan30Days(leave?.date);
                            }
                        });
                        if (filteredLeaves?.length > 0) {
                            organizedLeaves[key].push({
                                ...userData,
                                leaveData: filteredLeaves,
                            });
                        }
                    });
                });

                if (Object.values(organizedLeaves)?.some(data => data?.length)) {
                    let keyArray = [
                        "Next 7 days",
                        "Next 30 days",
                        "More than 30 days",
                    ];
                    let accordian = [];
                    if (status === Object.keys(OTHERS_LEAVE_FILTER)[0]) {
                        for (let key = 0; key < keyArray?.length; key++) {
                            if (!organizedLeaves[keyArray[key]]?.length) {
                                accordian.push(keyArray[key])
                            } else {
                                accordian.push(keyArray[key])
                                break;
                            }
                        }
                    } else {
                        accordian = ['Past WFH'];
                    }
                    setOpenAccordianList(accordian);
                } else {
                    setOpenAccordianList([]);
                }
                setOthersLeaveData(organizedLeaves);
                setTimeout(() => {
                    setIsLoading(prev => ({ ...prev, dataLoading: false }));
                }, 1000);
            } else if (
                [
                    GROUP_FILTER["User Role"],
                    GROUP_FILTER["WFH Type"],
                    GROUP_FILTER["Work Type"],
                ].includes(groupBy)
            ) {
                let keyToFilter =
                    groupBy === "User Role"
                        ? "User?.OrganizationUserRole?.name"
                        : groupBy === "WFH Type"
                            ? "work_type"
                            : "User?.OrganizationWorkTypeSetting?.name";

                let filteredData = [...data]
                if (filterStatus !== Object.values(STATUS_BY_FILTER)[0])
                    filteredData = data?.filter((leaveData) => leaveData?.status === filterStatus.toLocaleLowerCase())

                let departmentsData = filteredData?.reduce((result, item) => {
                    let _key = keyToFilter.split("?.").reduce((acc, part) => acc?.[part], item);
                    if (!result[_key]) {
                        result[_key] = [];
                    }
                    result[_key].push(item);
                    return result;
                }, {});
                setOthersLeaveData(departmentsData);
                if (Object.keys(departmentsData)?.length) {
                    setOpenAccordianList([Object.keys(departmentsData)?.[0]])
                }
                setTimeout(() => {
                    setIsLoading(prev => ({ ...prev, dataLoading: false }));
                }, 1000);
            } else if (GROUP_FILTER["User"] === groupBy) {
                let allList = { ...allUserList };
                data?.map((userWfhList) => {
                    if (!Object.keys(allList)?.includes(userWfhList?.User?.name))
                        allList[userWfhList?.User?.name] = userWfhList?.User?.id
                })
                setAllUserList(allList)
                let filteredData = [...data];
                if (filterStatus !== Object.values(STATUS_BY_FILTER)[0])
                    filteredData = data?.filter((leaveData) => leaveData?.status === filterStatus.toLocaleLowerCase())

                let userLeaveData = filteredData?.reduce((result, item) => {
                    const _key = item?.User?.name;
                    if (!result[_key]) {
                        result[_key] = [];
                    }
                    result[_key].push(item);
                    return result;
                }, {});
                if (Object.keys(userLeaveData)?.length) {
                    if (!Object?.keys(userFilter)?.length) {
                        let keyObj = {};
                        Object.keys(userLeaveData)?.map((key) => {
                            keyObj[key] = status;
                        })
                        setUserFilter(keyObj);
                    } else {
                        const updatedResultKey = Object.keys(userLeaveData);
                        const prefilterKey = Object.keys(userFilter);
                        let isKeyDiff = updatedResultKey?.length !== prefilterKey?.length || !updatedResultKey?.every(key => prefilterKey?.includes(key))
                        if (isKeyDiff) {
                            const keyObj = {};

                            const allKeys = new Set([...Object.keys(userLeaveData), ...Object.keys(userFilter)]);
                            allKeys.forEach(key => {
                                if (key in userLeaveData && key in userFilter) {
                                    keyObj[key] = userFilter[key];
                                } else if (key in userLeaveData) {
                                    keyObj[key] = status;
                                } else {
                                    keyObj[key] = userFilter[key];
                                    userLeaveData[key] = [];
                                }
                            });
                            setUserFilter(keyObj);
                        }
                    }
                    setOpenAccordianList([Object.keys(userLeaveData)?.[0]])
                }
                setOthersLeaveData(userLeaveData);
                setTimeout(() => {
                    setIsLoading(prev => ({ ...prev, dataLoading: false }));
                }, 1000);
            }
        }
    };

    const handleChange = (event) => {
        setQueryParams("groupBy", event.target.value);
        if (event.target.value === GROUP_FILTER?.Status)
            deleteQueryParams("filterStatus")
        else
            setQueryParams("filterStatus", filterStatus);
        if (event?.target?.value !== GROUP_FILTER["User"]) {
            setUserFilter({});
            if (optionalOthersLeaveData?.length) setOptionalUserLeaveData(optionalOthersLeaveData);
        }
    };

    const handleFilterChange = (e) => {
        setQueryParams("status", e.target.name);

        if (groupBy === GROUP_FILTER["User"] && Object.keys(userFilter)?.length) {
            let filterData = { ...userFilter };
            Object.keys(filterData).forEach(key => {
                filterData[key] = e.target.name;
            });
            setUserFilter(filterData);
        }
    };

    const handleUserFilterChange = (e, userName, userId) => {
        setFilterUserName(userName);
        setUserFilter(prev => ({ ...prev, [userName]: e.target.value }))
        getOneUserLeaveData(e.target.value, userName, userId)
    }

    const getUserId = (userName) => {
        return allUserList?.[userName];
    }

    const getOneUserLeaveData = async (status, userName, userId) => {
        try {
            setIsLoading((prev) => ({ ...prev, singleUserDataLoading: true }));
            const response = await axiosInstance.get(API.getOneUserWfhList(currentUser.id, userId, status));
            let updatedUserLeaveData = [];
            if (response?.status === 200)
                updatedUserLeaveData = response?.data?.data;

            setOthersLeaveData((prev) => ({
                ...prev,
                [userName]: filterStatus === Object.values(STATUS_BY_FILTER)[0] ? updatedUserLeaveData : updatedUserLeaveData?.filter((data) => data?.status === filterStatus?.toLocaleLowerCase())
            }));
            setOptionalUserLeaveData((prev) => [...prev?.filter(userLeaveData => userLeaveData?.User?.id !== userId), ...updatedUserLeaveData])
            setIsLoading((prev) => ({ ...prev, singleUserDataLoading: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, singleUserDataLoading: false }));
        }
    }

    const handleStatusChange = (e) => {
        setQueryParams("filterStatus", e.target.value);
    }

    const handleLeaveClick = (leave) => {
        let { User, id } = leave;
        navigate(`${URLS.OtherWfhApplicationDetails}/${User?.id}/${id}`);
    };

    const hasAttachmentInList = () => {
        if (optionalOthersLeaveData?.length) {
            return optionalOthersLeaveData?.some(leave => leave?.attachment_references?.length);
        }
    }

    const RequestedLeaveType = (id) => specialRequestList?.find(value => value?.id === id)?.name;

    return (
        <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }}>
            <Stack spacing={{ xs: 2, lg: 3 }}>
                <Grid container justifyContent="space-between" alignItems="flex-end" width={'100%'}>
                    <Grid item display="flex" alignItems="flex-end">
                        <FormControl
                            variant="standard"
                            sx={{ mr: { xs: 2, lg: 3, xl: 4 }, minWidth: { xs: 160, md: 180 }, maxWidth: { xs: 160, md: 180 } }}
                        >
                            <InputLabel htmlFor={getInputId("group-by", 0)}>
                                Group By
                            </InputLabel>
                            <Select
                                id="group-by"
                                inputProps={{ id: getInputId("group-by", 0) }}
                                value={groupBy || ""}
                                onChange={(e) => handleChange(e)}
                                label="Group By"
                            >
                                {Object.values(GROUP_FILTER)?.map((name, index) => (
                                    <MenuItem key={index} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {groupBy !== GROUP_FILTER?.Status ? <FormControl
                            variant="standard"
                            sx={{ mr: { xs: 2, lg: 3, xl: 4 }, minWidth: { xs: 160, md: 180 }, maxWidth: { xs: 160, md: 180 } }}
                        >
                            <InputLabel htmlFor={getInputId("filter-by", 0)}>
                                Filter By Status
                            </InputLabel>
                            <Select
                                id="filter-by"
                                inputProps={{ id: getInputId("filter-by", 0) }}
                                value={filterStatus || ""}
                                onChange={(e) => handleStatusChange(e)}
                                label="Filter By Status"
                            >
                                {Object.values(STATUS_BY_FILTER)?.map((name, index) => (
                                    <MenuItem key={index} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> : null}
                    </Grid>
                    <Grid item display="flex" justifyContent={"flex-end"}>
                        <Stack spacing={2} direction="row">
                            <ButtonGroup
                                variant="outlined"
                                aria-label="Basic button group"
                                color="secondary"
                                fontSize={14}
                            >
                                {Object.entries(OTHERS_LEAVE_FILTER)?.map((filter, index) => (
                                    <Button
                                        key={index}
                                        variant="outlined"
                                        color={status === filter[0] ? "primary" : "secondary"}
                                        name={filter[0]}
                                        sx={{
                                            textTransform: "none",
                                            background: `${status === filter[0] ? "rgba(4, 127, 224, 0.1)" : "none"
                                                }`,
                                            borderColor: `${status === filter[0] ? "#047FE0 !important" : "none"
                                                }`,
                                            position: "relative",
                                            zIndex: `${status === filter[0] ? "1" : "none"}`,
                                            paddingLeft: { xs: '10px', md: '15px' },
                                            paddingRight: { xs: '10px', md: '15px' }
                                        }}
                                        onClick={(e) => handleFilterChange(e)}
                                    >
                                        {filter[1]}
                                    </Button>
                                ))}
                            </ButtonGroup>
                            {/* <ButtonGroup
                                variant="outlined"
                                aria-label="Basic button group"
                                color="secondary"
                                fontSize={14}
                            >
                                <Button sx={{
                                    textTransform: "none",
                                    background: view === "list" ? "rgba(4, 127, 224, 0.1)" : "",
                                    borderColor: view === "list" ? "#047FE0 !important" : "",
                                    color: view === "list" ? "primary.main" : "",
                                    paddingLeft: { xs: '8px', md: '15px' },
                                    paddingRight: { xs: '8px', md: '15px' }
                                }}
                                    onClick={() => setQueryParams("view", "list")}
                                >
                                    <i style={{ height: 20, width: 20 }}>
                                        <ListAltRoundedIcon sx={{ fontSize: 20 }} />
                                    </i>
                                </Button>
                                <Button sx={{
                                    textTransform: "none",
                                    background: view === "calendar" ? "rgba(4, 127, 224, 0.1)" : "",
                                    borderColor: view === "calendar" ? "#047FE0 !important" : "",
                                    color: view === "calendar" ? "primary.main" : "",
                                    paddingLeft: { xs: '8px', md: '15px' },
                                    paddingRight: { xs: '8px', md: '15px' }
                                }}
                                    disabled={true}
                                    onClick={() => setQueryParams("view", "calendar")}
                                >
                                    <i style={{ height: 20, width: 20 }}>
                                        <CalendarTodayRoundedIcon sx={{ fontSize: 20 }} />
                                    </i>
                                </Button>
                            </ButtonGroup> */}
                        </Stack>
                    </Grid>
                </Grid>
                {isLoading?.dataLoading ?
                    <CircularLoader height="500px" /> :
                    view === "calendar" ? (
                        <WFHCalendarView leaveDataList={othersLeaveDataForCalendar} />
                    ) : (<Grid container>
                        <Grid item sx={{ width: "100%" }}>
                            <Stack spacing={1}>
                                {(!!othersLeaveData && Object?.keys(othersLeaveData)?.length > 0)
                                    ? Object?.entries(othersLeaveData)?.map((otherLeave, index) => (
                                        <Accordion
                                            key={index}
                                            expanded={openAccordianList?.includes(otherLeave[0])}
                                            sx={{
                                                width: "100%",
                                                background: "#EBEFF3",
                                                border: "1px solid rgba(0, 0, 0, 0.08)",
                                                boxShadow: "none",
                                                borderRadius: "5px",
                                                margin: "8px 0px 0px 0px !important",
                                                "&:first-of-type": {
                                                    margin: "0px !important",
                                                },
                                                "&:before": {
                                                    display: "none",
                                                },
                                                ".MuiAccordionSummary-root": {
                                                    minHeight: "42px",
                                                    flexDirection: "row-reverse",
                                                },
                                                ".MuiAccordionSummary-root.Mui-expanded": {
                                                    minHeight: "42px !important",
                                                },
                                                ".MuiAccordionSummary-content": {
                                                    marginBottom: "13px",
                                                    marginLeft: "8px",
                                                },
                                                ".MuiAccordionSummary-content.Mui-expanded": {
                                                    marginLeft: '8px',
                                                    marginBottom: "13px",
                                                    marginTop: "12px",
                                                },
                                                ".MuiAccordionDetails-root": {
                                                    paddingTop: "0px",
                                                },
                                                ".MuiAccordion-region> div": {
                                                    paddingBottom: "8px",
                                                    marginTop: "-8px",
                                                },
                                                '& .MuiAccordionSummaryExpandIconWrapper': {
                                                    transform: 'rotate(-90deg)',
                                                },
                                                '& .MuiAccordionSummaryExpandIconWrapper.MuiExpanded': {
                                                    transform: 'rotate(0deg)',
                                                }
                                            }}
                                            elevation={1}
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <i
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                    >
                                                        {ICONS.ChevronLineSmall}
                                                    </i>
                                                }
                                                aria-controls="panel2-content"
                                                id="panel2-header"
                                                onClick={() => setOpenAccordianList((prev) => prev?.includes(otherLeave[0]) ? prev?.filter(list => list !== otherLeave[0]) : [...prev, otherLeave[0]])}
                                            >
                                                <Typography
                                                    color="dark.800"
                                                    fontSize={16}
                                                    lineHeight="24px"
                                                    fontWeight={500}
                                                >
                                                    {titleCase(otherLeave[0])}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {GROUP_FILTER["User"] === groupBy ?
                                                    <Grid item>
                                                        <ButtonGroup
                                                            variant="outlined"
                                                            aria-label="Basic button group"
                                                            color="secondary"
                                                            fontSize={14}
                                                            sx={{
                                                                mr: 2,
                                                                overflowX: 'hidden',
                                                                position: 'absolute',
                                                                right: 0,
                                                                top: 6,
                                                                background: 'white',
                                                            }}
                                                        >
                                                            {Object.entries(OTHERS_LEAVE_FILTER)?.map((filter, index) => (
                                                                <Button
                                                                    key={index}
                                                                    variant="outlined"
                                                                    color={userFilter[otherLeave[0]] === filter[0] ? "primary" : "secondary"}
                                                                    size="small"
                                                                    name={filter[0]}
                                                                    value={filter[0]}
                                                                    disabled={isLoading?.singleUserDataLoading}
                                                                    sx={{
                                                                        textTransform: "none",
                                                                        background: `${userFilter[otherLeave[0]] === filter[0] ? "rgba(4, 127, 224, 0.1)" : "none"
                                                                            }`,
                                                                        borderColor: `${userFilter[otherLeave[0]] === filter[0] ? "primary !important" : "none"
                                                                            }`,
                                                                        position: "relative",
                                                                        zIndex: `${userFilter[otherLeave[0]] === filter[0] ? "1" : "none"}`,
                                                                        paddingLeft: { xs: '10px', md: '15px' },
                                                                        paddingRight: { xs: '10px', md: '15px' }
                                                                    }}
                                                                    // onClick={(e) => handleUserFilterChange(e, otherLeave[0], otherLeave[1]?.[0]?.user_id)}
                                                                    onClick={(e) => (!!otherLeave?.length && otherLeave[1]?.length) ?
                                                                        handleUserFilterChange(e, otherLeave[0], otherLeave[1]?.[0]?.User?.id) :
                                                                        handleUserFilterChange(e, otherLeave[0], getUserId(otherLeave[0]))
                                                                    }
                                                                >
                                                                    {filter[1]}
                                                                </Button>
                                                            ))}
                                                        </ButtonGroup>
                                                    </Grid> :
                                                    null}
                                                <TableContainer>
                                                    <Table
                                                        aria-label="simple table"
                                                        sx={{
                                                            borderCollapse: "separate",
                                                            borderSpacing: "0px 8px",
                                                        }}
                                                    >
                                                        {(isLoading?.singleUserDataLoading && filterUserName === otherLeave[0]) ?
                                                            <CircularLoader height="100px" /> :
                                                            <TableBody>
                                                                {(!!otherLeave?.length && otherLeave[1]?.length) ?
                                                                    (
                                                                        <>
                                                                            {otherLeave[1]?.map((userData, index) => (
                                                                                <TableRow
                                                                                    key={index}
                                                                                    sx={{
                                                                                        td: {
                                                                                            border: 0,
                                                                                            bgcolor: "white",
                                                                                            padding: { xs: "8px 8px", md: "8px 16px" },
                                                                                        },
                                                                                        'td:first-of-type': {
                                                                                            borderRadius: "4px 0px 0px 4px",
                                                                                        },
                                                                                        'td:last-child': {
                                                                                            borderRadius: "0px 4px 4px 0px",
                                                                                        },
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => handleLeaveClick(userData)}
                                                                                >
                                                                                    <TableCell
                                                                                        sx={{
                                                                                            width: '220px',
                                                                                            minWidth: '220px',
                                                                                            maxWidth: '220px'
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            item
                                                                                            display="flex"
                                                                                            alignItems="center"
                                                                                        >
                                                                                            <BackgroundLetterAvatars
                                                                                                user={userData?.User}
                                                                                                src={userData?.User?.profile_img}
                                                                                                sx={{ width: 32, height: 32 }}
                                                                                            />
                                                                                            <Box
                                                                                                ml={1}
                                                                                                display="flex"
                                                                                                flexWrap="wrap"
                                                                                                overflow={"hidden"}
                                                                                            >
                                                                                                <Typography
                                                                                                    variant="body1"
                                                                                                    color="secondary"
                                                                                                    fontSize={14}
                                                                                                    fontWeight={500}
                                                                                                    sx={{
                                                                                                        lineHeight: "24px",
                                                                                                        letterSpacing: "0.17px",
                                                                                                        marginBottom: "2px",
                                                                                                        display: "block",
                                                                                                        width: "100%",
                                                                                                        whiteSpace: "nowrap",
                                                                                                        overflow: "hidden",
                                                                                                        textOverflow: "ellipsis"
                                                                                                    }}
                                                                                                >
                                                                                                    {getFullName(userData?.User)}
                                                                                                </Typography>
                                                                                                {userData?.User
                                                                                                    ?.is_dedicated_developer ? (
                                                                                                    <Typography
                                                                                                        color="#9747FF"
                                                                                                        fontSize={12}
                                                                                                        fontWeight={700}
                                                                                                        sx={{
                                                                                                            display: "inline-block",
                                                                                                            mr: 0.5,
                                                                                                        }}
                                                                                                    >
                                                                                                        Dedicated
                                                                                                    </Typography>
                                                                                                ) : (
                                                                                                    <Typography
                                                                                                        color="primary"
                                                                                                        fontSize={12}
                                                                                                        fontWeight={700}
                                                                                                        sx={{
                                                                                                            display: "inline-block",
                                                                                                            mr: 0.5,
                                                                                                        }}
                                                                                                    >
                                                                                                        {userData?.User?.OrganizationWorkTypeSetting?.name}
                                                                                                    </Typography>
                                                                                                )}
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        sx={{
                                                                                            width: '150px',
                                                                                            minWidth: '150px',
                                                                                            maxWidth: '150px'
                                                                                        }}
                                                                                    >
                                                                                        <Typography
                                                                                            variant="body2"
                                                                                            color="dark.800"
                                                                                            lineHeight="14px"
                                                                                            letterSpacing="0.17px"
                                                                                            fontWeight={500}
                                                                                            mb="6px"
                                                                                            component={"span"}
                                                                                        >
                                                                                            {getApplicationNumber(userData?.application_num)}
                                                                                            <Chip
                                                                                                label={getFirstLetterOfString(RequestedLeaveType(userData?.static_id))}
                                                                                                color="secondary"
                                                                                                size="small"
                                                                                                variant="filled"
                                                                                                sx={{ marginLeft: '4px' }}
                                                                                            />
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            color="dark.500"
                                                                                            fontSize={10}
                                                                                            lineHeight="15px"
                                                                                            letterSpacing="0.17px"
                                                                                            fontWeight={500}
                                                                                        >
                                                                                            {calculateLeaveCount(
                                                                                                userData?.WfhDays
                                                                                            )} Days
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        sx={{
                                                                                            minWidth: '250px',
                                                                                        }}
                                                                                    >
                                                                                        <Typography
                                                                                            color="dark.500"
                                                                                            fontSize={10}
                                                                                            lineHeight="15px"
                                                                                            letterSpacing="0.17px"
                                                                                            fontWeight={500}
                                                                                            mb="6px"
                                                                                        >
                                                                                            Date
                                                                                        </Typography>
                                                                                        <Grid
                                                                                            item
                                                                                            display="flex"
                                                                                            alignItems="center"
                                                                                        >
                                                                                            <Typography
                                                                                                variant="body2"
                                                                                                color="dark.800"
                                                                                                lineHeight="14px"
                                                                                                letterSpacing="0.17px"
                                                                                                fontWeight={500}
                                                                                                mr="6px"
                                                                                                whiteSpace="nowrap"
                                                                                            >
                                                                                                {getLeaveDates(getLeaveDateArray(userData?.WfhDays, 'date'))}
                                                                                            </Typography>
                                                                                            <HtmlTooltip
                                                                                                title={
                                                                                                    <React.Fragment>
                                                                                                        <Typography color="white" mb="10px">
                                                                                                            {getFirstLetterOfString(RequestedLeaveType(userData?.static_id))} -{" "}
                                                                                                            {calculateLeaveCount(
                                                                                                                userData?.WfhDays
                                                                                                            )}{" "}
                                                                                                            {calculateLeaveCount(
                                                                                                                userData?.WfhDays
                                                                                                            ) <= 1
                                                                                                                ? "Day"
                                                                                                                : "Days"}
                                                                                                        </Typography>
                                                                                                        <List
                                                                                                            sx={{
                                                                                                                padding: 0,
                                                                                                                li: { padding: 0 },
                                                                                                                "li span": {
                                                                                                                    color: "white",
                                                                                                                    fontSize: 12,
                                                                                                                    fontWeight: 500,
                                                                                                                    lineHeight: "19px",
                                                                                                                },
                                                                                                            }}
                                                                                                        >
                                                                                                            {getAllLeaveDates(
                                                                                                                userData?.WfhDays,
                                                                                                                true
                                                                                                            )?.map((leaveDate, index) => (
                                                                                                                <ListItem key={index}>
                                                                                                                    <ListItemText>
                                                                                                                        {leaveDate}
                                                                                                                    </ListItemText>
                                                                                                                </ListItem>
                                                                                                            ))}
                                                                                                        </List>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                            >
                                                                                                <i
                                                                                                    style={{
                                                                                                        height: 18,
                                                                                                        maxWidth: 18,
                                                                                                        flex: "0 0 18px",
                                                                                                    }}
                                                                                                >
                                                                                                    <InfoRoundedIcon
                                                                                                        color="secondary"
                                                                                                        sx={{ fontSize: 18 }}
                                                                                                    />
                                                                                                </i>
                                                                                            </HtmlTooltip>
                                                                                        </Grid>
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        sx={{
                                                                                            width: '200px',
                                                                                            minWidth: '200px',
                                                                                            maxWidth: '200px'
                                                                                        }}
                                                                                    >
                                                                                        <Typography
                                                                                            color="dark.500"
                                                                                            fontSize={10}
                                                                                            lineHeight="15px"
                                                                                            letterSpacing="0.17px"
                                                                                            fontWeight={500}
                                                                                            mb="6px"
                                                                                        >
                                                                                            Applied on{" "}
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            variant="body2"
                                                                                            color="dark.800"
                                                                                            lineHeight="14px"
                                                                                            letterSpacing="0.17px"
                                                                                            fontWeight={500}
                                                                                        >
                                                                                            {getApplyDateTime((userData?.createdAt || userData?.apply_date), "DD/MM/YYYY")}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    {hasAttachmentInList() ?
                                                                                        <TableCell
                                                                                            sx={{
                                                                                                width: '70px',
                                                                                                minWidth: '70px',
                                                                                                maxWidth: '70px',
                                                                                            }}
                                                                                        >
                                                                                            {userData?.attachment_references?.length ? <Box
                                                                                                sx={{
                                                                                                    alignItems: 'center',
                                                                                                    color: "secondary",
                                                                                                    display: "flex",
                                                                                                }}
                                                                                            >
                                                                                                <Box
                                                                                                    sx={{ alignItems: 'center' }}
                                                                                                >
                                                                                                    <Typography
                                                                                                        variant="body1"
                                                                                                        color="secondary"
                                                                                                        fontSize={14}
                                                                                                        fontWeight={500}
                                                                                                        sx={{
                                                                                                            lineHeight: "24px",
                                                                                                            letterSpacing: "0.17px",
                                                                                                            marginBottom: "2px",
                                                                                                            display: "block",
                                                                                                            width: "100%",
                                                                                                        }}
                                                                                                    >
                                                                                                        <i
                                                                                                            style={{
                                                                                                                height: "18px",
                                                                                                                width: "18px",
                                                                                                                display: "flex",
                                                                                                            }}
                                                                                                        >
                                                                                                            {ICONS.Attachment}
                                                                                                        </i>
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                                <Box
                                                                                                    sx={{ alignItems: 'center' }}
                                                                                                >
                                                                                                    <Typography
                                                                                                        variant="body1"
                                                                                                        color="secondary"
                                                                                                        fontSize={14}
                                                                                                        fontWeight={500}
                                                                                                        sx={{
                                                                                                            lineHeight: "24px",
                                                                                                            letterSpacing: "0.17px",
                                                                                                            marginBottom: "2px",
                                                                                                            display: "block",
                                                                                                            width: "100%",
                                                                                                        }}
                                                                                                    >
                                                                                                        {userData?.attachment_references?.length}
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Box> : null}
                                                                                        </TableCell>
                                                                                        : null}
                                                                                    <TableCell
                                                                                        sx={{
                                                                                            width: '120px',
                                                                                            minWidth: '120px',
                                                                                            maxWidth: '120px'
                                                                                        }}
                                                                                    >
                                                                                        {userData?.status !== LEAVE_STATUS.Pending ?
                                                                                            <HtmlTooltip
                                                                                                title={
                                                                                                    <React.Fragment>
                                                                                                        <Typography fontSize={14} fontWeight={600} lineHeight={"24px"} letterSpacing={"0.15px"} display={"inline-block"} mr={0.5} width={"100%"}>
                                                                                                            {showInformationAuthority(userData?.processed_by) || "-"}
                                                                                                        </Typography>
                                                                                                        <Typography
                                                                                                            color="text.white" fontSize={12} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.15px"} width={"100%"}>
                                                                                                            at {userData?.process_date ? `${getApplyDateTime(userData?.process_date, "hh:mm A")}, on ${getApplyDateTime(userData?.process_date, "DD/MM/YYYY (dddd)")}` : "-"}
                                                                                                        </Typography>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                            >
                                                                                                <Box display={"inline-block"}>
                                                                                                    {getLeaveStatus(userData?.status, "chip")}
                                                                                                </Box>
                                                                                            </HtmlTooltip>
                                                                                            :
                                                                                            <Box display={"inline-block"}>
                                                                                                {getLeaveStatus(userData?.status, "chip")}
                                                                                            </Box>
                                                                                        }
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </>
                                                                    ) :
                                                                    <>
                                                                        <TableRow
                                                                            key={index}
                                                                            sx={{
                                                                                td: {
                                                                                    border: 0,
                                                                                    bgcolor: "white",
                                                                                    textAlign: 'center',
                                                                                    padding: { xs: "8px 8px", md: "8px 16px" },
                                                                                },
                                                                                'td:first-of-type': {
                                                                                    borderRadius: "4px 4px 4px 4px",
                                                                                },
                                                                                cursor: 'default'
                                                                            }}
                                                                        >
                                                                            <TableCell sx={{ height: '60px' }}>
                                                                                <Typography
                                                                                    color="dark.800"
                                                                                    fontSize={14}
                                                                                    lineHeight="24px"
                                                                                    fontWeight={500}
                                                                                >
                                                                                    No Request Available
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                }
                                                            </TableBody>
                                                        }
                                                    </Table>
                                                </TableContainer>
                                            </AccordionDetails>
                                        </Accordion>
                                    )) :
                                    (!isLoading?.dataLoading && Object?.keys(othersLeaveData)?.length === 0) ?
                                        <EmptyPage
                                            isButtonShow={false}
                                            title="No request available"
                                            showSideBar={false}
                                        />
                                        : null
                                }
                            </Stack>
                        </Grid>
                    </Grid>)
                }
            </Stack>
        </Box>
    );
};

export default OthersWFHRequests;