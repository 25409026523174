import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import React, { useState } from 'react'
import dayjs from 'dayjs';
import moment from 'moment';
import { titleCase } from '../../utils';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Typography } from '@mui/material';

const TimePickerClock = ({ isHideAmPm = false, value, onChange, label, onOpen = () => { }, onClose = () => { }, error = null, isMaxTime = false, placeholder = "hh:mm a", formatType = "HH:mm", is12Hour = false, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);

  let getMaxTime = () => {
    let value = moment(new Date()).format("hh:mm:ss");
    let [hh, mm, ss] = value.split(':').map(Number);
    return dayjs().set('hour', hh).set('minute', mm).set('second', ss);
  }

  const timeFormat = is12Hour ? "hh:mm A" : "HH:mm";

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoItem label={titleCase(label)}
      sx={{
        '.MuiTypography-body2': {
          fontSize: 12,
          fontWeight: 400,
          color: 'dark.600',
          width: "100%",
          letterSpacing: "0.15px",
          marginTop: "-3px",
          marginBottom: "2px",
        },
        '.MuiFormControl-root': {
          margin: '0',
        },
        'fieldset': {
          border: 'none',
        },
        '.MuiOutlinedInput-root': {
          padding: '0px',
        },
        'input': {
          width: "100%",
          height: 30,
          border: "none !important",
          boxShadow: "none !important",
          borderRadius: "0px",
          // paddingBottom: "6px",
          borderBottom:
            "1px solid rgba(0, 0, 0, 0.42) !important",
          margin: "0",
          position: "relative",
          background: "transparent",
          color: "dark.800",
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: "0.15px",
          padding: '0px',
          paddingLeft: '27px',
        },
        "input:hover": {
          borderBottomColor:
            "#333333 !important",
        },
        "input:focus": {
          borderBottomColor:
            "#047FE0 !important",
        },
      }}>
      {!isFocused && !value ? (
        <Typography
          fontSize={14}
          fontWeight={400}
          sx={{
            height: 30,
            color: 'rgba(0, 0, 0, 0.6)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            marginRight: '4px',
            paddingLeft: '25px',
            lineHeight: '24px',
            letterSpacing: '0.15px'
          }}
          onClick={() => setIsFocused(true)}>{placeholder}</Typography>
      ) :
        (<MobileTimePicker
          open={isFocused}
          value={value ? dayjs(value) : null}
          onChange={onChange}
          onOpen={() => {
            setIsFocused(true);
            onOpen();
          }}
          onClose={() => {
            setIsFocused(false);
            onClose();
          }}
          openTo="hours"
          maxTime={isMaxTime ? getMaxTime() : null}
          label={titleCase(label)}
          placeholder={placeholder}
          {...rest}
          format={timeFormat}
        />)}
      {!!error ? <Typography sx={{ color: "red", fontSize: "12px" }}>{error}</Typography> : null}
    </DemoItem>
  </LocalizationProvider>
}

export default TimePickerClock;