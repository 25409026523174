/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, MenuItem, Modal, Select, Stack, Tab, Tabs, Tooltip, tooltipClasses, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularLoader from "../../../../components/CircularLoader";
import LeaveTypeForm from './LeaveTypeForm';
import Button from '../../../../components/Button';
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import ChooseWorkTypeActivity from './ChooseWorkTypeActivity';
import { styled } from '@mui/styles';
import CreateNewActivity from './CreateNewActivity';
import { useAlert } from '../../../../hook/useAlert';
import useAuthentication from '../../../../hook/useAuthentication';
import { usePageTitle } from '../../../../hook/useTitle';
import { generateUniqueId, getFullName, getInputId, getMissingKeys, getYearObject, secondsToTime, sortArrayByKey, timeToHours, titleCase } from '../../../../utils';
import API from '../../../../axios/api';
import axiosInstance from '../../../../axios';
// import useQuery from '../../../../hook/useQuery';
import InformationModal from '../../../../components/InformationModal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useQuery from '../../../../hook/useQuery';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
    overflowX: 'hidden',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(0),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3F5C76',
    color: '#ffffff',
    maxWidth: 310,
    padding: 12,
  },
}));

const WorkTypeSettings = () => {
  const showAlert = useAlert();
  let initialConstantType = {
    work_type_settings: [],
    leaveTypes: [],
    specialRequestTypes: [],
  };
  let initialModalDetails = {
    isShow: false,
    isApplyForSelectedUser: false,
    user_ids: [],
    errorMsg: "",
    isShowUsersList: false,
    isSelectAllUser: true,
  };
  let initialLeaveTypeData = {
    id: null,
    leave_type_id: null,
    monthly_days: null,
    advance_days: null,
    max_leave_encashment: null,
  };
  const { getCurrentUser } = useAuthentication();
  let { getQueryParams } = useQuery();
  // let workTypeTabValue = parseInt(getQueryParams("workTypeTabValue")) || 0;
  let workTypeId = parseInt(getQueryParams("worktypeId")) || 0;
  const { setPageTitle } = usePageTitle();
  const currentUser = getCurrentUser();
  const userId = currentUser?.id;
  const org_year_id = currentUser?.organization?.work_type_settings?.[0]?.org_year_id;
  const organization_id = currentUser?.organization?.id;
  const [updateWorkTypeIndex, setUpdateWorkTypeIndex] = useState(null);
  const [currentOpenTabWorkType, setCurrentOpenTabWorkType] = useState(null);
  const [formData, setFormData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [loading, setLoading] = useState({
    formSubmitting: false,
    pageLoading: true,
    updateActivity: false,
    yearLoading: true,
    leaveTypeLoading: false,
    users: false,
  });
  const [constantType, setConstantType] = useState(initialConstantType);
  const [errors, setErrors] = useState([]);
  const [organizationUserList, setOrganizationUserList] = useState([]);
  const [modalDetails, setModalDetails] = useState(initialModalDetails);
  const [isOpen, setIsOpen] = useState(false);
  const [isNewActivityOpen, setIsNewActivityOpen] = useState(false);
  const [currentWorkTypeData, setCurrentWorkTypeData] = useState({});
  const [allActivity, setAllActivity] = useState([]);
  const [currentWorkTypeActivity, setCurrentWorkTypeActivity] = useState([]);
  const [otherWorkTypeActivity, setOtherWorkTypeActivity] = useState([]);
  const [inputActivityValue, setInputActivityValue] = useState('');
  const [allSelectedActivity, setAllSelectedActivity] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [userListByRoleData, setUserListByRoleData] = useState([]);
  const [isShowWorktypeRemoveModal, setIsShowWorktypeRemoveModal] = useState("");
  const [workTypeTabValue, setWorkTypeTabValue] = useState(0);
  const [firstTimePageLoad, setFirstTimePageLoad] = useState(true);

  setPageTitle("Leaves Settings");

  useEffect(() => {
    if (formData?.work_type_settings?.length && workTypeTabValue?.toString()?.length) {
      let workType = formData?.work_type_settings?.find((workType) => {
        return workType?.name?.toLowerCase() === constantType?.work_type_settings[workTypeTabValue]?.name?.toLowerCase()
      });
      if (workType?.id && workType?.active && firstTimePageLoad) {
        getUserListByRole(workType?.id);
        setFirstTimePageLoad(false)
      }
      setCurrentWorkTypeData({ ...workType, isChecked: workType?.active } || formData?.work_type_settings[0]);
    }
  }, [workTypeTabValue, formData?.work_type_settings]);

  useEffect(() => {
    if (allActivity?.length && Object.keys(currentWorkTypeData)?.length) {
      setCurrentWorkTypeActivity(currentWorkTypeData?.activities);
      setOtherWorkTypeActivity(allActivity?.filter((activityData) => !activityData?.org_work_type_ids?.includes(currentWorkTypeData?.id)))
    }
  }, [allActivity, currentWorkTypeData])

  useEffect(() => {
    getUserRolesData();
    getAllHolidayYears();
  }, [userId]);

  useEffect(() => {
    if (formData?.work_type_settings?.length && workTypeTabValue?.toString()?.length) {
      let workType = formData?.work_type_settings?.find((workType) => {
        return workType?.name?.toLowerCase() === constantType?.work_type_settings[workTypeTabValue]?.name?.toLowerCase()
      });

      if (workType?.id && workType?.active) {
        getUserListByRole(workType?.id);
      }
    }
  }, [workTypeTabValue])

  useEffect(() => {
    if (selectedYear?.toString()?.length) {
      setFirstTimePageLoad(true)
      fetchData()
    }
  }, [selectedYear])

  const getUserRolesData = async () => {
    try {
      const response = await axiosInstance.get(
        API.organisationUserRoles(currentUser?.organization_id)
      );
      if (response.status === 200) {
        setUserRoles(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUserListByRole = async (workTypeId) => {
    try {
      setLoading((prev) => ({ ...prev, users: true }));
      let urlEndpoint = API.organisationUsersListByRoles(
        currentUser?.organization_id,
        workTypeId
      );
      const response = await axiosInstance.get(urlEndpoint);
      if (response.status === 200) {
        setUserListByRoleData(response?.data?.result);
        setLoading((prev) => ({ ...prev, users: false }));
      } else {
        setUserListByRoleData([]);
        setLoading((prev) => ({ ...prev, users: false }));
      }
    } catch (error) {
      console.error(error);
      setUserListByRoleData([]);
      setLoading((prev) => ({ ...prev, users: false }));
    }
  };

  const getAllHolidayYears = async () => {
    try {
      setLoading((prev) => ({ ...prev, yearLoading: true }));
      const response = await axiosInstance.get(
        API.getAllHolidaysYear(currentUser.organization_id)
      );
      if (response.status === 200) {
        let sortedYear = response?.data?.result?.sort((a, b) =>
          a?.year?.toString()?.localeCompare(b?.year?.toString())
        );

        let removeFutureYear = sortedYear?.filter((yearData) => {
          return yearData?.year <= new Date().getFullYear();
        });
        setYears(removeFutureYear);
        let activeYear = sortedYear?.find(yearData => yearData?.active);
        setSelectedYear(activeYear?.id);
        // fetchData(activeYear);
        // getAllActivityData();
      }
      setLoading((prev) => ({ ...prev, yearLoading: false }));
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading((prev) => ({ ...prev, yearLoading: false }));
    }
  };

  const getAllLeaveSettingData = () => {
    fetchData();
    // getAllActivityData();
  }

  const getUserSettingData = async () => {
    try {
      setLoading((prev) => ({ ...prev, users: true }));
      const response = await axiosInstance.get(
        API.organisationUsersListByRoles(currentUser?.organization_id, currentWorkTypeData?.id))
      if (response.status === 200) {
        let sortedData = sortArrayByKey(response?.data?.result, 'asc', 'name');
        setOrganizationUserList(sortedData);
        setModalDetails((prev) => ({ ...prev, user_ids: sortedData?.map(item => item?.id) }));
        setLoading((prev) => ({ ...prev, users: false }))
      } else {
        setLoading((prev) => ({ ...prev, users: false }))
      }
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, users: false }))
    }
  };

  // const getAllActivityData = async () => {
  //   try {
  //     setLoading((prev) => ({ ...prev, activity: true }));
  //     const response = await axiosInstance.get(
  //       API.getAllActivity(currentUser?.organization_id)
  //     );
  //     if (response.status === 200) {
  //       setAllActivity(response?.data?.data);
  //     } else {
  //       showAlert(response?.data?.message, "error");
  //       setAllActivity([]);
  //     }
  //     setLoading((prev) => ({ ...prev, pageLoading: false }))
  //   } catch (error) {
  //     console.error(error);
  //     setLoading((prev) => ({ ...prev, pageLoading: false }))
  //   }
  // };

  const fetchData = async (activeYear = null, updatedIndex = null) => {
    try {
      const [
        leaveSettingResponse,
        workTypesResponse,
        leaveTypesResponse,
        specialRequestTypesResponse,
        activityDataResponse,
      ] = await Promise.all([
        currentUser?.organization_id
          ? axiosInstance.get(
            API.getWorkTypesByOrganisationYearId(currentUser.organization_id, activeYear?.id || selectedYear)
          )
          : null,
        axiosInstance.get(API.getOrganisationWorkTypes),
        axiosInstance.get(API.getLeaveTypes),
        axiosInstance.get(API.getLeaveSpecialRequests),
        axiosInstance.get(API.getAllActivity(currentUser?.organization_id))
      ]);

      if (workTypesResponse.status === 200) {
        let workTypeData = workTypesResponse?.data.data;
        setConstantType((prev) => ({
          ...prev,
          work_type_settings: workTypeData?.map(data => ({ ...data, isChecked: false, is_changed: false, canDelete: false, external: data?.external })),
        }));
      } else {
        showAlert(workTypesResponse.data?.message, "error");
      }

      if (leaveTypesResponse.status === 200) {
        setConstantType((prev) => ({
          ...prev,
          leaveTypes: leaveTypesResponse.data.data,
        }));
      } else {
        showAlert(leaveTypesResponse.data?.message, "error");
      }

      if (specialRequestTypesResponse.status === 200) {
        setConstantType((prev) => ({
          ...prev,
          specialRequestTypes: specialRequestTypesResponse.data.data,
        }));
      } else {
        showAlert(specialRequestTypesResponse.data?.message, "error");
      }

      if (activityDataResponse.status === 200) {
        setAllActivity(activityDataResponse?.data?.data);
      } else {
        showAlert(activityDataResponse?.data?.message, "error");
        setAllActivity([]);
      }

      if (leaveSettingResponse && leaveSettingResponse.status === 200) {
        setInitialData(leaveSettingResponse.data.data?.[0]);
        // setFormData(leaveSettingResponse.data.data);
        handleCreateDataWorkType(
          leaveSettingResponse.data.data[0],
          workTypesResponse.data.data,
          leaveTypesResponse.data.data,
          specialRequestTypesResponse.data.data,
          activityDataResponse?.data?.data,
          updatedIndex
        );
      } else if (leaveSettingResponse) {
        showAlert(leaveSettingResponse?.response?.data?.message, "error");
      }
      setLoading((prev) => ({ ...prev, pageLoading: false }));
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  const handleCreateDataWorkType = (
    initialData,
    workType,
    leaveType,
    specialRequest,
    allActivityData,
    updatedIndex = null,
  ) => {
    let constantWorkType = [];
    let errorObject = [];
    const updatedFormData = {
      ...initialData,
      leave_frequency: initialData?.leave_frequency || "monthly",
      isLeaveSettings: initialData?.isLeaveSettings || false,
      allow_special_credits: initialData?.allow_special_credits || false,
      enable_sys_generated_leave: initialData?.enable_sys_generated_leave || false,
      work_type_settings: initialData?.work_type_settings?.map((workTypeData, index) => {
        // let getInitialWorkTypeData = initialData?.work_type_settings?.find((value) => value?.name?.toLowerCase() === workTypeData?.name?.toLowerCase());
        const leaves = [];
        const specialRequests = [];
        let activities = allActivityData?.filter((activityData) => activityData?.org_work_type_ids?.includes(workTypeData?.id));

        constantWorkType.push({ id: workTypeData?.id, name: workTypeData?.name, is_changed: false, isChecked: workTypeData?.active, canDelete: !workTypeData?.Users?.length, isActive: workTypeData?.active, external: workTypeData?.external });
        workTypeData?.leave_type_settings?.filter((leave, index) => !!leave?.leave_type_id)?.forEach((leave) => {
          leaves.push({
            ...leave,
            monthly_days: initialData?.leave_frequency === "monthly" ? leave?.monthly_days * 12 : leave?.monthly_days,
            is_visible: true,
            unique_id: generateUniqueId(),
            is_changed: false,
          });
        });

        workTypeData?.special_requests?.filter((leave, index) => !isNaN(leave?.allotment))?.forEach((req) => {
          specialRequests.push({
            ...req,
            is_visible: true,
            unique_id: generateUniqueId(),
            is_changed: false,
          });
        });
        workTypeData['activities'] = activities?.slice();
        if (workTypeData?.active) {
          errorObject.push({
            min_working_hrs_half_day: !!workTypeData?.min_working_hrs_half_day ? "" : "Required",
            min_working_hrs_full_day: !!workTypeData?.min_working_hrs_full_day ? "" : "Required",
            id: workTypeData?.id,
            name: workTypeData?.name,
            max_monthly_leave_for_dd: !!workTypeData?.max_monthly_leave_for_dd ? "" : "Required",
            leave_type_settings: leaves?.map((value, index, array) => {
              return {
                id: value?.id,
                leave_type_id: !!value?.leave_type_id || (index !== 0 && array.length - 1) ? "" : "Required",
                monthly_days: !!value?.monthly_days || (index !== 0 && array.length - 1) ? "" : "Required",
                advance_days: value?.advance_days >= 0 || (index !== 0 && array.length - 1) ? "" : "Required",
                max_leave_encashment: value?.max_leave_encashment >= 0 || (index !== 0 && array.length - 1) ? "" : "Required",
                is_visible: true,
                unique_id: generateUniqueId(),
              }
            }),
            special_requests: specialRequests?.map((value, index, array) => {
              return {
                id: value?.id,
                name: !!value?.name || (index !== 0 && array.length - 1) ? "" : array?.length === 1 ? "" : "Required",
                allotment: !isNaN(value?.allotment) || (index !== 0 && array.length - 1) ? "" : array?.length === 1 ? "" : "Required",
                is_visible: true,
                unique_id: generateUniqueId(),
              }
            }),
          })
        } else {
          errorObject.push({
            id: workTypeData?.id,
            name: workTypeData?.name,
            max_monthly_leave_for_dd: "",
            leave_type_settings: leaves?.map((value, index, array) => {
              return {
                id: null,
                leave_type_id: "",
                monthly_days: "",
                advance_days: "",
                max_leave_encashment: "",
                is_visible: false,
                unique_id: generateUniqueId(),
              }
            }),
            special_requests: specialRequests?.map((value, index, array) => {
              return {
                id: null,
                name: "",
                allotment: "",
                is_visible: false,
                unique_id: generateUniqueId(),
              }
            }),
          })
        }

        if (leaves?.length < leaveType?.length && leaves?.length > 0) {
          leaves.push({
            ...initialLeaveTypeData,
            is_visible: true,
            unique_id: generateUniqueId(),
            is_changed: false,
          });
        } else if (leaves?.length === 0) {
          leaves.push({
            ...initialLeaveTypeData,
            is_visible: true,
            unique_id: generateUniqueId(),
            is_changed: false,
          });
        }
        if (specialRequests?.length < specialRequest?.length && specialRequests?.length > 0) {
          specialRequests.push({
            id: null,
            name: "",
            allotment: "",
            is_visible: true,
            unique_id: generateUniqueId(),
            is_initial: true,
            is_changed: false,
          });
        } else if (specialRequests?.length === 0) {
          specialRequests.push({
            id: null,
            name: "",
            allotment: "",
            is_visible: true,
            unique_id: generateUniqueId(),
            is_initial: true,
            is_changed: false,
          });
        }

        let finalWorkTypeData = {
          ...workTypeData,
          leave_type_settings: leaves,
          special_requests: sortArrayByKey(specialRequests, "asc", "id"),
          activities: sortArrayByKey(workTypeData?.activities, "asc", "title") ?? [],
          createdAt: workTypeData?.createdAt,
          min_working_hrs_half_day: workTypeData?.min_working_hrs_half_day,
          min_working_hrs_full_day: workTypeData?.min_working_hrs_full_day,
          is_changed: false
        }
        return finalWorkTypeData;

      }),
    };
    errorObject.push({
      "max_absent_allowed": "",
    });
    errorObject.push({
      "renewal_month": "",
    });
    errorObject.push({
      "work_type_settings": "",
    });
    setErrors(errorObject);

    let sortedWorkType = sortArrayByKey(updatedFormData.work_type_settings, "asc", "createdAt");
    updatedFormData.work_type_settings = sortedWorkType;
    const orderArray = sortedWorkType?.map((value) => value?.name);
    const sortOrder = Object.fromEntries(orderArray.map((name, index) => [name, index]));
    // let updatedOrder = [...constantWorkType].sort((a, b) => sortOrder[a.name] - sortOrder[b.name]);
    let updatedOrder = [...constantWorkType].sort((a, b) => {
      if (a.isChecked === false) return 1;
      if (b.isChecked === false) return -1;
      return (sortOrder[a.name] || Infinity) - (sortOrder[b.name] || Infinity);
    });
    setFormData(updatedFormData);
    let index = updatedOrder?.findIndex((data) => data?.name === updatedIndex?.name);
    let updateIndex = index !== -1 ? index : workTypeTabValue;
    if (workTypeId) {
      updateIndex = index !== -1 ? index : updatedOrder?.findIndex(data => data?.id === Number(workTypeId))
    }
    setConstantType((prev) => ({ ...prev, work_type_settings: updatedOrder }));
    setCurrentOpenTabWorkType(updatedOrder[updateIndex]);
    setIsEditable(updatedOrder[updateIndex]?.isActive ? false : true);
    setUpdateWorkTypeIndex(updatedOrder[updateIndex]);
    if (updateIndex !== -1) {
      // setQueryParams("workTypeTabValue", updateIndex);
      setWorkTypeTabValue(updateIndex)
    }
  };

  const handleOpenChangeActivity = () => {
    setIsOpen(true);
  }

  const handleModalClose = () => {
    setIsOpen(false);
    setIsNewActivityOpen(false);
    setAllSelectedActivity([])
  }

  const handleCloseUpdateLeaveModal = () => {
    setModalDetails(initialModalDetails);
  };

  const handleChangeUserCheckbox = (e) => {
    setModalDetails((prev) => ({
      ...prev,
      isApplyForSelectedUser: e.target.checked,
      errorMsg: "",
    }));
  };

  const handleSelectAllUser = (e) => {
    if (e.target.checked) {
      setModalDetails((prev) => ({
        ...prev,
        isSelectAllUser: e.target.checked,
        user_ids: organizationUserList?.map(item => item?.id),
        errorMsg: "",
      }));
    } else {
      setModalDetails((prev) => ({
        ...prev,
        isSelectAllUser: e.target.checked,
        user_ids: [],
        errorMsg: "Please select at least one user."
      }));
    }
  }

  const handleUserCheckboxCheck = (id) => {
    let isAlready = modalDetails?.user_ids?.includes(id);
    if (!isAlready) {
      if (!!modalDetails?.errorMsg) {
        setModalDetails((prev) => ({ ...prev, errorMsg: "" }));
      }
      let updatedArray = [...modalDetails?.user_ids, id];
      setModalDetails((prev) => ({
        ...prev,
        user_ids: updatedArray,
        isSelectAllUser: updatedArray.length === organizationUserList?.map(item => item?.id)?.length
      }));
    } else {
      setModalDetails((prev) => ({
        ...prev,
        user_ids: prev?.user_ids?.filter((value) => value !== id),
        errorMsg: prev?.user_ids?.filter((value) => value !== id)?.length > 0 ? "" : "Please select at least one user.",
        isSelectAllUser: false,
      }));
    }
  }

  const handleChangeTab = (event, newValue) => {
    let selectedWorkType = constantType.work_type_settings.find((type, index) => type?.name.toLowerCase() === event.target.innerText.toLowerCase());
    setCurrentOpenTabWorkType({ ...selectedWorkType, isChecked: selectedWorkType?.isActive });
    setIsEditable(selectedWorkType?.isActive ? false : true);
    // setQueryParams("workTypeTabValue", newValue);
    setWorkTypeTabValue(newValue)
    setConstantType((prev) => ({
      ...prev, work_type_settings: prev.work_type_settings.map((data, index) => {
        if (data?.id === selectedWorkType?.id) {
          if (selectedWorkType?.isActive) {
            return { ...data, isChecked: true };
          } else {
            return { ...data, isChecked: false };
          }
        } else {
          return data;
        }
      })
    }));
    setUpdateWorkTypeIndex(selectedWorkType);
  };

  const handleChangeCheckBox = (event, checked, tabType) => {
    setConstantType((prev) => {
      const updatedWorkTypes = [...prev.work_type_settings];
      const findIndex = updatedWorkTypes.findIndex(
        (tab) => tab.name === tabType
      );

      if (findIndex !== -1) {
        updatedWorkTypes[findIndex] = {
          ...updatedWorkTypes[findIndex],
          isChecked: checked,
          is_changed: true,
          isDeleted: updatedWorkTypes[findIndex]?.isActive ? !checked : false
        };
      }
      return { ...prev, work_type_settings: updatedWorkTypes };
    });
  };

  const onBack = () => {
    setModalDetails((prev) => ({ ...prev, errorMsg: "", isShowUsersList: false, user_ids: [], isApplyForSelectedUser: false, }));
  };

  const onApprove = () => {
    if (!!modalDetails?.isApplyForSelectedUser && !modalDetails?.isShowUsersList) {
      getUserSettingData();
      setModalDetails((prev) => ({ ...prev, errorMsg: "", isShowUsersList: true }));
    } else if (!!modalDetails?.isApplyForSelectedUser && modalDetails?.isShowUsersList && modalDetails?.user_ids?.length <= 0) {
      setModalDetails((prev) => ({ ...prev, errorMsg: "Please select at least one user." }))
    } else {
      handleSubmitLeaveSetting("yes", "update-with-users")
    }
  };

  const onClose = () => {
    if (modalDetails?.isShowUsersList) {
      setModalDetails(initialModalDetails);
    } else {
      // handleSubmitLeaveSetting("no");
      handleCancel();
      setModalDetails(initialModalDetails);
      setIsEditable(false);
    }
  };

  const handleChooseActivity = async () => {
    try {
      if (allSelectedActivity?.length) {
        setLoading((prev) => ({ ...prev, updateActivity: true }));
        // let payload = {
        //   id: currentWorkTypeData?.id,
        //   activities_id: allSelectedActivity?.map((activityData) => activityData?.id)
        // }
        // const response = await axiosInstance.put(
        //   API.updateWorkTypeActivity, payload
        // );
        // if (response?.status === 200) {
        //   showAlert(response?.data?.message);
        setAllActivity((prev) => {
          return prev?.map((data) => {
            if ((allSelectedActivity?.filter(value => !!value)?.map((activityData) => activityData?.id))?.includes(data?.id)) {
              return { ...data, org_work_type_ids: [...data?.org_work_type_ids, currentWorkTypeData?.id]?.filter(value => !!value) }
            }
            return data
          })
        })
        let updatedActivity = allSelectedActivity?.map((data) => {
          if ((allSelectedActivity?.map((activityData) => activityData?.id))?.includes(data?.id)) {
            return { ...data, isCreated: true }
          }
          return data
        });
        setFormData((prev) => ({
          ...prev,
          work_type_settings: prev?.work_type_settings?.map((data, index) => {
            if (data?.id === currentWorkTypeData?.id) {
              return { ...data, is_changed: true, 'activities': data?.activities?.length > 0 ? [...data?.activities, ...updatedActivity] : [...updatedActivity] }
            }
            return data;
          })
        }))
        setAllSelectedActivity([])
        // }
        setLoading((prev) => ({ ...prev, updateActivity: false }));
        setIsOpen(false);
      }
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, updateActivity: false }));
    }
  }

  const handleSubmitLeaveSetting = async (type = "yes", action = null) => {
    try {
      setLoading((prev) => ({ ...prev, formSubmitting: true, noButtonLoading: type === "no" }));
      let errorsTab = [];
      if (currentOpenTabWorkType?.id && currentOpenTabWorkType?.isChecked) {
        let updatedErrors = [...errors];
        // validate max_absent_allowed and renewal_month
        let workTypeSettingsIndex = errors?.findIndex((value) => Object.keys(value).includes("work_type_settings"));

        updatedErrors[workTypeSettingsIndex]["work_type_settings"] = "";
        // validate tab data
        let isErrorInTab = false;
        let workTypeIndex = formData?.work_type_settings?.findIndex((_value) => _value?.name === currentOpenTabWorkType?.name);
        let workTypeData = formData?.work_type_settings[workTypeIndex];
        let errorWorkTypeIndex = updatedErrors?.findIndex((_value) => _value?.name === currentOpenTabWorkType?.name);
        if (!workTypeData["max_monthly_leave_for_dd"] && workTypeData["max_monthly_leave_for_dd"] !== 0) {
          updatedErrors[errorWorkTypeIndex]["max_monthly_leave_for_dd"] = "Required";
          isErrorInTab = true;
        }
        if (workTypeData?.enable_sys_generated_leave) {
          let min_working_hrs_half_day = workTypeData?.min_working_hrs_half_day;
          let min_working_hrs_full_day = workTypeData?.min_working_hrs_full_day;

          if (!min_working_hrs_half_day || min_working_hrs_half_day === 0) {
            updatedErrors[errorWorkTypeIndex]["min_working_hrs_half_day"] = "Required";
            isErrorInTab = true;
          }

          if (!min_working_hrs_full_day || min_working_hrs_full_day === 0) {
            updatedErrors[errorWorkTypeIndex]["min_working_hrs_full_day"] = "Required";
            isErrorInTab = true;
          }
        }

        let errorsForLeaveTypeSettings = workTypeData?.leave_type_settings?.map((leave, leaveIndex) => {
          let error = {};
          if (leaveIndex === 0) {
            error = getMissingKeys(leave, formData?.leave_frequency);
          } else {
            let isShowError = Object.values(getMissingKeys(leave, formData?.leave_frequency)).filter((value) => !!value).length !== ['leave_type_id', 'monthly_days', 'advance_days', 'max_leave_encashment'].length;
            error = isShowError ? getMissingKeys(leave, formData?.leave_frequency) : {
              'leave_type_id': "",
              'monthly_days': "",
              'advance_days': "",
              'max_leave_encashment': "",
            };
          }
          return error;
        });
        let filteredErrors2 = [];
        let isErrorSet = false;
        let isErrorSet2 = false;
        if (!!formData?.allow_special_credits) {
          let errorsForSpecialRequests = workTypeData?.special_requests?.map((leave, leaveIndex, array) => {
            let error = {};
            if (leaveIndex === 0) {
              error = getMissingKeys(leave, formData?.leave_frequency);
            } else {
              let isShowError = Object.values(getMissingKeys(leave, formData?.leave_frequency)).filter((value) => !!value).length !== ['name', 'allotment'].length;
              let object = isShowError ? getMissingKeys(leave, formData?.leave_frequency) : {
                'name': "",
                'allotment': "",
              };
              error = object;
            }
            return error;
          });

          let blankLength = workTypeData?.special_requests?.filter((value) => !value?.id && !!isNaN(value?.allotment) && !value?.name).length
          let isDeletedLength = workTypeData?.special_requests?.filter((value) => value?.is_deleted).length

          if (errorsForSpecialRequests?.length === 2) {
            let isAnyValueIsDeleted = workTypeData?.special_requests.some((value) => value?.is_deleted);
            if (isAnyValueIsDeleted) {
              isErrorSet = true;
            }
          }

          if (workTypeData?.special_requests?.length === (blankLength + isDeletedLength)) {
            isErrorSet2 = true;
          }

          filteredErrors2 = errorsForSpecialRequests?.filter((value) => !!Object.values(value)?.filter((value) => !!value)?.length);
          updatedErrors[errorWorkTypeIndex]["special_requests"] = (isErrorSet || isErrorSet2) ? [...errorsForSpecialRequests.slice(0, errorsForSpecialRequests?.length - 1), { ...errorsForSpecialRequests[errorsForSpecialRequests?.length - 1], name: "Required", allotment: "Required" }] : errorsForSpecialRequests;
        }

        let filteredErrors = errorsForLeaveTypeSettings?.filter((value) => !!Object.values(value)?.filter((value) => !!value)?.length);
        updatedErrors[errorWorkTypeIndex]["leave_type_settings"] = errorsForLeaveTypeSettings;

        if (isErrorInTab || Boolean(filteredErrors?.length) || Boolean(filteredErrors2?.length) || isErrorSet || isErrorSet2) {
          errorsTab.push(workTypeIndex)
          isErrorInTab = false;
          isErrorSet = false;
          isErrorSet2 = false;
        }
        setErrors(updatedErrors);
      }
      if (!errorsTab?.length) {
        let body = {
          id: formData?.id,
          name: formData?.name,
          leave_frequency: formData?.leave_frequency,
          max_absent_allowed: formData?.max_absent_allowed,
          renewal_month: formData?.renewal_month,
          allow_special_credits: formData?.allow_special_credits,
          work_type_settings:
            [currentOpenTabWorkType]?.map((work_type) => {
              let isChangeData = work_type?.is_changed;
              let workTypeData = formData?.work_type_settings?.find((value) => value?.id === work_type?.id);
              isChangeData = workTypeData?.is_changed;
              let getLeavesTypeSettings = () => {
                let initialWorkTypeData = initialData?.work_type_settings?.find((value) => value?.name === work_type?.name)?.leave_type_settings;
                let deletedLeave = initialWorkTypeData?.filter(initialObj => !workTypeData?.leave_type_settings?.some(updatedObj => updatedObj?.id === initialObj?.id));
                let array = workTypeData?.leave_type_settings?.filter(leave => (!!leave?.leave_type_id && !!leave?.monthly_days && (!!leave?.advance_days || leave?.advance_days === 0) && (!!leave?.max_leave_encashment || leave?.max_leave_encashment === 0)))?.map((leave) => {
                  let object = {
                    leave_type_id: leave?.leave_type_id,
                    monthly_days: leave?.monthly_days,
                    advance_days: leave?.advance_days,
                    max_leave_encashment: leave?.max_leave_encashment,
                    org_year_id: org_year_id,
                    organization_id: organization_id
                  }
                  if (leave?.id != null) {
                    object['id'] = leave?.id;
                  }
                  if (leave?.is_changed) {
                    object['is_changed'] = true;
                    isChangeData = true;
                  }
                  if (leave?.is_deleted) {
                    object['is_deleted'] = true;
                    delete object['is_changed'];
                    isChangeData = true;
                  }
                  return object;
                });
                if (!!deletedLeave && Object.keys(deletedLeave)) {
                  deletedLeave.forEach((value) => {
                    array.push({ ...value, is_deleted: true, });
                  })
                }
                return array;
              };
              let getSpecialRequests = () => {
                let array = workTypeData?.special_requests?.map((leave) => {
                  let object = {
                    name: leave?.name,
                    allotment: !isNaN(leave?.allotment) ? leave?.allotment : null,
                    is_changed: leave?.is_changed,
                    org_year_id: org_year_id,
                    organization_id: organization_id,
                  }
                  if ((leave?.id && formData?.allow_special_credits === false) || (leave?.id && !work_type?.isChecked)) {
                    object['is_deleted'] = true;
                    isChangeData = true;
                    delete object['is_changed'];
                  }
                  if (leave?.id != null) {
                    object['id'] = leave?.id;
                  }
                  if (leave?.is_changed) {
                    object['is_changed'] = true;
                    isChangeData = true;
                  } else {
                    delete object['is_changed'];
                  }
                  if (leave?.is_deleted) {
                    object['is_deleted'] = true;
                    isChangeData = true;
                    delete object['is_changed'];
                  }
                  return object;
                });
                let updatedArray = array?.filter(value => !isNaN(value?.allotment) && !!value?.name);
                return updatedArray;
              }
              let object = {
                name: workTypeData?.name,
                max_monthly_leave_for_dd: workTypeData?.max_monthly_leave_for_dd,
                leave_type_settings: getLeavesTypeSettings(),
                special_requests: getSpecialRequests(),
                activities: workTypeData?.activities,
                min_working_hrs_half_day: workTypeData?.min_working_hrs_half_day,
                min_working_hrs_full_day: workTypeData?.min_working_hrs_full_day,
                enable_sys_generated_leave: formData?.enable_sys_generated_leave,
                active: work_type?.isChecked,
                is_changed: isChangeData
              }
              if (workTypeData?.id) {
                object['id'] = workTypeData?.id;
              }
              if (currentOpenTabWorkType?.isDeleted) {
                object['is_inactive'] = true;
                object['is_deleted'] = true;
              }

              // if (!object?.id && object?.is_deleted === true)
              //   return null;
              // else
              return object;
            }).filter(value => !!value),
        }
        try {
          let response;
          let payload = {
            user_ids: modalDetails?.user_ids,
            organization_id: body?.id,
            leave_frequency: body?.leave_frequency,
            allow_special_credits: body?.allow_special_credits,
            work_type_settings: body?.work_type_settings?.map((value) => {
              let workTypeObj = {
                is_changed: value?.is_changed,
                name: value?.name,
                max_monthly_leave_for_dd: value?.max_monthly_leave_for_dd,
                leave_type_settings: value?.leave_type_settings?.map((leave) => {
                  let object = {
                    leave_type_id: leave?.leave_type_id,
                    monthly_days: leave?.monthly_days,
                    advance_days: leave?.advance_days,
                    max_leave_encashment: leave?.max_leave_encashment,
                    is_changed: leave?.is_changed,
                    organization_id: organization_id,
                  }
                  if (leave?.id != null) {
                    object['id'] = leave?.id;
                  }
                  if (leave?.is_changed) {
                    object['is_changed'] = true;
                  } else {
                    delete object['is_changed'];
                  }
                  if (leave?.is_deleted) {
                    object['is_deleted'] = true;
                    delete object['is_changed'];
                  }
                  if (!object?.id && object?.is_changed) {
                    delete object?.is_changed;
                  }
                  return object;
                }),
                special_requests: value?.special_requests?.map((request) => {
                  let obj = {
                    name: request?.name,
                    allotment: request?.allotment,
                    active: request?.active,
                    is_deleted: request?.is_deleted,
                    is_changed: request?.is_changed,
                    organization_id: organization_id
                  };
                  if (!obj?.id && obj?.is_changed) {
                    delete obj?.is_changed;
                  }
                  if (request?.id != null) {
                    obj['id'] = request?.id;
                  }
                  if (request?.is_changed) {
                    obj['is_changed'] = true;
                  } else {
                    delete obj['is_changed'];
                  }
                  if (request?.is_deleted) {
                    obj['is_deleted'] = true;
                    delete obj['is_changed'];
                  }
                  return obj;
                }),
                activities: value?.activities || [],
                min_working_hrs_half_day: value?.min_working_hrs_half_day,
                min_working_hrs_full_day: value?.min_working_hrs_full_day,
                enable_sys_generated_leave: value?.enable_sys_generated_leave,
              };
              if (value?.id) {
                workTypeObj['id'] = value?.id
              }
              if (value?.is_deleted) {
                workTypeObj['is_deleted'] = true;
                workTypeObj['leave_type_settings'] = workTypeObj?.leave_type_settings?.map((leave) => {
                  return { ...leave, is_deleted: true };
                });
                workTypeObj['special_requests'] = workTypeObj?.special_requests?.map((leave) => {
                  return { ...leave, is_deleted: true };
                });
              }

              return workTypeObj;
            })
          }
          await axiosInstance.put(
            API.organisationLeaveSettings(currentUser?.organization_id),
            body
          );
          response = await axiosInstance.put(
            API.organisationUsersLeaveSettings,
            payload
          );
          if (response.status === 200) {
            showAlert(response.data?.message, "success");
            setModalDetails(initialModalDetails);

            handleCancel(updateWorkTypeIndex);
          }
          setLoading((prev) => ({ ...prev, formSubmitting: false, noButtonLoading: false, }));
        } catch (error) {
          showAlert("Something went wrong.", "error");
          setLoading((prev) => ({ ...prev, formSubmitting: false, noButtonLoading: false, }));
          setModalDetails(initialModalDetails);
          fetchData();
        }
      }
      setLoading((prev) => ({ ...prev, formSubmitting: false, noButtonLoading: false, }));
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, formSubmitting: false, noButtonLoading: false, }));
    }
  };

  const handleCancel = (index = null) => {
    fetchData(null, index);
    setIsEditable(false);
  };

  const isValidateFormData = () => {
    if (currentOpenTabWorkType?.id && !currentOpenTabWorkType?.isChecked) {
      return false;
    }
    setLoading((prev) => ({ ...prev, formSubmitting: true, }));
    let updatedErrors = [...errors];
    let errorsTab = [];
    let isErrorInTab = false;
    let workTypeIndex = formData?.work_type_settings?.findIndex((_value) => _value?.name === currentOpenTabWorkType?.name);
    let workTypeData = formData?.work_type_settings[workTypeIndex];
    let errorWorkTypeIndex = updatedErrors?.findIndex((_value) => _value?.name === currentOpenTabWorkType?.name);
    if (!workTypeData["max_monthly_leave_for_dd"] && workTypeData["max_monthly_leave_for_dd"] !== 0) {
      updatedErrors[errorWorkTypeIndex]["max_monthly_leave_for_dd"] = "Required";
      isErrorInTab = true;
    }

    if (formData?.enable_sys_generated_leave) {
      let min_working_hrs_half_day = workTypeData?.min_working_hrs_half_day;
      let min_working_hrs_full_day = workTypeData?.min_working_hrs_full_day;

      if (!min_working_hrs_half_day || min_working_hrs_half_day === 0) {
        updatedErrors[errorWorkTypeIndex]["min_working_hrs_half_day"] = "Required";
        isErrorInTab = true;
      } else {
        let halfDayTime = secondsToTime(workTypeData?.min_working_hrs_half_day);
        if (halfDayTime && halfDayTime !== 'Invalid date' && halfDayTime !== '00:00') {
          const halfDayTotalHours = timeToHours(halfDayTime);
          if (halfDayTotalHours > 10) {
            updatedErrors[errorWorkTypeIndex]['min_working_hrs_half_day'] = "Time must be less than 10 hours";
            isErrorInTab = true;
          } else {
            updatedErrors[errorWorkTypeIndex]['min_working_hrs_half_day'] = "";
          }
        } else {
          updatedErrors[errorWorkTypeIndex]['min_working_hrs_half_day'] = "Required";
          isErrorInTab = true;
        }
      }

      if (!min_working_hrs_full_day || min_working_hrs_full_day === 0) {
        updatedErrors[errorWorkTypeIndex]["min_working_hrs_full_day"] = "Required";
        isErrorInTab = true;
      } else {
        let fullDayTime = secondsToTime(workTypeData?.min_working_hrs_full_day);
        if (fullDayTime && fullDayTime !== 'Invalid date' && fullDayTime !== '00:00') {
          const fullDayTotalHours = timeToHours(fullDayTime);
          if (fullDayTotalHours > 10) {
            updatedErrors[errorWorkTypeIndex]['min_working_hrs_full_day'] = "Time must be less than 10 hours";
            isErrorInTab = true;
          } else {
            updatedErrors[errorWorkTypeIndex]['min_working_hrs_full_day'] = "";
          }
        } else {
          updatedErrors[errorWorkTypeIndex]['min_working_hrs_full_day'] = "Required";
          isErrorInTab = true;
        }
      }

      if (workTypeData?.min_working_hrs_half_day && workTypeData?.min_working_hrs_full_day) {
        // Cross-validation logic
        let halfDayTime = secondsToTime(workTypeData?.min_working_hrs_half_day);
        let fullDayTime = secondsToTime(workTypeData?.min_working_hrs_full_day);

        if (halfDayTime && fullDayTime && halfDayTime !== 'Invalid date' && fullDayTime !== 'Invalid date') {
          const halfDayTotalHours = timeToHours(halfDayTime);
          const fullDayTotalHours = timeToHours(fullDayTime);

          if (halfDayTotalHours >= fullDayTotalHours) {
            updatedErrors[errorWorkTypeIndex]['min_working_hrs_half_day'] = "Half day time must be less than full day time";
            updatedErrors[errorWorkTypeIndex]['min_working_hrs_full_day'] = "Full day time must be greater than half day time";
            isErrorInTab = true;
          } else {
            updatedErrors[errorWorkTypeIndex]['min_working_hrs_half_day'] = "";
            updatedErrors[errorWorkTypeIndex]['min_working_hrs_full_day'] = "";
          }
        }
      }
    }

    let errorsForLeaveTypeSettings = workTypeData?.leave_type_settings?.map((leave, leaveIndex) => {
      let error = {};
      if (leaveIndex === 0) {
        error = getMissingKeys(leave, formData?.leave_frequency);
      } else {
        let isShowError = Object.values(getMissingKeys(leave, formData?.leave_frequency)).filter((value) => !!value).length !== ['leave_type_id', 'monthly_days', 'advance_days', 'max_leave_encashment'].length;
        error = isShowError ? getMissingKeys(leave, formData?.leave_frequency) : {
          'leave_type_id': "",
          'monthly_days': "",
          'advance_days': "",
          'max_leave_encashment': "",
        };
      }
      return error;
    });

    let filteredErrors2 = [];
    let isErrorSet = false;
    let isErrorSet2 = false;
    if (!!formData?.allow_special_credits) {
      let errorsForSpecialRequests = workTypeData?.special_requests?.map((leave, leaveIndex, array) => {
        let error = {};
        if (leaveIndex === 0) {
          error = getMissingKeys(leave, formData?.leave_frequency);
        } else {
          let isShowError = Object.values(getMissingKeys(leave, formData?.leave_frequency)).filter((value) => !!value).length !== ['name', 'allotment'].length;
          let object = isShowError ? getMissingKeys(leave, formData?.leave_frequency) : {
            'name': "",
            'allotment': "",
          };
          error = object;
        }
        return error;
      });

      let blankLength = workTypeData?.special_requests?.filter((value) => !value?.id && !!isNaN(value?.allotment) && !value?.name).length
      let isDeletedLength = workTypeData?.special_requests?.filter((value) => value?.is_deleted).length

      if (errorsForSpecialRequests?.length === 2) {
        let isAnyValueIsDeleted = workTypeData?.special_requests.some((value) => value?.is_deleted);
        if (isAnyValueIsDeleted) {
          isErrorSet = true;
        }
      }

      if (workTypeData?.special_requests?.length === (blankLength + isDeletedLength)) {
        isErrorSet2 = true;
      }

      filteredErrors2 = errorsForSpecialRequests?.filter((value) => !!Object.values(value)?.filter((value) => !!value)?.length);
      updatedErrors[errorWorkTypeIndex]["special_requests"] = (isErrorSet || isErrorSet2) ? [...errorsForSpecialRequests.slice(0, errorsForSpecialRequests?.length - 1), { ...errorsForSpecialRequests[errorsForSpecialRequests?.length - 1], name: "Required", allotment: "Required" }] : errorsForSpecialRequests;
    }

    let filteredErrors = errorsForLeaveTypeSettings?.filter((value) => !!Object.values(value)?.filter((value) => !!value)?.length);
    updatedErrors[errorWorkTypeIndex]["leave_type_settings"] = errorsForLeaveTypeSettings;

    if (isErrorInTab || Boolean(filteredErrors?.length) || Boolean(filteredErrors2?.length) || isErrorSet || isErrorSet2) {
      errorsTab.push(workTypeIndex)
      isErrorInTab = false;
      isErrorSet = false;
      isErrorSet2 = false;
    }
    setErrors(updatedErrors);

    if (!errorsTab?.length) {
      setLoading((prev) => ({ ...prev, formSubmitting: false, }));
      return false;
    } else {
      setLoading((prev) => ({ ...prev, formSubmitting: false, }));
      return true;
    }
  }

  const handleOpenModal = () => {
    if (!isValidateFormData()) {
      if (currentOpenTabWorkType?.id && currentOpenTabWorkType?.isChecked && currentOpenTabWorkType?.isActive && !currentOpenTabWorkType?.canDelete) {
        setModalDetails((prev) => ({
          ...prev,
          isShow: true,
        }))
      } else {
        handleSubmitLeaveSetting("no");
      }
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (loading.pageLoading || loading?.yearLoading ?
    <CircularLoader /> :
    <Box p={{ xs: 2, lg: 3, xl: 4 }} pb={{ xs: 3, lg: 3, xl: 4 }} sx={{ paddingTop: '0px !important' }} height={"100%"} overflow="auto">
      <Grid item my={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid item width={265} maxWidth={265}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel htmlFor="year_label">
                Select Year
              </InputLabel>
              <Select
                id="year-range"
                inputProps={{ id: "year_label" }}
                value={selectedYear}
                onChange={(e) => handleYearChange(e)}
                label="Select Year"
                sx={{
                  ".MuiSelect-select": {
                    fontSize: 14,
                    fontWeight: 400,
                    color: "dark.800",
                  },
                }}
                disabled={isEditable && !!years?.find((yearData) => yearData?.id === selectedYear && yearData?.active)}
              >
                {years?.map((yearData) => (
                  <MenuItem value={yearData?.id} key={yearData?.id}>
                    {getYearObject(yearData)?.yearDashedFormat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Box>
      </Grid>
      <Stack spacing={1} sx={{ flexBasis: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: 'white', position: 'sticky', top: 0, zIndex: 5, }}>
            <Tabs
              value={workTypeTabValue}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              {constantType.work_type_settings.map(
                (work_type_settings, index) => (
                  <Tab label={work_type_settings.name} key={index} {...a11yProps(index)} disabled={loading?.users} />
                )
              )}
            </Tabs>
          </Box>
          {
            loading?.users ?
              <CircularLoader height={500} />
              :
              constantType.work_type_settings.map((work_type_settings, index) => {
                return (
                  <CustomTabPanel value={workTypeTabValue} index={index} key={work_type_settings?.name?.toLowerCase()}>
                    <Box mx={-0.5}>
                      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                        <FormControlLabel
                          id='work_type_settings_checkbox'
                          name='work_type_settings_checkbox'
                          value="end"
                          control={<Checkbox checked={work_type_settings?.isChecked} />}
                          label={`Yes, I want to set ${work_type_settings.name} Leave Settings for this organisation.`}
                          labelPlacement="end"
                          onChange={(e, checked) => {
                            if (!currentWorkTypeData?.Users?.length) {
                              handleChangeCheckBox(e, checked, work_type_settings.name)
                              setCurrentOpenTabWorkType({ ...work_type_settings, isChecked: checked, isDeleted: work_type_settings?.isActive ? !checked : false });
                            }
                          }}
                          onClick={() => {
                            if (isEditable && currentWorkTypeData?.Users?.length) {
                              setIsShowWorktypeRemoveModal(work_type_settings?.name)
                            }
                          }}
                          sx={{
                            marginBottom: '16px',
                            '.MuiCheckbox-sizeMedium': {
                              minWidth: '42px'
                            }
                          }}
                          disabled={!isEditable && work_type_settings?.isActive}
                        />
                        <Box display={'flex'} alignItems={'center'} gap={2}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography color="dark.600" fontSize={13} lineHeight={'21.58px'} letterSpacing={'0.4px'}>
                              Type:
                            </Typography>
                            <Typography color={"primary"} fontSize={"13px"} fontWeight={"500"} border={"1px solid #047FE0"} borderRadius={"4px"} padding={"2px 9px"} lineHeight={"22px"} letterSpacing={"0.46px"} sx={{ mx: '10px', fontStyle: 'italic' }} >
                              {work_type_settings?.external ? "External" : "Internal"}
                            </Typography>
                            <HtmlTooltip arrow placement="top"
                              title={
                                <Typography fontSize={{ xs: 12, lg: 14 }}>Indicates the nature of worktype, specified whether it is Internal or External.</Typography>
                              }>
                              <i
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  color: "#3F5C76",
                                }}
                              >
                                <i style={{ width: '18px', height: '18px' }}><HelpOutlineIcon sx={{ fontSize: '18px', color: 'secondary.main' }} /></i>
                              </i>
                            </HtmlTooltip>
                          </Box>
                          {!isEditable && work_type_settings?.isActive && work_type_settings?.id !== null ?
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              sx={{
                                boxShadow: "0",
                                color: "white",
                                fontSize: "14px",
                                paddingTop: '6px',
                                paddingBottom: '6px',
                                textTransform: "none",
                                "&:hover": { boxShadow: "0" },
                              }}
                              type="button"
                              onClick={() => setIsEditable(!isEditable)}
                            >
                              Edit
                            </Button>
                            : null}
                        </Box>
                        {/* {!isEditable && work_type_settings?.isChecked && work_type_settings?.id !== null ? */}
                        {/* {!isEditable && work_type_settings?.id !== null ? */}
                      </Stack>
                      {work_type_settings?.isChecked ? (
                        <LeaveTypeForm
                          totalUsersCount={currentWorkTypeData?.Users?.length}
                          userListByRoleData={userListByRoleData}
                          userRoles={userRoles}
                          work_type_settings={work_type_settings}
                          formData={formData}
                          errors={errors}
                          setErrors={setErrors}
                          setFormData={setFormData}
                          constantType={constantType}
                          handleOpenChangeActivity={handleOpenChangeActivity}
                          currentWorkTypeActivity={currentWorkTypeActivity}
                          setAllActivity={setAllActivity}
                          isEdit={isEditable}
                        />
                      ) : null}
                    </Box>
                  </CustomTabPanel>
                )
              }
              )
          }
        </Box>
        {isEditable && (currentOpenTabWorkType?.isActive || currentOpenTabWorkType?.isChecked) ?
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              flexBasis="100%"
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  boxShadow: "0",
                  color: "white",
                  fontSize: "14px",
                  paddingTop: '6px',
                  paddingBottom: '6px',
                  textTransform: "none",
                  "&:hover": { boxShadow: "0" },
                }}
                type="button"
                onClick={handleOpenModal}
              // isButtonLoading={loading?.formSubmitting}
              // disabled={!isValidData(errors)}
              >
                {currentOpenTabWorkType?.isActive ? currentOpenTabWorkType?.isChecked ? "Update" : "Deactivate" : "Create"} {titleCase(currentOpenTabWorkType?.name)} {'Settings'}
              </Button>
              <Button
                variant="contained"
                color="lightGray"
                size="medium"
                sx={{
                  boxShadow: "0",
                  color: "#3F5C76",
                  fontSize: "14px",
                  textTransform: "none",
                  marginLeft: "20px",
                  padding: '6px',
                  "&:hover": { boxShadow: "0" },
                }}
                type="button"
                onClick={() => handleCancel(updateWorkTypeIndex)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
          : null}
      </Stack>
      <Modal
        open={modalDetails?.isShow}
        onClose={handleCloseUpdateLeaveModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '> div': {
            overflow: 'hidden',
            '> div': {
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }
          }
        }}
      >
        <Box sx={[style, {
          minWidth: modalDetails?.isShowUsersList ? "30%" : "auto",
          minHeight: modalDetails?.isShowUsersList ? "25%" : "auto",
          maxHeight: modalDetails?.isShowUsersList ? "80%" : "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflowY: "auto !important",
        }]}>
          <Box >
            <Grid item py={2} px={3} borderBottom="1px solid #E2E4EC" sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              background: "white",
            }}>
              <Typography variant="h6">Apply Leave Settings</Typography>
            </Grid>
            <Grid item py={2} px={3} flex={1} overflow={"auto"} width={576}>
              <Box>
                {!modalDetails?.isShowUsersList ?
                  <FormControlLabel
                    id='isApplyForSelectedUser'
                    name='isApplyForSelectedUser'
                    label={`Apply changes to Users?`}
                    labelPlacement="end"
                    control={<Checkbox checked={modalDetails?.isApplyForSelectedUser} />}
                    disabled={modalDetails?.isShowUsersList}
                    onChange={(e) => handleChangeUserCheckbox(e)}
                  /> : null}
                {!!modalDetails?.errorMsg ?
                  <Typography sx={{
                    fontSize: "12px",
                    color: "red",
                  }}>
                    {modalDetails?.errorMsg}
                  </Typography> : null}
              </Box>
              {!!modalDetails?.isShowUsersList ?
                <Box>
                  {!!loading?.users ? <CircularLoader /> : null}
                  <FormControlLabel
                    id={getInputId("isSelectAllUser")}
                    name='isSelectAllUser'
                    label={`Select all users`}
                    labelPlacement="end"
                    control={<Checkbox checked={modalDetails?.isSelectAllUser} />}
                    onChange={(e) => handleSelectAllUser(e)}
                  />
                  {organizationUserList?.length > 0 ?
                    organizationUserList?.map((userData, index) => (
                      <List sx={{ p: 0, cursor: "pointer", overflow: "auto" }} key={index} onClick={() => handleUserCheckboxCheck(userData?.id)}>
                        <ListItem sx={{
                          padding: '8px 12px',
                          '&:hover': { background: 'rgba(0,0,0,0.04)' },
                          '&:hover .MuiIconButton-root': {
                            opacity: '1',
                          }
                        }}>
                          <Grid sx={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}>
                            <Grid item>
                              <FormControlLabel
                                id={getInputId("user_ids", userData?.id)}
                                name='user_ids'
                                control={<Checkbox checked={modalDetails?.user_ids?.includes(userData?.id)} />}
                                onChange={(e) => handleUserCheckboxCheck(userData?.id)}
                              />
                            </Grid>
                            <Grid sx={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}>
                              <BackgroundLetterAvatars
                                user={userData}
                                src={userData?.profile_img ? userData?.profile_img : "/static/images/avatar/1.jpg"}
                                sx={{ width: 30, height: 30, mr: 1, fontSize: '75%' }} />
                              <Typography color="secondary" fontSize={14} fontWeight={500} sx={{ lineHeight: '22px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} flex={1}>{getFullName(userData)}</Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>)) :
                    <>
                      {!loading?.users ? <div>No users found.</div> : null}
                    </>}
                </Box>
                : null}
            </Grid>
          </Box>
          <Grid
            item
            py={2}
            px={3}
            sx={{
              borderTop: "1px solid #E2E4EC",
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
              bottom: 0,
              left: 0,
              background: "white",
              flexDirection: 'row !important',
              overflow: 'visible !important',
            }}
          >
            {modalDetails?.isShowUsersList ?
              <Stack spacing={1} direction="row">
                <Button
                  variant="text"
                  size="medium"
                  fontWeight="500"
                  // sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                  onClick={onBack}
                  isButtonLoading={false}
                  disabled={loading?.formSubmitting || loading?.noButtonLoading || loading?.users}
                >
                  Previous
                </Button>
              </Stack> : null}
            <Stack spacing={1} direction="row">
              <Button
                variant="contained"
                size="medium"
                fontWeight="500"
                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                onClick={onApprove}
                isButtonLoading={loading?.noButtonLoading ? false : modalDetails?.isShowUsersList ? loading?.formSubmitting : loading?.users ? loading?.users : loading?.formSubmitting}
                disabled={loading?.formSubmitting || loading?.noButtonLoading || loading?.users || !!modalDetails?.errorMsg}
              >
                {modalDetails?.isShowUsersList ? "Update" : "Continue"}
              </Button>
              <Button
                variant="text"
                color="secondary"
                sx={{ textTransform: "none" }}
                size="medium"
                fontWeight="500"
                onClick={onClose}
                disabled={loading?.formSubmitting || loading?.noButtonLoading || loading?.users}
                isButtonLoading={loading?.noButtonLoading}
              >
                {modalDetails?.isShowUsersList ? "Cancel" : "Cancel"}
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Modal>

      <Modal
        sx={{
          height: '90%',
          maxWidth: "90%",
          maxHeight: "90%",
          margin: "auto",
          borderRadius: '10px',
          boxSizing: 'border-box',
        }}
        open={isOpen}
        onClose={() => handleModalClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, {
          width: 'auto',
          minWidth: "250px",
          minHeight: "250px",
        }]}>
          <ChooseWorkTypeActivity
            setInputActivityValue={setInputActivityValue}
            otherWorkTypeActivity={otherWorkTypeActivity}
            setIsNewActivityOpen={setIsNewActivityOpen}
            handleModalClose={handleModalClose}
            handleChooseActivity={handleChooseActivity}
            setAllSelectedActivity={setAllSelectedActivity}
            currentWorkTypeData={currentWorkTypeData}
            loading={loading} />
        </Box>
      </Modal>
      <BootstrapDialog
        open={isNewActivityOpen}
        // onClose={() => setIsNewActivityOpen(false)}
        onClose={() => handleModalClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent>
          <Box>
            <CreateNewActivity
              setCurrentWorkTypeActivity={setCurrentWorkTypeActivity}
              currentUser={currentUser}
              inputActivityValue={inputActivityValue}
              setFormData={setFormData}
              currentWorkTypeData={currentWorkTypeData}
              workTypeData={formData?.work_type_settings}
              // handleModalClose={() => setIsNewActivityOpen(false)}
              handleModalClose={() => handleModalClose()}
              getAllLeaveSettingData={getAllLeaveSettingData}
              currentWorkTypeActivity={currentWorkTypeActivity}
              allActivity={allActivity}
              setAllActivity={setAllActivity}
            />
          </Box>
        </DialogContent>
      </BootstrapDialog>
      <InformationModal
        isOpen={isShowWorktypeRemoveModal?.length}
        title={<span>Work Type <b>{isShowWorktypeRemoveModal}</b> can not be deactivated.</span>}
        handleClose={() => setIsShowWorktypeRemoveModal("")}
        subList={<span> Work type <b>{isShowWorktypeRemoveModal}</b> cannot be deactivated because team members are currently assigned to it. To proceed, please reassign or remove all members from <b>{isShowWorktypeRemoveModal}</b> before attempting to deactivated it.</span>}
        buttonName={"Ok"}
      />
    </Box>
  )
}

export default WorkTypeSettings;