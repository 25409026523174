import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
    tooltipClasses,
    Menu,
    MenuItem,
    Box,
    Chip,
} from "@mui/material";
import ICONS from "../../../../../constants/icons";
import { styled } from "@mui/styles";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { calculateLeaveCount, getApplicationNumber, getApplyDateTime, getFirstLetterOfString, getFormattedDate, getLeaveDateArray, getLeaveDates, getLeaveStatus, showInformationAuthority, sortArrayByKey, specialRequestList, titleCase } from "../../../../../utils";
import EmptyPage from "../../../../../components/EmptyPage";
import URLS from "../../../../../routes/urls";
import { useNavigate } from "react-router-dom";
import { LEAVE_STATUS } from "../../../../../constants/default-values";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: "16px",
        borderRadius: "5px",
        background: "#3F5C76",
    },
}));

const WHFListTable = ({ leaveDataList, leaveStatus, handleCancelRequestModal, handleDeleteRequestModal, handleViewLeave, handleEditLeave, handleClose, handleClick, anchorEl, openElem, open, openAccordianList, setOpenAccordianList, activeRequestType, optionalWfhData }) => {
    const navigate = useNavigate();
    function calculateTotalCredits(leaveDates) {
        let totalCredits = 0;

        leaveDates?.forEach((leave) => {
            totalCredits += leave?.credit_used || 0;
        });

        return totalCredits;
    }

    const isCreditEnable = (leaveDetail) => {
        return !!activeRequestType?.find((request) => request?.spec_req_id === leaveDetail?.static_id);
    }

    const AppliedLeaveType = (leaveDetails) => {
        return specialRequestList?.find(
            (leaveType) =>
                leaveType?.id ===
                leaveDetails?.static_id
        )?.name;
    }

    const hasAttachmentInList = () => {
        if (optionalWfhData?.length) {
            return optionalWfhData?.some(leave => leave?.attachment_references > 0);
        }
    }

    const checkApplicationEnable = (wfhData) => {
        const minRequestDate = wfhData?.WfhDays?.reduce((min, request) => {
            const requestDate = new Date(request.date);
            return requestDate < min ? requestDate : min;
        }, new Date(wfhData?.WfhDays[0].date));

        const today = new Date();
        // today.setHours(0, 0, 0, 0);

        const result = today <= minRequestDate;
        return result && [LEAVE_STATUS.Approved, LEAVE_STATUS.Rejected, LEAVE_STATUS.Pending]?.includes(wfhData?.status);
    }

    return (
        <Grid container>
            <Grid item sx={{ width: "100%" }}>
                {!!leaveDataList?.length || Object?.keys(leaveDataList)?.length
                    ? Object?.entries(leaveDataList)?.map((leaveData, index) => (
                        <Accordion
                            expanded={openAccordianList?.includes(leaveData[0])}
                            sx={{
                                width: "100%",
                                background: "#EBEFF3",
                                border: "1px solid rgba(0, 0, 0, 0.08)",
                                boxShadow: "none",
                                borderRadius: "5px",
                                margin: "8px 0px 0px 0px !important",
                                "&:first-of-type": {
                                    margin: "0px !important",
                                },
                                "&:before": {
                                    display: "none",
                                },
                                ".MuiAccordionSummary-root": {
                                    minHeight: "42px",
                                    flexDirection: "row-reverse",
                                },
                                ".MuiAccordionSummary-root.Mui-expanded": {
                                    minHeight: "42px !important",
                                },
                                ".MuiAccordionSummary-content": {
                                    marginBottom: "13px",
                                    marginLeft: "8px",
                                },
                                ".MuiAccordionSummary-content.Mui-expanded": {
                                    marginLeft: '8px',
                                    marginBottom: "13px",
                                    marginTop: "12px",
                                },
                                ".MuiAccordionDetails-root": {
                                    paddingTop: "0px",
                                },
                                ".MuiAccordion-region> div": {
                                    paddingBottom: "8px",
                                    marginTop: "-8px",
                                },
                                '& .MuiAccordionSummaryExpandIconWrapper': {
                                    transform: 'rotate(-90deg)',
                                },
                                '& .MuiAccordionSummaryExpandIconWrapper.MuiExpanded': {
                                    transform: 'rotate(0deg)',
                                }
                            }}
                            elevation={0}
                            key={index}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <i
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            '& .MuiAccordionSummaryExpandIconWrapper': {
                                                transform: 'rotate(-90deg)',
                                            },
                                            '& .MuiAccordionSummaryExpandIconWrapper.MuiExpanded': {
                                                transform: 'rotate(0deg)',
                                            }
                                        }}
                                    >
                                        {ICONS.ChevronLineSmall}
                                    </i>
                                }
                                aria-controls="panel1-content"
                                id="panel1-header"
                                onClick={() => setOpenAccordianList((prev) => prev?.includes(leaveData[0]) ? prev?.filter(list => list !== leaveData[0]) : [...prev, leaveData[0]])}
                            >
                                <Typography
                                    color="dark.800"
                                    fontSize={16}
                                    lineHeight="24px"
                                    fontWeight={500}
                                >
                                    {titleCase(leaveData[0])}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer>
                                    <Table
                                        aria-label="simple table"
                                        sx={{
                                            borderCollapse: "separate",
                                            borderSpacing: "0px 8px",
                                        }}
                                    >
                                        <TableBody>
                                            {(!!leaveData?.length && leaveData[1]?.length) ? leaveData[1]?.map((leaveDetail, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{
                                                        td: {
                                                            border: 0,
                                                            bgcolor: "white",
                                                            padding: "8px 16px",
                                                            cursor: "pointer",
                                                        },
                                                        'td:first-of-type': {
                                                            borderRadius: "4px 0px 0px 4px",
                                                        },
                                                        'td:last-child': {
                                                            borderRadius: "0px 4px 4px 0px",
                                                        },
                                                    }}
                                                >
                                                    <TableCell
                                                        sx={{
                                                            width: '170px',
                                                            minWidth: '170px',
                                                            maxWidth: '170px',
                                                        }}
                                                        onClick={() => handleViewLeave(leaveDetail)}>
                                                        <Typography
                                                            variant="body2"
                                                            color="dark.800"
                                                            lineHeight="14px"
                                                            letterSpacing="0.17px"
                                                            fontWeight={500}
                                                            mb="6px"
                                                            whiteSpace="nowrap"
                                                            component={"span"}
                                                        >
                                                            {getApplicationNumber(leaveDetail?.application_num)}
                                                            <Chip
                                                                label={getFirstLetterOfString(AppliedLeaveType(leaveDetail) === "Come late" ? "Late Coming" : AppliedLeaveType(leaveDetail))}
                                                                color="secondary"
                                                                size="small"
                                                                variant="filled"
                                                                sx={{ marginLeft: '4px' }}
                                                            />
                                                        </Typography>
                                                        <Typography
                                                            color="dark.500"
                                                            fontSize={10}
                                                            lineHeight="15px"
                                                            letterSpacing="0.17px"
                                                            fontWeight={500}
                                                        >
                                                            {leaveDetail?.WfhDays?.length} Days
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            minWidth: '250px',
                                                        }}
                                                        onClick={() => handleViewLeave(leaveDetail)}>
                                                        <Typography
                                                            color="dark.500"
                                                            fontSize={10}
                                                            lineHeight="15px"
                                                            letterSpacing="0.17px"
                                                            fontWeight={500}
                                                            mb="6px"
                                                        >
                                                            Date
                                                        </Typography>
                                                        <Grid item display="flex" alignItems="center">
                                                            <Typography
                                                                variant="body2"
                                                                color="dark.800"
                                                                lineHeight="14px"
                                                                letterSpacing="0.17px"
                                                                fontWeight={500}
                                                                mr="6px"
                                                                whiteSpace="nowrap"
                                                            >
                                                                {getLeaveDates(getLeaveDateArray(leaveDetail?.WfhDays, 'date'))}
                                                            </Typography>
                                                            <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography color="white" mb="10px">
                                                                            {getFirstLetterOfString(AppliedLeaveType(leaveDetail))} -{" "}
                                                                            {calculateLeaveCount(
                                                                                leaveDetail?.WfhDays
                                                                            )}{" "}
                                                                            {calculateLeaveCount(
                                                                                leaveDetail?.WfhDays
                                                                            ) <= 1
                                                                                ? "Day"
                                                                                : "Days"}
                                                                        </Typography>
                                                                        <List
                                                                            sx={{
                                                                                padding: 0,
                                                                                li: { padding: 0 },
                                                                                "li span": {
                                                                                    color: "white",
                                                                                    fontSize: 12,
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "19px",
                                                                                },
                                                                            }}
                                                                        >
                                                                            {sortArrayByKey(leaveDetail?.WfhDays, 'asc', 'date')?.map(
                                                                                (leaveDate, dateIndex) => (
                                                                                    <ListItem key={dateIndex}>
                                                                                        <ListItemText>
                                                                                            {`${getFormattedDate(leaveDate?.date)} ${leaveDate?.leave_duration ===
                                                                                                "first_half"
                                                                                                ? "(1st Half)"
                                                                                                : leaveDate?.leave_duration ===
                                                                                                    "second_half"
                                                                                                    ? "(2nd Half)"
                                                                                                    : ""
                                                                                                }`}
                                                                                        </ListItemText>
                                                                                    </ListItem>
                                                                                )
                                                                            )}
                                                                        </List>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <i
                                                                    style={{
                                                                        height: 18,
                                                                        maxWidth: 18,
                                                                        flex: "0 0 18px",
                                                                    }}
                                                                >
                                                                    <InfoRoundedIcon
                                                                        color="secondary"
                                                                        sx={{ fontSize: 18 }}
                                                                    />
                                                                </i>
                                                            </HtmlTooltip>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            width: '200px',
                                                            minWidth: '200px',
                                                            maxWidth: '200px',
                                                        }}
                                                        onClick={() => handleViewLeave(leaveDetail)}>
                                                        <Typography
                                                            color="dark.500"
                                                            fontSize={10}
                                                            lineHeight="15px"
                                                            letterSpacing="0.17px"
                                                            fontWeight={500}
                                                            mb="6px"
                                                        >
                                                            Applied on
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="dark.800"
                                                            lineHeight="14px"
                                                            letterSpacing="0.17px"
                                                            fontWeight={500}
                                                            whiteSpace="nowrap"
                                                        >
                                                            {getApplyDateTime((leaveDetail?.createdAt || leaveDetail?.apply_date), "DD/MM/YYYY")}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            width: '170px',
                                                            minWidth: '170px',
                                                            maxWidth: '170px',
                                                        }}
                                                        onClick={() => handleViewLeave(leaveDetail)}>
                                                        {isCreditEnable(leaveDetail) ? <Typography
                                                            variant="body2"
                                                            color="dark.800"
                                                            lineHeight="14px"
                                                            letterSpacing="0.17px"
                                                            fontWeight={500}
                                                            whiteSpace="nowrap"
                                                        >
                                                            {calculateTotalCredits(leaveDetail?.WfhDays)}{" "}
                                                            Credits Used
                                                        </Typography> : null}
                                                    </TableCell>
                                                    {hasAttachmentInList() ?
                                                        <TableCell
                                                            sx={{
                                                                width: '70px',
                                                                minWidth: '70px',
                                                                maxWidth: '70px',
                                                            }}>
                                                            {leaveDetail?.attachment_references > 0 ? <Box
                                                                sx={{
                                                                    alignItems: 'center',
                                                                    color: "secondary",
                                                                    display: "flex",
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{ alignItems: 'center' }}
                                                                >
                                                                    <Typography
                                                                        variant="body1"
                                                                        color="secondary"
                                                                        fontSize={14}
                                                                        fontWeight={500}
                                                                        sx={{
                                                                            lineHeight: "24px",
                                                                            letterSpacing: "0.17px",
                                                                            marginBottom: "2px",
                                                                            display: "block",
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                height: "18px",
                                                                                width: "18px",
                                                                                display: "flex",
                                                                            }}
                                                                        >
                                                                            {ICONS.Attachment}
                                                                        </i>
                                                                    </Typography>
                                                                </Box>
                                                                <Box
                                                                    sx={{ alignItems: 'center' }}
                                                                >
                                                                    <Typography
                                                                        variant="body1"
                                                                        color="secondary"
                                                                        fontSize={14}
                                                                        fontWeight={500}
                                                                        sx={{
                                                                            lineHeight: "24px",
                                                                            letterSpacing: "0.17px",
                                                                            marginBottom: "2px",
                                                                            display: "block",
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        {leaveDetail?.attachment_references}
                                                                    </Typography>
                                                                </Box>
                                                            </Box> : null}
                                                        </TableCell>
                                                        : null}
                                                    <TableCell
                                                        sx={{
                                                            width: '120px',
                                                            minWidth: '120px',
                                                            maxWidth: '120px',
                                                            padding: "10px 16px",
                                                        }}
                                                        onClick={() => handleViewLeave(leaveDetail)}
                                                    >
                                                        {leaveDetail?.status !== LEAVE_STATUS.Pending ?
                                                            <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography
                                                                            color="text.white" fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} mb={0.5} component={"div"}>
                                                                            <Typography fontSize={14} fontWeight={600} lineHeight={"24px"} letterSpacing={"0.15px"} display={"inline-block"} mr={0.5}>
                                                                                {showInformationAuthority(leaveDetail?.processed_by) || "-"}
                                                                            </Typography>
                                                                            at {leaveDetail?.process_date ? `${getApplyDateTime(leaveDetail?.process_date, "hh:mm A")}, on ${getApplyDateTime(leaveDetail?.process_date, "DD/MM/YYYY (dddd)")}` : "-"}
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                {getLeaveStatus(leaveDetail?.status, "chip")}
                                                            </HtmlTooltip>
                                                            : getLeaveStatus(leaveDetail?.status, "chip")}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            width: '65px',
                                                            minWidth: '65px',
                                                            maxWidth: '65px',
                                                        }}
                                                    >
                                                        <IconButton
                                                            aria-label="more"
                                                            aria-haspopup="true"
                                                            onClick={handleClick(leaveDetail)}
                                                            sx={{
                                                                height: "30px",
                                                                width: "30px",
                                                                padding: "0px",
                                                                borderRadius: "4px",
                                                            }}
                                                            id="basic-button"
                                                            aria-controls={open ? 'basic-menu' : ""}
                                                            aria-expanded={open ? 'true' : "false"}
                                                        >
                                                            <i
                                                                style={{
                                                                    height: "20px",
                                                                    width: "20px",
                                                                    display: "flex",
                                                                }}
                                                            >
                                                                {ICONS.DotsHorizontal}
                                                            </i>
                                                        </IconButton>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorEl}
                                                            open={open && openElem?.id === leaveDetail?.id}
                                                            onClose={handleClose}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}
                                                        >
                                                            <MenuItem onClick={() => handleViewLeave(openElem)}>View</MenuItem>
                                                            <MenuItem disabled={!checkApplicationEnable(leaveDetail) || leaveDetail?.status !== "pending"} onClick={() => handleEditLeave(openElem)}>Edit</MenuItem>
                                                            <MenuItem disabled={!checkApplicationEnable(leaveDetail)} onClick={() => handleCancelRequestModal(openElem)}>Cancel Request</MenuItem>
                                                            {/* {leaveDetail?.status !== LEAVE_STATUS.Cancelled ? <MenuItem disabled={!checkApplicationEnable(leaveDetail)} onClick={() => handleDeleteRequestModal(openElem)}>Delete Request</MenuItem> : null} */}
                                                        </Menu>
                                                    </TableCell>
                                                </TableRow>
                                            )) :
                                                <TableRow
                                                    key={index}
                                                    sx={{
                                                        td: {
                                                            border: 0,
                                                            bgcolor: "white",
                                                            textAlign: 'center',
                                                            padding: { xs: "8px 8px", md: "8px 16px" },
                                                        },
                                                        'td:first-of-type': {
                                                            borderRadius: "4px 4px 4px 4px",
                                                        },
                                                        cursor: 'default'
                                                    }}
                                                >
                                                    <TableCell sx={{ height: '60px' }}>
                                                        <Typography
                                                            color="dark.800"
                                                            fontSize={14}
                                                            lineHeight="24px"
                                                            fontWeight={500}
                                                        >
                                                            No Request Available
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    ))
                    :
                    <EmptyPage
                        onClick={() => navigate(URLS.ApplyWorkFromHome)}
                        buttontext="Apply WFH"
                        title="No request available"
                        buttonColor="secondary"
                    />
                }
            </Grid>
        </Grid>
    );
};

export default WHFListTable;