/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react';
import { Grid, IconButton, Modal, Stack, Typography } from '@mui/material'
import API from "../../../../axios/api";
import axiosInstance from "../../../../axios";
import CircularLoader from '../../../../components/CircularLoader';
import { getInputId } from '../../../../utils';
import SearchBoxInput from '../../../../components/SearchBoxInput';
import useDebounce from '../../../../hook/useDebounce';
import { usePageTitle } from '../../../../hook/useTitle';
import TablePagination from '../../../../components/TablePagination';
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from '../../../../components/Input';
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useDropzone } from 'react-dropzone';
import { useAlert } from '../../../../hook/useAlert';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import Button from '../../../../components/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
};

const CampaignsContactList = () => {
    const showAlert = useAlert();
    const { setPageTitle } = usePageTitle();
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: false,
    });

    const [contactsData, setContactsData] = useState([]);
    const [userDataCount, setUserDataCount] = useState(0);
    const [searchString, setSearchString] = useState("");
    const debouncedSearchTerm = useDebounce(searchString);
    const [pageBeenRendered, setPageBeenRendered] = useState(false);
    const [openElem, setOpenElem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [editableUserModalData, setEditableUserModalData] = useState(null);
    const [showModalForImportContacts, setShowModalForImportContacts] = useState(false);
    const [showIsAddEditUserModal, setShowIsAddEditUserModal] = useState(false);
    const [isDeleteIsOpen, setIsDeleteIsOpen] = useState(false);
    const [deletedContactData, setDeletedContactData] = useState(null);

    setPageTitle("Campaigns - Contacts");

    const [params, setParams] = useState({
        page: 0,
        limit: 100,
        search: null
    });

    useEffect(() => {
        setUserDataCount(0);
    }, [params]);

    useEffect(() => {
        if (pageBeenRendered) {
            setParams((prev) => ({
                ...prev,
                search: debouncedSearchTerm,
            }));
        }
        setPageBeenRendered(true)
    }, [debouncedSearchTerm]);


    useEffect(() => {
        getAllContactsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const getAllContactsList = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.post(
                API.getAllContactsListPaginate,
                params
            );

            if (response?.status === 200) {
                setContactsData(response?.data?.data?.rows);
                setUserDataCount(response?.data?.data?.count);
            } else {
                setContactsData([]);
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const formikForAddEditContacts = useFormik({
        initialValues: editableUserModalData ? editableUserModalData :
            {
                name: "",
                email: "",
            },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            email: Yup.string().email("Invalid email address").required("Required"),
        }),
        onSubmit: async (values) => {
            try {
                setLoading((prev) => ({ ...prev, formSubmitting: true }));
                if (!editableUserModalData) {
                    const response = await axiosInstance.post(API.createContact, values);
                    if (response?.status === 200) {
                        if (!response?.data?.error) {
                            setContactsData(prev => [response?.data?.data, ...prev]);
                            setShowIsAddEditUserModal(false);
                        } else {
                            showAlert(response?.data?.error, "error");
                        }
                    }
                } else {
                    const response = await axiosInstance.put(API.updateContact(editableUserModalData?.id), values);
                    if (response?.status === 200) {
                        setContactsData(prev => prev.map((contact) => contact?.id === editableUserModalData?.id ? values : contact));
                        setEditableUserModalData(null);
                        setShowIsAddEditUserModal(false);
                    }
                }
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
            } catch (error) {
                console.error("Error:", error);
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
            }
        },
    });

    const formikForImportContacts = useFormik({
        initialValues: {
            file: null
        },
        validationSchema: Yup.object().shape({
            file: Yup.mixed().required('File is required'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading((prev) => ({ ...prev, formSubmitting: true }));
                const config = {
                    headers: { "content-type": "multipart/form-data-1" },
                };
                const formData = new FormData();
                formData.append("file", values.file);
                const response = await axiosInstance.post(API.importContactsData, formData, config);
                if (response.status === 200) {
                    getAllContactsList();
                    formikForImportContacts.resetForm();
                    setShowModalForImportContacts(false);
                    showAlert(response?.data.message);
                    setLoading((prev) => ({ ...prev, formSubmitting: false }));
                }
            } catch (error) {
                console.error("Error:", error);
                showAlert("Failed to upload file. Please try again.", "error");
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
            }
        },
    });

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        accept: {
            "text/csv": [".csv"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        },
        maxFiles: 1,
        onDrop: async (acceptedFiles) => {
            if (acceptedFiles.length === 0) return;

            const file = acceptedFiles[0];
            if (file) {
                formikForImportContacts.setFieldValue('file', file);
            }
        },
    });

    const handleClick = (elem) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };

    const handleChangePage = (event, newPage) => {
        setParams((prev) => ({
            ...prev,
            page: newPage,
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setParams((prev) => ({
            ...prev,
            limit: event.target.value,
            page: 0,
        }));
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };


    const handleSearch = (value) => {
        setSearchString(value);
    };

    const handleEditContact = (user) => {
        setEditableUserModalData(user);
        setShowIsAddEditUserModal(true);
        handleClose();
    };

    const handleSubmitDelete = async () => {
        try {
            setLoading((prev) => ({ ...prev, formSubmitting: true }));
            const response = await axiosInstance.delete(API.deleteContact(deletedContactData?.id));
            if (response?.status === 200) {
                setContactsData(prev => prev.filter((contact) => contact?.id !== deletedContactData?.id));
                setDeletedContactData(null);
                setIsDeleteIsOpen(false);
                showAlert(response?.data?.message)
            }
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        } catch (error) {
            console.error("Error:", error);
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
    }
    return (
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} sx={{ background: '#ffffff' }} flex={1} overflow="auto">
                {(loading?.userRoleLoading || loading?.years) ?
                    <CircularLoader height="500px" /> :
                    <Box borderRadius={0.5} border="1px solid #E0E0E0" overflow="hidden">
                        <Grid container p={{ xs: 1, xl: 2 }} display="flex" justifyContent="space-between" borderBottom="1px solid #E0E0E0">
                            <Grid item display="flex">
                                <Stack direction="row" spacing={2}>
                                    <Grid item width={{ xs: 180, xl: 230 }}>
                                        <SearchBoxInput
                                            label="Search"
                                            variant="standard"
                                            placeholder="Name, email, etc..."
                                            handleSearch={handleSearch}
                                            value={searchString}
                                            isUseDebounce={false}
                                            isOverwritePlaceholder={true}
                                        />
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Grid item display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
                                <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size="small"
                                        sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                        onClick={() => { setShowIsAddEditUserModal(true); }}
                                    >

                                        Add Contact
                                    </Button>
                                </Stack>
                                <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size="small"
                                        sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                        onClick={() => setShowModalForImportContacts(true)}
                                    >

                                        Import Contacts
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Paper sx={{ width: '100%', borderRadius: '0px', boxShadow: 'none', }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                >
                                    <TableHead>
                                        <TableRow sx={{
                                            whiteSpace: 'nowrap',
                                            'th': {
                                                bgcolor: "bgColor", padding: "8px"
                                            }
                                        }}>
                                            <TableCell sx={{ width: '100px', maxWidth: '100px', minWidth: '100px', paddingRight: '0px !important' }}>#</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell sx={{ textAlign: 'center', width: '48px', maxWidth: '48px', minWidth: '48px' }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading.pageLoading ?
                                            <CircularLoader variant="table" rows={params.limit} cols={8} /> :
                                            contactsData?.length ? contactsData?.map((user, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={15} fontWeight={500} color="dark.800" sx={{ lineHeight: '4px', letterSpacing: '0.17px' }}>{index + 1}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={15} fontWeight={500} color="dark.800" sx={{ lineHeight: '4px', letterSpacing: '0.17px' }}>{user?.name}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={15} fontWeight={500} color="dark.800" sx={{ lineHeight: '4px', letterSpacing: '0.17px' }}>{user?.email}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center', width: '100px', maxWidth: '100px', minWidth: '100px', paddingLeft: '0px !important', paddingRight: '0px !important', display: "flex", gap: 1 }}>
                                                                <IconButton
                                                                    aria-label="edit"
                                                                    size="small"
                                                                    color="secondary"
                                                                    onClick={() => handleEditContact(user)}
                                                                >
                                                                    <EditIcon fontSize="small" />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    size="small"
                                                                    color="secondary"
                                                                    onClick={() => { setIsDeleteIsOpen(true); setDeletedContactData(user); handleClose(); }}
                                                                >
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>)
                                            }) : (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={8}
                                                        align="center"
                                                        sx={{ padding: "12px 16px;" }}
                                                    >
                                                        <Typography
                                                            color={"secondary"}
                                                            display={"flex"}
                                                            alignItems={"center"}
                                                            justifyContent={"center"}
                                                            fontSize={13}
                                                            sx={{ opacity: "0.5", height: "236px" }}
                                                        >
                                                            No records found.
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                count={userDataCount}
                                rowsPerPageOptions={[100, 200, 500]}
                                rowsPerPage={params.limit}
                                page={params.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                }
            </Box>

            {/* Modal for Import contacts */}
            <Modal
                open={showModalForImportContacts}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '> div': {
                        overflow: 'hidden',
                        '> div': {
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    }
                }}
            >
                <Box sx={[style, {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto !important",
                }]}>
                    <form onSubmit={formikForImportContacts.handleSubmit} noValidate>
                        <Box>
                            <Grid item py={2} px={3} borderBottom="1px solid #E2E4EC" sx={{
                                position: "sticky",
                                bottom: 0,
                                left: 0,
                                background: "white",
                            }}>
                                <Typography fontSize={20} lineHeight={'32px'} letterSpacing={'0.15px'} fontWeight={500}>Import Contacts</Typography>
                            </Grid>
                        </Box>
                        <Box
                            sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 }, width: "100%" }}
                            boxShadow={1}
                        >
                            <Stack spacing={3} alignItems="flex-start">
                                <Box mb={3}>
                                    <Box>
                                        <Box
                                            {...getRootProps({ className: "dropzone" })}
                                            display="flex"
                                            alignItems="center"
                                            sx={{ border: "1px dashed black", borderRadius: "5px", padding: "20px", cursor: "pointer" }}
                                        >
                                            <input {...getInputProps()} />
                                            <i
                                                style={{
                                                    height: "40px",
                                                    maxWidth: "40px",
                                                    flex: "0 0 40px",
                                                    padding: "10px",
                                                }}
                                            >
                                                <UploadFileOutlinedIcon color="primary" sx={{ fontSize: "20px" }} />
                                            </i>
                                            {formikForImportContacts.values.file ?
                                                <Box ml={1}>
                                                    <Typography
                                                        color="primary"
                                                        fontSize={14}
                                                        fontWeight={400}
                                                        mr={0.5}
                                                        sx={{
                                                            display: "inline-block",
                                                            lineHeight: "24px",
                                                            letterSpacing: "0.15px",
                                                            textDecoration: "underline",
                                                        }}
                                                    >
                                                        {formikForImportContacts.values.file?.name}
                                                    </Typography>
                                                </Box> :
                                                <Box ml={1}>
                                                    <Typography
                                                        color="primary"
                                                        fontSize={14}
                                                        fontWeight={400}
                                                        mr={0.5}
                                                        sx={{
                                                            display: "inline-block",
                                                            lineHeight: "24px",
                                                            letterSpacing: "0.15px",
                                                            textDecoration: "underline",
                                                        }}
                                                    >
                                                        Click to upload
                                                    </Typography>
                                                    <Typography
                                                        color="dark.800"
                                                        fontSize={14}
                                                        fontWeight={400}
                                                        sx={{
                                                            display: "inline-block",
                                                            lineHeight: "24px",
                                                            letterSpacing: "0.15px",
                                                        }}
                                                    >
                                                        or drag and drop
                                                    </Typography>
                                                    <Typography
                                                        color="dark.800"
                                                        fontSize={11}
                                                        fontWeight={400}
                                                        mt={0.5}
                                                        sx={{
                                                            display: "block",
                                                            lineHeight: "15px",
                                                            letterSpacing: "0.17px",
                                                        }}
                                                    >
                                                        Supported formats: XLSX or CSV
                                                    </Typography>
                                                </Box>}
                                        </Box>
                                    </Box>
                                    {formikForImportContacts.errors.file ? <Typography sx={{ color: "red", fontSize: "12px" }}>{formikForImportContacts.errors.file}</Typography> : null}
                                </Box>
                            </Stack>
                        </Box>
                        <Grid item py={2} px={3} borderTop="1px solid #E2E4EC">
                            <Stack spacing={1} direction="row">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    fontWeight="500"
                                    sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                    type='submit'
                                    isButtonLoading={loading.formSubmitting}

                                >
                                    Upload
                                </Button>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    sx={{ textTransform: "none" }}
                                    size="medium"
                                    fontWeight="500"
                                    onClick={() => { setShowModalForImportContacts(false); }}
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        </Grid>
                    </form>
                </Box>
            </Modal>

            {/* Modal for Add/edit contacts */}
            <Modal
                open={showIsAddEditUserModal}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '> div': {
                        overflow: 'hidden',
                        '> div': {
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    }
                }}
            >
                <Box sx={[style, {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto !important",
                }]}>
                    <form onSubmit={formikForAddEditContacts.handleSubmit} noValidate>
                        <Box>
                            <Grid item py={2} px={3} borderBottom="1px solid #E2E4EC" sx={{
                                position: "sticky",
                                bottom: 0,
                                left: 0,
                                background: "white",
                            }}>
                                <Typography fontSize={20} lineHeight={'32px'} letterSpacing={'0.15px'} fontWeight={500}>{editableUserModalData ? "Edit Contact" : "Add Contact"}</Typography>
                            </Grid>
                        </Box>
                        <Box
                            sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 }, width: "100%" }}
                            boxShadow={1}
                        >
                            <Stack spacing={3} alignItems="flex-start">
                                <Input
                                    id={getInputId("name")}
                                    name="name"
                                    label="Name"
                                    variant="standard"
                                    type="text"
                                    sx={{ width: "100%" }}
                                    placeholder={"name"}
                                    isCapitalizeFirstLatter={true}
                                    value={formikForAddEditContacts.values.name}
                                    error={
                                        formikForAddEditContacts.touched.name &&
                                        Boolean(formikForAddEditContacts.errors.name)
                                    }
                                    helperText={
                                        formikForAddEditContacts.touched.name && formikForAddEditContacts.errors.name
                                    }
                                    {...formikForAddEditContacts.getFieldProps("name")}
                                />
                                <Input
                                    id={getInputId("email")}
                                    name="email"
                                    label="Email address"
                                    variant="standard"
                                    type="email"
                                    sx={{ width: "100%" }}
                                    value={formikForAddEditContacts.values.email}
                                    placeholder={"email address"}
                                    disabled={editableUserModalData}
                                    error={
                                        formikForAddEditContacts.touched.email && Boolean(formikForAddEditContacts.errors.email)
                                    }
                                    helperText={formikForAddEditContacts.touched.email && formikForAddEditContacts.errors.email}
                                    {...formikForAddEditContacts.getFieldProps("email")}
                                />
                            </Stack>
                        </Box>
                        <Grid item py={2} px={3} borderTop="1px solid #E2E4EC">
                            <Stack spacing={1} direction="row">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    fontWeight="500"
                                    sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                    type='submit'
                                    isButtonLoading={loading.formSubmitting}
                                >
                                    {editableUserModalData ? "Update Contact" : "Create Contact"}
                                </Button>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    sx={{ textTransform: "none" }}
                                    size="medium"
                                    fontWeight="500"
                                    onClick={() => { setShowIsAddEditUserModal(false); setEditableUserModalData(null); formikForAddEditContacts.resetForm(); }}
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        </Grid>
                    </form>
                </Box>
            </Modal>
            <ConfirmationModal
                isOpen={isDeleteIsOpen}
                title={'Contact'}
                deletableDataName={deletedContactData?.name}
                dataContentName={'Contact'}
                handleClose={() => setIsDeleteIsOpen(false)}
                onConfirm={handleSubmitDelete}
                onCancel={() => setIsDeleteIsOpen(false)}
                isButtonLoading={loading.formSubmitting}
                subList={<span>This will remove <b>{deletedContactData?.name}</b> from your contact list.</span>}
            />
        </Box>
    );
};

export default CampaignsContactList;
