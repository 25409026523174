import React from "react";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "../../../../components/Input";
import {
  calculateLeavePerMonth,
  generateUniqueId,
  getDaysInYear,
  getInputId,
  isValidDays,
  isValidEncashDays,
  secondsToTime,
  timeToHours,
  timeToSeconds,
  titleCase,
} from "../../../../utils";
import { styled } from "@mui/styles";
import dayjs from "dayjs";
import moment from "moment";
import CustomTimePicker from "../../../../components/CustomTimePicker";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const LeaveTypeForm = ({
  work_type_settings,
  handleChange,
  formData,
  setFormData,
  constantType,
  setErrors,
  errors,
  setIsAnyError,
  setIsEditField
}) => {
  const findIndexWorkType = formData?.work_type_settings?.findIndex(
    (type) => type?.id === work_type_settings?.id
  );
  const errorWorkTypeIndex = errors?.findIndex((value) => value?.id === work_type_settings?.id);
  const workTypeData = formData?.work_type_settings[findIndexWorkType];

  let maxDays = getDaysInYear();
  let minDays = 0;

  let initialLeaveTypeData = {
    id: null,
    leave_type_id: null,
    monthly_days: null,
    advance_days: null,
    max_leave_encashment: null,
    is_visible: true,
    unique_id: null,
  };

  const findIndexWithUid = (array, unique_id) => {
    return array.findIndex((data) => data?.unique_id === unique_id);
  };

  const validateLeaveTypes = (event, leaveIndex, unique_id) => {
    const { name, value } = event.target;
    let updatedErrors = [...errors];
    let existingObject = updatedErrors[errorWorkTypeIndex][
      "leave_type_settings"
    ]?.find((value) => value?.unique_id === unique_id);
    if (!existingObject) {
      updatedErrors[errorWorkTypeIndex]["leave_type_settings"].push({
        ...initialLeaveTypeData,
        unique_id,
      });
    }
    if (name === "monthly_days" && formData?.leave_frequency === "monthly") {
      updatedErrors[errorWorkTypeIndex]["leave_type_settings"][leaveIndex][
        name
      ] = !Boolean(parseInt(value))
          ? "Required"
          : !isValidDays(parseInt(value))
            ? "Please enter valid days which is divisible by 12 or 6"
            : "";
    } else if (
      name === "monthly_days" &&
      formData?.leave_frequency !== "monthly"
    ) {
      updatedErrors[errorWorkTypeIndex]["leave_type_settings"][leaveIndex][
        name
      ] = !Boolean(parseInt(value)) && parseInt(value) !== 0 ? "Required" : "";
    } else if (name === "max_leave_encashment") {
      updatedErrors[errorWorkTypeIndex]["leave_type_settings"][leaveIndex][
        name
      ] =
        !Boolean(parseInt(value)) && parseInt(value) !== 0
          ? "Required"
          : !isValidEncashDays(
            formData,
            findIndexWorkType,
            parseInt(value),
            unique_id
          )
            ? "Please enter valid encash days which is less than or equal to yearly leave"
            : "";
    } else {
      updatedErrors[errorWorkTypeIndex]["leave_type_settings"][leaveIndex][
        name
      ] = !Boolean(parseInt(value)) && parseInt(value) !== 0 ? "Required" : "";
    }
    setIsAnyError(false)
    setErrors(updatedErrors);
  };

  const handleChangeLeaveTypes = (event, leaveIndex, unique_id) => {
    setIsEditField(true);
    validateLeaveTypes(event, leaveIndex, unique_id);

    const { name, value } = event.target;
    const leaveTypeSettings = workTypeData?.leave_type_settings;
    leaveTypeSettings[leaveIndex] = {
      ...leaveTypeSettings[leaveIndex],
      [name]: value,
      is_changed: true,
    };
    let updatedLength = leaveTypeSettings?.filter(value => !value?.is_deleted)?.length;
    if (value !== "" && updatedLength <= (constantType?.leaveTypes?.length - 1) && leaveIndex === leaveTypeSettings.length - 1) {
      leaveTypeSettings.push({
        ...initialLeaveTypeData,
        unique_id: generateUniqueId(),
        is_visible: true,
        is_changed: false
      });
    }

    let updatedFormData = { ...formData };
    updatedFormData["work_type_settings"][findIndexWorkType][
      "leave_type_settings"
    ] = leaveTypeSettings;
    setFormData(updatedFormData);
  };

  const handleChangeSpecialRequest = (event, requestIndex) => {
    setIsEditField(true);
    const { name, value: Value } = event.target;
    let nextIndex = requestIndex + 1;

    setFormData((prev) => {
      const updatedRequestType = [...prev.work_type_settings];
      let lengthOfLeaveTypeArray = updatedRequestType[findIndexWorkType].special_requests?.filter((value) => !!value?.is_visible)?.length;

      updatedRequestType[findIndexWorkType].special_requests[requestIndex] = {
        ...updatedRequestType[findIndexWorkType].special_requests[requestIndex],
        [name]: Value,
        is_changed: true,
      };

      if (nextIndex < lengthOfLeaveTypeArray) {
        updatedRequestType[findIndexWorkType].special_requests[nextIndex] = {
          ...updatedRequestType[findIndexWorkType].special_requests[nextIndex],
          is_visible: true,
          is_changed: false,
        };
      }

      if (lengthOfLeaveTypeArray < constantType?.specialRequestTypes?.length) {
        let lastData = updatedRequestType[findIndexWorkType]?.special_requests[updatedRequestType[findIndexWorkType]?.special_requests?.length - 1];
        if (lastData?.allotment >= 0 && !!lastData?.name) {
          updatedRequestType[findIndexWorkType]?.special_requests?.push({
            id: null,
            name: null,
            allotment: null,
            is_visible: true,
            unique_id: generateUniqueId(),
            is_changed: false,
          })
        }
      }

      return {
        ...prev,
        work_type_settings: updatedRequestType,
      };
    });
    validateSpacialRequest(event, requestIndex);
  };

  const validateSpacialRequest = (event, requestIndex) => {
    const { name, value } = event.target;
    let updatedErrors = [...errors];
    let isExists = updatedErrors[errorWorkTypeIndex]["special_requests"][requestIndex];
    if (!!isExists) {
      updatedErrors[errorWorkTypeIndex]["special_requests"][requestIndex][name] = !value ? name === "allotment" && value === 0 ? "" : "Required" : "";
    }
    setIsAnyError(false)
    setErrors(updatedErrors);
  }

  const handleDeleteChangeLeaveTypes = (unique_id) => {
    setIsEditField(true);
    setFormData((prev) => {
      const updatedWorkTypes = prev.work_type_settings.map(
        (workType, index) => {
          if (index === findIndexWorkType) {
            const updatedLeaveTypes = workType.leave_type_settings?.map(
              (value) => {
                if (value?.unique_id === unique_id) {
                  if (value?.id === null) {
                    return null;
                  } else {
                    return { ...value, is_deleted: true, is_visible: false };
                  }
                } else {
                  return value;
                }
              }
            );
            let filteredData = updatedLeaveTypes?.filter(value => !!value);
            let deletedList = filteredData?.filter(value => value?.is_deleted);
            let lastEle = filteredData[filteredData?.length - 1];
            if ((filteredData?.length - deletedList?.length) < 3 && lastEle?.leave_type_id !== null && lastEle?.advance_days !== null && lastEle?.max_leave_encashment !== null && lastEle?.monthly_days !== null) {
              let initialData = {
                advance_days: null,
                id: null,
                is_changed: false,
                is_visible: true,
                leave_type_id: null,
                max_leave_encashment: null,
                monthly_days: null,
                unique_id: generateUniqueId(),
                is_initial: true,
              };
              filteredData.push(initialData);
            }
            return { ...workType, leave_type_settings: filteredData };
          }
          return workType;
        }
      );
      return { ...prev, work_type_settings: updatedWorkTypes };
    });
  };

  const handleDeleteChangeRequestTypes = (unique_id) => {
    setIsEditField(true);
    let copyOfFormData = { ...formData };
    let specialRequests = [...copyOfFormData?.work_type_settings[findIndexWorkType]?.special_requests];
    let updatedSpecialRequests = specialRequests?.map((value, index) => {
      if (value?.unique_id === unique_id) {
        if (!!value?.id) {
          return {
            ...value,
            is_deleted: true,
            is_visible: false
          }
        }
        else {
          return {
            ...value,
            name: null,
            allotment: null,
            is_deleted: true,
            is_visible: false,
          };
        }
      }
      else {
        return value;
      }
    });
    let filteredData = updatedSpecialRequests?.filter(value => !!value);
    let deletedList = filteredData?.filter(value => value?.is_deleted);
    let lastEle = filteredData[filteredData?.length - 1];
    if ((filteredData?.length - deletedList?.length) < 7 && lastEle?.name !== null && lastEle?.allotment !== null) {
      let initialData = {
        name: null,
        allotment: null,
        point: null,
        is_visible: true,
        is_changed: false,
        id: null,
        is_initial: true,
        unique_id: generateUniqueId(),
      };
      filteredData.push(initialData);
    }
    let updatedWorkTypes = formData?.work_type_settings?.map((value, index) => {
      if (index === findIndexWorkType) {
        return {
          ...value,
          special_requests: filteredData,
        };
      }
      else {
        return value;
      }
    });
    setFormData((prev) => ({ ...prev, work_type_settings: updatedWorkTypes }));
  };

  const handleBlurWorkTypeSetting = (event) => {
    let updatedErrors = [...errors];
    const { name, value } = event.target;
    updatedErrors[errorWorkTypeIndex][name] = !!value ? "" : "Required";
    setIsAnyError(false)
    setErrors(updatedErrors);
  };

  const handleChangeWorkTypeSetting = (event) => {
    setIsEditField(true);
    const { name, value } = event.target;
    const updateFormDataValue = (prev) => {
      const findIndex = prev.work_type_settings.findIndex(
        (type) => type?.name === work_type_settings?.name
      );

      const updatedWorkTypes = [...prev.work_type_settings];
      updatedWorkTypes[findIndex] = {
        ...updatedWorkTypes[findIndex],
        [name]: parseInt(value),
      };

      return { ...prev, work_type_settings: updatedWorkTypes };
    };

    let updatedErrors = [...errors];
    let data = updateFormDataValue(formData);

    updatedErrors[errorWorkTypeIndex][name] = !!value || value === 0 ? "" : "Required";
    setIsAnyError(false)
    setErrors(updatedErrors);
    setFormData(data);
  };

  const handleChangeTimePicker = (newTime, name) => {
    let updatedWorkTypes = formData?.work_type_settings?.map((value, index) => {
      if (index === findIndexWorkType) {
        return {
          ...value,
          [name]: timeToSeconds(moment(new Date(dayjs(newTime))).format("hh:mm")),
        };
      } else {
        return value;
      }
    });
    setFormData((prev) => ({ ...prev, work_type_settings: updatedWorkTypes }));
  };

  const handleBlurTimePicker = (newTime, name) => {
    let time = secondsToTime(formData?.work_type_settings[findIndexWorkType]?.[name]);
    let updatedErrors = [...errors];
    const errorIndex = errorWorkTypeIndex;

    const validateHalfDay = () => {
      let halfDayTime = secondsToTime(formData?.work_type_settings[findIndexWorkType]?.min_working_hrs_half_day);
      if (halfDayTime && halfDayTime !== 'Invalid date' && halfDayTime !== '00:00') {
        const halfDayTotalHours = timeToHours(halfDayTime);
        if (halfDayTotalHours > 10) {
          updatedErrors[errorIndex]['min_working_hrs_half_day'] = "Time must be less than 10 hours";
        } else {
          updatedErrors[errorIndex]['min_working_hrs_half_day'] = "";
        }
      } else {
        updatedErrors[errorIndex]['min_working_hrs_half_day'] = "Required";
      }
    };

    const validateFullDay = () => {
      let fullDayTime = secondsToTime(formData?.work_type_settings[findIndexWorkType]?.min_working_hrs_full_day);
      if (fullDayTime && fullDayTime !== 'Invalid date' && fullDayTime !== '00:00') {
        const fullDayTotalHours = timeToHours(fullDayTime);
        if (fullDayTotalHours > 10) {
          updatedErrors[errorIndex]['min_working_hrs_full_day'] = "Time must be less than 10 hours";
        } else {
          updatedErrors[errorIndex]['min_working_hrs_full_day'] = "";
        }
      } else {
        updatedErrors[errorIndex]['min_working_hrs_full_day'] = "Required";
      }
    };

    // Validate current field
    if (time === 'Invalid date' || !time || time === '00:00') {
      updatedErrors[errorIndex][name] = "Required";
    } else {
      const totalHours = timeToHours(time);

      if (totalHours > 10) {
        updatedErrors[errorIndex][name] = "Time must be less than 10 hours";
      } else {
        updatedErrors[errorIndex][name] = "";

        if (name === 'min_working_hrs_half_day') {
          validateFullDay();
        }

        if (name === 'min_working_hrs_full_day') {
          validateHalfDay();
        }

        if (formData?.work_type_settings[findIndexWorkType]?.min_working_hrs_half_day && formData?.work_type_settings[findIndexWorkType]?.min_working_hrs_full_day) {
          // Cross-validation logic
          let halfDayTime = secondsToTime(formData?.work_type_settings[findIndexWorkType]?.min_working_hrs_half_day);
          let fullDayTime = secondsToTime(formData?.work_type_settings[findIndexWorkType]?.min_working_hrs_full_day);

          if (halfDayTime && fullDayTime && halfDayTime !== 'Invalid date' && fullDayTime !== 'Invalid date') {
            const halfDayTotalHours = timeToHours(halfDayTime);
            const fullDayTotalHours = timeToHours(fullDayTime);

            if (halfDayTotalHours >= fullDayTotalHours) {
              updatedErrors[errorIndex]['min_working_hrs_half_day'] = "Half day time must be less than full day time";
              updatedErrors[errorIndex]['min_working_hrs_full_day'] = "Full day time must be greater than half day time";
            } else {
              updatedErrors[errorIndex]['min_working_hrs_half_day'] = "";
              updatedErrors[errorIndex]['min_working_hrs_full_day'] = "";
            }
          }
        }
      }
    }
    setIsAnyError(false);
    setErrors(updatedErrors);
  };

  let totalLeaves = workTypeData?.leave_type_settings?.reduce(
    (accumulator, currentValue) =>
      parseInt(accumulator) + parseInt(currentValue?.monthly_days || 0),
    0
  );

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#3F5C76',
      color: '#ffffff',
      maxWidth: 310,
      padding: 12,
    },
  }));

  return (
    <>
      {formData?.enable_sys_generated_leave ?
        <Stack direction={"column"} mb={3}>
          <Grid display="flex" alignItems="center" mb={1}>
            <Typography color="dark.800" fontSize={20} mr={1} letterSpacing={"0.15px"} lineHeight={"30px"}>
              System Generated Leaves Criteria
            </Typography>
            <HtmlTooltip arrow placement="top"
              title={
                <React.Fragment>
                  <Typography color={"white"} textAlign={"center"} fontSize={16} fontWeight={500} lineHeight={"24px"}>System will automatically creates a leave upon unavailability for the user.</Typography>
                </React.Fragment>
              }
            >
              <i style={{ width: '18px', height: '18px' }}><HelpOutlineIcon sx={{ fontSize: '18px', color: 'secondary.main' }} /></i>
            </HtmlTooltip>
          </Grid>
          <Typography color="dark.600" fontSize={12}>
            Leaves will be automatically generated if the required minimum hours for a half day or full day are not fulfilled.
          </Typography>
          <Grid container width={470} display={"flex"} gap={4} maxWidth="100%">
            <Grid item flex={1} maxWidth={214}>
              <CustomTimePicker
                value={(workTypeData?.min_working_hrs_half_day) || null}
                onChange={(e) =>
                  handleChangeTimePicker(e, "min_working_hrs_half_day")
                }
                label="Min. Hours For Half day"
                id="min_working_hrs_half_day"
                name="min_working_hrs_half_day"
                onBlur={(e) => handleBlurTimePicker(e, "min_working_hrs_half_day")}
                error={errors[errorWorkTypeIndex]?.min_working_hrs_half_day}
              />
            </Grid>
            <Grid item flex={1} maxWidth={214}>
              <CustomTimePicker
                value={workTypeData?.min_working_hrs_full_day || null}
                onChange={(e) =>
                  handleChangeTimePicker(e, "min_working_hrs_full_day")
                }
                label="Min. Hours For Full day"
                id="min_working_hrs_full_day"
                name="min_working_hrs_full_day"
                onBlur={(e) => handleBlurTimePicker(e, "min_working_hrs_full_day")}
                error={errors[errorWorkTypeIndex]?.min_working_hrs_full_day}
              />
            </Grid>
          </Grid>
        </Stack>
        : null}
      <Typography color="dark.800" fontSize={20} mb={1}>
        Leave Types
      </Typography>
      <Box p={3} bgcolor={"#F8F8F8"} mb={2}>
        <Stack spacing={{ xs: 1, md: 4 }}>
          {workTypeData?.leave_type_settings?.map(
            (leaveType, parentIndex, leaveArray) => {
              let leaveIndex = findIndexWithUid(
                leaveArray,
                leaveType?.unique_id
              );
              return (
                <React.Fragment key={parentIndex}>
                  {leaveType?.is_visible ? (
                    <Grid container key={parentIndex}>
                      <Grid item display={"flex"} flexWrap={{ xs: 'wrap', md: 'nowrap' }} flex={1}>
                        <Grid item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}>
                          <Input
                            id={getInputId("leave_type_id", parentIndex)}
                            select
                            label="Select Type"
                            type="select"
                            name="leave_type_id"
                            variant="standard"
                            sx={{ width: "100%" }}
                            onChange={(e) =>
                              handleChangeLeaveTypes(
                                e,
                                leaveIndex,
                                leaveType?.unique_id
                              )
                            }
                            onBlur={(e) =>
                              validateLeaveTypes(
                                e,
                                leaveIndex,
                                leaveType?.unique_id
                              )
                            }
                            InputLabelProps={{
                              shrink: Boolean(leaveType?.leave_type_id) || leaveType?.leave_type_id === 0,
                              htmlFor: getInputId("leave_type_id", parentIndex),
                              id: `label-${getInputId("leave_type_id", parentIndex)}`,
                              name: `label-${getInputId("leave_type_id", parentIndex)}`,
                            }}
                            value={Boolean(leaveType?.is_initial) && !Boolean(leaveType?.leave_type_id) ? "" : leaveType?.leave_type_id}
                            error={
                              !!errors[errorWorkTypeIndex]["leave_type_settings"][
                                parentIndex
                              ]?.leave_type_id
                            }
                            helperText={
                              errors[errorWorkTypeIndex]["leave_type_settings"][
                                parentIndex
                              ]?.leave_type_id
                            }
                            required={
                              !!(leaveType?.leave_type_id ||
                                leaveType?.advance_days ||
                                leaveType?.monthly_days ||
                                leaveType?.max_leave_encashment)
                            }>
                            {constantType?.leaveTypes?.map(
                              ({ id, name }, index) => (
                                <MenuItem
                                  key={index}
                                  value={id}
                                  disabled={leaveArray?.some(
                                    (data) => data?.leave_type_id === id && data?.is_visible === true
                                  )}>
                                  {titleCase(name)}
                                </MenuItem>
                              )
                            )}
                          </Input>
                        </Grid>
                        {!!leaveType?.leave_type_id || parentIndex === 0 ?
                          <>
                            <Grid item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}
                              sx={{
                                '.MuiTypography-body1': {
                                  fontSize: '12px',
                                  lineHeight: '19px',
                                  marginTop: '3px',
                                  color: 'dark.800'
                                }
                              }}>
                              <Input
                                id={getInputId("monthly_days", parentIndex)}
                                label={`Yearly Leave (Days)`}
                                type="number"
                                inputProps={{ min: minDays, max: maxDays }}
                                min={minDays}
                                max={maxDays}
                                isDays={true}
                                variant="standard"
                                name="monthly_days"
                                sx={{ width: "100%" }}
                                onChange={(e) =>
                                  handleChangeLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                onBlur={(e) =>
                                  validateLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                InputLabelProps={{
                                  shrink: Boolean(leaveType?.monthly_days) || leaveType?.monthly_days === 0,
                                  htmlFor: getInputId("monthly_days", parentIndex),
                                  id: `label-${getInputId("monthly_days", parentIndex)}`,
                                  name: `label-${getInputId("monthly_days", parentIndex)}`,
                                }}
                                value={leaveType?.monthly_days}
                                error={
                                  !!errors[errorWorkTypeIndex]["leave_type_settings"][
                                    parentIndex
                                  ]?.monthly_days
                                }
                                helperText={
                                  errors[errorWorkTypeIndex]["leave_type_settings"][
                                    parentIndex
                                  ]?.monthly_days
                                }
                                required={
                                  !!(leaveType?.leave_type_id ||
                                    leaveType?.advance_days ||
                                    leaveType?.monthly_days ||
                                    leaveType?.max_leave_encashment)
                                }
                              />
                              {leaveType?.monthly_days > 0 &&
                                formData?.leave_frequency === "monthly" ? (
                                <Typography>
                                  ={calculateLeavePerMonth(leaveType?.monthly_days)}{" "}
                                  {`${calculateLeavePerMonth(leaveType?.monthly_days) > 1 ? 'Leaves' : 'Leave'} / Month`}
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid item mr={3} flex={1}>
                              <Input
                                id={getInputId("advance_days", parentIndex)}
                                label="Notice Period (Days)"
                                type="number"
                                inputProps={{ min: minDays, max: maxDays }}
                                min={minDays}
                                max={maxDays}
                                isDays={true}
                                variant="standard"
                                sx={{ width: "100%" }}
                                name="advance_days"
                                onChange={(e) =>
                                  handleChangeLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                onBlur={(e) =>
                                  validateLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                InputLabelProps={{
                                  shrink: Boolean(leaveType?.advance_days) || leaveType?.advance_days === 0,
                                  htmlFor: getInputId("advance_days", parentIndex),
                                  id: `label-${getInputId("advance_days", parentIndex)}`,
                                  name: `label-${getInputId("advance_days", parentIndex)}`,
                                }}
                                value={leaveType?.advance_days}
                                error={
                                  !!errors[errorWorkTypeIndex]["leave_type_settings"][
                                    parentIndex
                                  ]?.advance_days
                                }
                                helperText={
                                  errors[errorWorkTypeIndex]["leave_type_settings"][
                                    parentIndex
                                  ]?.advance_days
                                }
                                required={
                                  !!(leaveType?.leave_type_id ||
                                    leaveType?.advance_days ||
                                    leaveType?.monthly_days ||
                                    leaveType?.max_leave_encashment)
                                }
                              />
                            </Grid>
                            <Grid item mr={3} flex={1}>
                              <Input
                                id={getInputId("max_leave_encashment", parentIndex)}
                                label="Max. Encash (Days)"
                                type="number"
                                inputProps={{ min: minDays, max: maxDays }}
                                min={minDays}
                                max={maxDays}
                                isDays={true}
                                variant="standard"
                                name="max_leave_encashment"
                                sx={{ width: "100%" }}
                                onChange={(e) =>
                                  handleChangeLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                onBlur={(e) =>
                                  validateLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                InputLabelProps={{
                                  shrink: Boolean(leaveType?.max_leave_encashment) || leaveType?.max_leave_encashment === 0,
                                  htmlFor: getInputId("max_leave_encashment", parentIndex),
                                  id: `label-${getInputId("max_leave_encashment", parentIndex)}`,
                                  name: `label-${getInputId("max_leave_encashment", parentIndex)}`,
                                }}
                                value={leaveType?.max_leave_encashment}
                                error={
                                  !!errors[errorWorkTypeIndex]["leave_type_settings"][
                                    parentIndex
                                  ]?.max_leave_encashment
                                }
                                helperText={
                                  errors[errorWorkTypeIndex]["leave_type_settings"][
                                    parentIndex
                                  ]?.max_leave_encashment
                                }
                                required={
                                  !!(leaveType?.leave_type_id ||
                                    leaveType?.advance_days ||
                                    leaveType?.monthly_days ||
                                    leaveType?.max_leave_encashment)
                                }
                              />
                            </Grid>
                          </>
                          : <>
                            <Grid item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}></Grid>
                            <Grid item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}></Grid>
                            <Grid item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}></Grid>
                          </>}
                        <Grid item mt={1} minWidth={40}>
                          {leaveType?.leave_type_id ?
                            <Box sx={{ minWidth: "40px" }}>
                              {leaveArray?.filter((data) => data?.is_visible)
                                ?.length > 1 ? (
                                <IconButton
                                  size="small"
                                  sx={{ width: "40px", height: "40px" }}
                                  onClick={() =>
                                    handleDeleteChangeLeaveTypes(
                                      leaveType?.unique_id
                                    )
                                  }
                                  disabled={workTypeData?.leave_type_settings?.filter((value) => !!value?.is_visible && !!value?.leave_type_id)?.length <= 1}>
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              ) : null}
                            </Box> : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </React.Fragment>
              );
            })}
        </Stack>
      </Box>
      {Boolean(totalLeaves) ? (
        <Box>
          <Typography color="dark.800" fontSize={20}>
            Total Yearly Paid Leaves <b>{totalLeaves}</b>
          </Typography>
          <Typography color="dark.600" fontSize={12} mb={3}>
            Above Total Yearly Paid Leaves are the sum of all the Yearly leaves
            for all leave types.
          </Typography>
        </Box>
      ) : null}
      <Grid container mb={4}>
        <Grid item mr={4} flex={1}>
          <Input
            id="monthly-max-leaves"
            label="Monthly Max Leaves for Dedicated Developer (Days)"
            variant="standard"
            name="max_monthly_leave_for_dd"
            sx={{ width: "50%" }}
            type="number"
            inputProps={{ min: minDays, max: maxDays }}
            min={minDays}
            max={maxDays}
            isDays={true}
            onChange={handleChangeWorkTypeSetting}
            onBlur={handleBlurWorkTypeSetting}
            value={workTypeData?.max_monthly_leave_for_dd}
            error={!!errors[errorWorkTypeIndex]?.["max_monthly_leave_for_dd"]}
            helperText={errors[errorWorkTypeIndex]?.["max_monthly_leave_for_dd"]}
          />
          <Box>

            <HtmlTooltip
              arrow
              title={
                <React.Fragment>
                  <Typography sx={{ fontSize: '13px !important' }} textAlign={"center"}>Individuals working on project in dedicated mode needs higher avalability to work</Typography>
                </React.Fragment>
              }
            >
              <Typography
                display="inline-block"
                color="primary"
                fontSize={12}
                sx={{ cursor: "pointer" }}
              >
                Help
              </Typography>
            </HtmlTooltip>
          </Box>
        </Grid>
      </Grid>
      {
        !!formData?.allow_special_credits ? (
          <>
            <Typography color="dark.800" fontSize={20} mb={1}>
              Special Credits
            </Typography>
            <Box p={3} bgcolor={"#F8F8F8"}>
              <Stack spacing={4}>
                {workTypeData?.special_requests?.map(
                  (requestData, requestIndex, requestArray) => (
                    requestData?.is_visible ? (
                      <Grid container key={requestIndex}>
                        <Grid item mr={3} flex={1} sx={{ maxWidth: "325px" }}>
                          <Input
                            id={getInputId("special_request", requestIndex)}
                            select
                            type="select"
                            label="Request"
                            variant="standard"
                            name="name"
                            sx={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: Boolean(requestData?.is_initial) || !!requestData?.name,
                              htmlFor: getInputId("special_request", requestIndex),
                              id: `label-${getInputId("special_request", requestIndex)}`,
                              name: `label-${getInputId("special_request", requestIndex)}`,
                            }}
                            value={requestData?.name}
                            onChange={(e) => handleChangeSpecialRequest(e, requestIndex)}
                            onBlur={(e) => validateSpacialRequest(e, requestIndex)}
                            error={
                              !!errors[errorWorkTypeIndex]?.special_requests[
                                requestIndex
                              ]?.name
                            }
                            helperText={
                              errors[errorWorkTypeIndex]?.special_requests[
                                requestIndex
                              ]?.name
                            }
                            required={
                              !!(requestData?.name ||
                                requestData?.allotment)
                            }>
                            {constantType?.specialRequestTypes?.map(
                              ({ name, is_visible }, index) => (
                                <MenuItem
                                  key={index}
                                  value={name}
                                  disabled={requestArray?.some(
                                    (data) => data?.name === name && data?.is_visible
                                  )}
                                  sx={{
                                    "&:hover": {
                                      background: "rgba(4, 127, 224, 0.1)",
                                    },
                                  }}>
                                  {titleCase(name)}
                                </MenuItem>
                              )
                            )}
                          </Input>
                        </Grid>
                        <Grid item mr={3} flex={130} maxWidth={130}>
                          {!!requestData?.name || requestIndex === 0 ?
                            <Input
                              id={getInputId("allotment", requestIndex)}
                              label="Credit(s)"
                              variant="standard"
                              name="allotment"
                              sx={{ width: "100%" }}
                              onChange={(e) => handleChangeSpecialRequest(e, requestIndex)}
                              onBlur={(e) => validateSpacialRequest(e, requestIndex)}
                              // InputLabelProps={{ shrink: Boolean(requestData?.is_initial) || requestData?.allotment }}
                              value={Boolean(requestData?.is_initial) && requestData?.allotment < 0 ? "" : requestData?.allotment}
                              type="number"
                              error={
                                !!errors[errorWorkTypeIndex]?.special_requests[
                                  requestIndex
                                ]?.allotment
                              }
                              helperText={
                                errors[errorWorkTypeIndex]?.special_requests[
                                  requestIndex
                                ]?.allotment
                              }
                              disabled={!formData?.allow_special_credits}
                              required={
                                !!(requestData?.name ||
                                  requestData?.allotment)
                              }
                            /> : null}
                        </Grid>
                        {requestArray?.length - 1 !== requestIndex ? <Grid item mt={1}>
                          <Box sx={{ minWidth: "40px" }}>
                            {requestArray?.filter((data) => data?.is_visible)
                              ?.length > 1 ? (
                              <IconButton
                                size="small"
                                sx={{ width: "40px", height: "40px" }}
                                onClick={() =>
                                  handleDeleteChangeRequestTypes(requestData?.unique_id)
                                }>
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </Box>
                        </Grid> : null}
                      </Grid>
                    ) : null
                  )
                )}
              </Stack>
            </Box>
          </>
        ) : null
      }
    </>
  );
};

export default LeaveTypeForm;