import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../axios';
import API from '../../axios/api';
import { convertDateFromFormat, getFormattedDate } from '../../utils';

const initialState = {
    holidaySettingData: {},
    ignoreLeaveDates: [],
    leaveLoading: true,
};

export const getAllHolidayData = createAsyncThunk(
    'leaveData/getAllHolidayData',
    async ({ organization_id, year_Id, user_id }, thunkAPI) => {
        try {
            let object = {};
            const holidayResponse = await axiosInstance.get(
                `${API.getHolidayDatesBySettings(organization_id, year_Id)}/leave/${user_id}`
            );
            if (holidayResponse?.status === 200) {
                object = { ...object, ...holidayResponse?.data?.data }
            }

            try {
                const workFromHomeResponse = await axiosInstance.get(API.getWfhDisabledDatesById(user_id));
                if (workFromHomeResponse?.status === 200) {
                    object = { ...object, disabledDates: workFromHomeResponse?.data?.dates, halfDayDates: workFromHomeResponse?.data?.halfDayDates ?? [] }
                }
            } catch (error) {
                if (error?.response?.status === 404) {
                    object = { ...object, disabledDates: [] }
                    return thunkAPI.fulfillWithValue(object);
                } else {
                    return thunkAPI.rejectWithValue(object);
                }
            }

            return object;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.holidayResponse?.data?.data);
        }
    }
);

const leaveDataSlice = createSlice({
    name: 'leaveData',
    initialState,
    reducers: {
        addAllLeaveData: (state, action) => {
            state.holidaySettingData = action.payload?.data;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllHolidayData.fulfilled, (state, action) => {
            state.holidaySettingData = action.payload;
            state.ignoreLeaveDates = getIgnoreDates(state.holidaySettingData);
            state.leaveLoading = false;

        });
        builder.addCase(getAllHolidayData.rejected, (state, action) => {
            state.leaveLoading = false;
        });
    },
});

const getIgnoreDates = (holidaySettingData) => {
    let array = [];
    if (!!holidaySettingData?.leaves?.length) {
        array.push(...holidaySettingData?.leaves);
    }
    if (!!holidaySettingData?.custom_holidays?.length) {
        array.push(...holidaySettingData?.custom_holidays?.map(value => value?.date));
    }
    if (!!holidaySettingData?.festival_holidays?.length) {
        array.push(...holidaySettingData?.festival_holidays?.map(value => value?.date));
    }
    if (!!holidaySettingData?.saturdayHolidays?.length) {
        array.push(...holidaySettingData?.saturdayHolidays?.map(value => value?.date));
    }
    if (!!holidaySettingData?.sundayHolidays?.length) {
        array.push(...holidaySettingData?.sundayHolidays?.map(value => value?.date));
    }
    if (!!holidaySettingData?.inactive_holidays?.length) {
        array.push(...holidaySettingData?.inactive_holidays?.map(value => value?.date));
    }
    if (holidaySettingData?.disabledDates?.length) {
        array.push(...holidaySettingData?.disabledDates?.map(date => convertDateFromFormat(date, 'YYYY-MM-DD', 'DD/MM/YYYY')));
    }
    return Array.from(new Set(array?.map(date => (getFormattedDate(date)))));
};

export const { addAllLeaveData } = leaveDataSlice.actions;
export default leaveDataSlice.reducer;
